import { ICustomCardProps } from "./CustomCard.interface";
import { Wrapper } from "./CustomCard.styles";

const CustomCard = ({ children }: ICustomCardProps) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

export default CustomCard;
