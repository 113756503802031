import moment from "jalali-moment";
import { useTheme } from "@emotion/react";

import { IInterviewerResume } from "../../../../../models";
import { ITableDefinition } from "../../../atoms";

const useApplicantsTableConfig = (onClickItem: (id: number, applicationId: number) => void) => {
  const theme = useTheme();

  const getTableDefinition = (): ITableDefinition<IInterviewerResume>[] => {
    return [
      {
        columnKey: "name",
        columnStyle: (data) => ({
          cursor: "pointer",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        label: "Applicant’s name",
        renderer: (data) => {
          return <>{data.applicants?.name}</>;
        },
        onColumnClick: (data) => {
          onClickItem(data.id, data.applicants.id);
        },
      },
      {
        columnKey: "position",
        label: "Position",
        columnStyle: (data) => ({
          cursor: "pointer",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          return <>{data.applicants?.job_application?.job_position?.title}</>;
        },
        onColumnClick: (data) => {
          onClickItem(data.id, data.applicants.id);
        },
      },
      {
        columnKey: "type",
        label: "Interview Type",
        columnStyle: (data) => ({
          cursor: "pointer",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          return <>{data.type}</>;
        },
        onColumnClick: (data) => {
          onClickItem(data.id, data.applicants.id);
        },
      },
      {
        columnKey: "date",
        label: "Interview Date",
        columnStyle: (data) => ({
          cursor: "pointer",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          return (
            <>
              {moment.utc(data.plan_date).format("h:mm a")}
              <br />
              {moment(data.plan_date).format("DD / MMM / YYYY")}
            </>
          );
        },
        onColumnClick: (data) => {
          onClickItem(data.id, data.applicants.id);
        },
      },
    ];
  };

  return { getTableDefinition };
};

export default useApplicantsTableConfig;
