import { NormalPage, Pagination, Table } from "../../atoms";
import useUsers from "./useUsers";
import useUsersTableConfig from "./config/config";
import UsersFilter from "./filter/UsersFilter";

const Users = () => {
  const {
    totalCount,
    stateData,
    isFetching,
    filter,
    setFilter,
    handleSelect,
    handleSelectAll,
    allSelected,
    isSomeItemSelected,
    setFilterSearch,
    onClickItem,
    onClickDeleteItem,
    onClickDeleteItems,
    onClickAdd,
    onClickActivateItem,
  } = useUsers();
  const { getTableDefinition } = useUsersTableConfig(
    allSelected,
    isSomeItemSelected,
    handleSelectAll,
    handleSelect,
    onClickItem,
    onClickDeleteItem,
    onClickActivateItem
  );

  return (
    <>
      <NormalPage>
        <UsersFilter
          onClickAdd={onClickAdd}
          handleSearch={(e) => setFilterSearch(e.target.value)}
          onClickDelete={onClickDeleteItems}
          showDeleteAll={isSomeItemSelected}
        />
        <div className="list-content">
          <div className="form-style">
            <Table
              definitions={getTableDefinition()}
              items={stateData}
              isLoading={isFetching}
            />
            <Pagination
              onPageChanged={(page) =>
                setFilter({
                  ...filter,
                  page: page - 1,
                })
              }
              totalCount={totalCount}
              currentPage={filter.page + 1}
            />
          </div>
        </div>
      </NormalPage>
    </>
  );
};

export default Users;
