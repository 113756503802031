import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { UseApplication, UseFolder } from "../../../../hooks";
import { IFolder } from "../../../../models";
import StorageUtil from "../../../../util/StorageUtil";
import { handleLogout } from "../../../../redux/auth";
import { useInvalidateFolders } from "../../../../hooks/useFolder";

const useAdminNavList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = UseFolder.useGetFolders({
    page: 0,
    size: 1000,
    sort: "id,asc",
    filter: "",
  });
  const { invalidate } = useInvalidateFolders();
  const { data: recentCountData } =
    UseApplication.useGetApplicationUnreadCount();
  const { invalidate: invalidateRecent } =
    UseApplication.useInvalidateUnreadCount();

  const fixFolders = [
    {
      editable: false,
      id: 0,
      name: "Recent",
      organization: "",
      unReadCount: recentCountData?.data?.unread_recent_counts,
    },
    {
      editable: false,
      id: 0,
      name: "Starred",
      organization: "",
      unReadCount: recentCountData?.data?.unread_stars_count,
    },
  ];
  const [folders, setFolders] = useState<IFolder[]>(fixFolders);

  useEffect(() => {
    if (data?.data?.content) {
      setFolders([...fixFolders, ...data.data.content]);
    }
  }, [data]);

  const onClickLogout = () => {
    StorageUtil.storageRemoveAll();
    dispatch(handleLogout());
    navigate("/sign-in", {
      replace: true,
    });
  };

  return {
    folders,
    onClickLogout,
    invalidate,
    recentCountData,
    invalidateRecent,
  };
};

export default useAdminNavList;
