import { Theme } from "@emotion/react";
import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme();

export const DefaultTheme: Theme = {
  ...muiTheme,
  palette: {
    ...muiTheme.palette,
    mode: "light",
    common: {
      black: "#0000",
      white: "#fff",
    },
    primary: {
      main: "#1c87ffcc",
      light: "#1c87ff4d",
      lighter: "#1c87ff1f",
      dark: "#1c87ff",
      contrastText: "#1c87ff99",
    },
    secondary: {
      main: "#1c87ffcc",
      light: "#1c87ff4d",
      lighter: "#1c87ff1f",
      dark: "#1c87ff",
      contrastText: "#1c87ff99",
    },
    error: {
      main: "#FF3131cc",
      light: "#FF31314d",
      lighter: "#FF31311f",
      dark: "#FF3131",
      contrastText: "#FF313199",
    },
    warning: {
      main: "#e59900cc",
      light: "#e599004d",
      lighter: "#e599001f",
      dark: "#e59900",
      contrastText: "#e5990099",
    },
    info: {
      main: "#67aefdcc",
      light: "#67aefd4d",
      lighter: "#67aefd1f",
      dark: "#67aefd",
      contrastText: "#67aefd99",
    },
    success: {
      main: "#5fb881cc",
      light: "#5fb8814d",
      lighter: "#5fb8811f",
      dark: "#5fb881",
      contrastText: "#5fb88199",
    },
    text: {
      primary: "#060026",
      secondary: "#00000099 ",
      disabled: "#00000099 ",
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF ",
    },
    divider: "#00000033",
    grey: {
      "50": "#0000001A",
      "100": "#0000001A",
      "200": "#0000001A",
      "300": "#00000033",
      "400": "#666666",
      "500": "#666666",
      "600": "#333333",
      "700": "#333333",
      "800": "#000000DE",
      "900": "#000000DE",
      A100: "#000000DE",
      A200: "#000000DE",
      A400: "#000000DE",
      A700: "#000000DE",
    },
  },
};
