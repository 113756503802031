import { Container, ImageContainer, TextWrapper } from "./NoContent.styles";

import ImgNoContent from "../../../../assets/images/img-no-content.png";

const NoContent = () => {
  return (
    <Container>
      <ImageContainer>
        <img src={ImgNoContent} alt="no-content" />
      </ImageContainer>
      <TextWrapper>No Content Found</TextWrapper>
    </Container>
  );
};

export default NoContent;
