import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PAGE_SIZE } from "../../../../util/constants";
import { UseUser, useDashboardRoutes } from "../../../../hooks";
import { IUser, IRequestFilter } from "../../../../models";

const useUsers = () => {
  const navigate = useNavigate();

  const { urls } = useDashboardRoutes();

  const [filterSearch, setFilterSearch] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSomeItemSelected, setIsSomeItemSelected] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [stateData, setStateData] = useState<IUser[]>([]);
  const [selectedItem, setSelectedItem] = useState<IUser | undefined>();

  const [filter, setFilter] = useState<IRequestFilter>({
    page: 0,
    size: PAGE_SIZE,
    sort: "id,desc",
    filter: "",
  });

  const { data, isLoading: isFetching, refetch } = UseUser.useGetUsers(filter);
  const {
    isSuccess: activateSuccess,
    isLoading: activateLoading,
    mutate: activateMutate,
  } = UseUser.useActivate();

  const handleFilter = () => {
    const searchFilterArray = [];
    if (filterSearch) {
      searchFilterArray.push(`username=ilike='${filterSearch}'`);
      searchFilterArray.push(`firstName=ilike='${filterSearch}'`);
      searchFilterArray.push(`lastName=ilike='${filterSearch}'`);
      searchFilterArray.push(`email=ilike='${filterSearch}'`);
    }

    if (searchFilterArray.length > 0) {
      setFilter({
        ...filter,
        page: 0,
        filter: searchFilterArray.join(","),
      });
    } else {
      setFilter({
        ...filter,
        page: 0,
        sort: "firstName,asc",
        filter: "",
      });
    }
  };

  const handleSelectAll = (all: boolean) => {
    setAllSelected(all);
    const newData = stateData.map((item) => ({
      ...item,
      selected: item.editable ? all : false,
    }));
    setStateData(newData);
  };

  const handleSelect = (selected: boolean, id: number) => {
    const newData = stateData.map((item) => ({
      ...item,
      selected: item.id === id ? selected : item.selected,
    }));
    setStateData(newData);
  };

  const onClickItem = (id: number) => {
    navigate(
      urls.adminSubRoots.MEMBER_UPDATE.address.replace(":username", String(id))
    );
  };

  const onClickDeleteItem = (id: number) => {
    const item = stateData.find((item) => item.id === id);
    if (item) {
      setSelectedItem(item);
      setShowDeleteModal(true);
    }
  };

  const onClickActivateItem = (id: number) => {
    const item = stateData.find((item) => item.id === id);
    if (item) {
      activateMutate({
        id: item.id,
        activated: !item.activated,
      });
    }
  };

  const onClickDeleteItems = () => {
    setSelectedItem(undefined);
    setShowDeleteModal(true);
  };

  const onClickAdd = () => {
    navigate(urls.adminSubRoots.MEMBER_CREATE.address);
  };

  const deleteUsers = () => {
    //TODO
  };

  // const deleteUser = () => {
  //   if (selectedItem) {
  //     deleteMutate(selectedItem.login);
  //   }
  // };

  useEffect(() => {
    setTotalCount(data?.data?.totalElements ?? 0);
    setStateData(data?.data?.content ?? []);
  }, [data]);

  useEffect(() => {
    const selectedItems = stateData.filter((item) => item.selected === true);
    setIsSomeItemSelected(selectedItems.length > 0);
  }, [stateData]);

  useEffect(() => {
    if (activateSuccess) {
      refetch();
    }
  }, [activateSuccess]);

  useEffect(() => {
    handleFilter();
  }, [filterSearch]);

  return {
    totalCount,
    stateData,
    isFetching,
    filter,
    setFilter,
    handleSelect,
    handleSelectAll,
    allSelected,
    isSomeItemSelected,
    setFilterSearch,
    onClickItem,
    showDeleteModal,
    setShowDeleteModal,
    selectedItem,
    onClickDeleteItem,
    onClickDeleteItems,
    deleteUsers,
    onClickAdd,
    onClickActivateItem,
  };
};

export default useUsers;
