import { useEffect, useState } from "react";
import moment from "jalali-moment";

import { interviewTextEmail } from "./textConfig";
import { InterviewType } from "../../../../models";

const useApplicantInterviewModal = (
  jobPosition: string,
  applicantName: string
) => {
  const [content, setContent] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [place, setPlace] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);

  const validation = () => {
    let valid = true;

    if (!selectedType) {
      valid = false;
    }

    if (!selectedTime) {
      valid = false;
    }

    if (!place) {
      valid = false;
    }

    setFormSubmit(true);
    return valid;
  };

  useEffect(() => {
    setContent(
      interviewTextEmail
        .replaceAll(
          "{type}",
          selectedType === InterviewType.ON_SITE ? "حضوری" : "آنلاین"
        )
        .replaceAll(
          "{date}",
          selectedTime ? moment(selectedTime).format("DD / MM / YYYY") : ""
        )
        .replaceAll("{place}", place)
        .replaceAll(
          "{time}",
          selectedTime ? moment.utc(selectedTime).format("h:mm a") : ""
        )
        .replaceAll("{job_position}", jobPosition)
        .replaceAll("{name}", applicantName)
    );
  }, [selectedTime, jobPosition, place, applicantName, selectedType]);

  return {
    content,
    setContent,
    selectedType,
    setSelectedType,
    selectedTime,
    setSelectedTime,
    place,
    setPlace,
    validation,
    formSubmit
  };
};

export default useApplicantInterviewModal;
