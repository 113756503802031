import { PaginationItem } from "@mui/material";

import { StyledPagination, Wrapper } from "./Pagination.styles";
import { IPaginationProps } from "./Pagination.interface";
import { PAGE_SIZE } from "../../../../util/constants";

const Pagination = ({
  totalCount,
  currentPage,
  onPageChanged,
}: IPaginationProps) => {
  const count = Math.ceil(totalCount / PAGE_SIZE);
  return (
    <Wrapper>
      <StyledPagination
        count={count}
        onChange={(_, page) => onPageChanged(page)}
        variant="outlined"
        shape="rounded"
        page={currentPage}
        renderItem={(item) => <PaginationItem {...item} />}
      />
    </Wrapper>
  );
};

export default Pagination;
