import Cookies from "js-cookie";

const StorageUtil = {
  //storage
  storageGet: (key: string) => {
    let val = "";
    if (StorageUtil.isCookieSupported()) {
      val = String(Cookies.get(key));
    } else if (StorageUtil.isStorageSupported()) {
      val = String(window.localStorage.getItem(key));
    }

    try {
      const obj = JSON.parse(val);
      return obj;
    } catch (e) {
      return undefined;
    }
  },

  storageSet: (key: string, value: Object, exp: any | null = null): boolean => {
    if (StorageUtil.isCookieSupported()) {
      let expires = new Date();
      expires.setDate(expires.getDate() + 360);
      if (exp) {
        expires = exp;
      }
      try {
        const cookieVal = JSON.stringify(value);
        Cookies.set(key, cookieVal, { path: "/", expires });
        return true;
      } catch (e) {
        return false;
      }
    } else if (StorageUtil.isStorageSupported()) {
      window.localStorage.setItem(key, JSON.stringify(value));
      return true;
    }

    return false;
  },

  storageRemove: (key: string): boolean => {
    if (StorageUtil.isCookieSupported()) {
      Cookies.remove(key);
      return true;
    } else if (StorageUtil.isStorageSupported()) {
      window.localStorage.removeItem(key);
      return true;
    }
    return false;
  },

  storageRemoveAll: () => {
    const keys: string[] = Object.keys(StorageUtil.storageKeys);
    for (const key of keys) {
      //@ts-ignore
      StorageUtil.storageRemove(StorageUtil.storageKeys[key]);
    }
  },

  isStorageSupported: () => {
    try {
      const testKey = "__some_random_key_you_are_not_going_to_use__";
      window.localStorage.setItem(testKey, testKey);
      window.localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  },

  isCookieSupported: (): boolean => {
    var cookieEnabled = navigator.cookieEnabled ? true : false;
    //if not IE4+ nor NS6+
    if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled =
        document.cookie.indexOf("testcookie") !== -1 ? true : false;
    }

    return cookieEnabled;
  },

  storageKeys: {
    token: "sji_admin_TOKENS",
    userInfo: "sji_admin_USER_INFO"
  }
};

export default StorageUtil;
