import { MouseEvent, useState } from "react";

import { Input } from "../../atoms";
import { ISortMenuProps } from "./SortMenu.interface";
import { Container, StyleMenuItem, StyledMenu } from "./SortMenu.styles";

import { ReactComponent as IconSort } from "../../../../assets/icons/ic-sort.svg";

const SortMenu = ({
  onChange,
  sortList,
  placeHolder,
  value,
}: ISortMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (val: string) => {
    onChange(val);
    setAnchorEl(null);
  };

  return (
    <Container>
      <Input
        placeholder={placeHolder}
        endAdornment={<IconSort />}
        autoFocus={false}
        sx={{ cursor: "pointer !important" }}
        value={typeof value === "string" ? value : ""}
        onClick={(e) => handleClick(e)}
      />
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {sortList.map((item, index) => (
          <StyleMenuItem key={index} onClick={() => handleClose(item)}>
            {item}
          </StyleMenuItem>
        ))}
      </StyledMenu>
    </Container>
  );
};

export default SortMenu;
