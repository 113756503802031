import { Drawer } from "@mui/material";

import AdminNavList from "../../atoms/adminNavList/AdminNavList";
import { IAdminDrawerProps } from "./AdminDrawer.interface";

const AdminDrawer = ({
  drawerWidth,
  showDrawer,
  onCloseDrawer
}: IAdminDrawerProps) => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          position: "relative"
        }
      }}
      variant="persistent"
      anchor="left"
      open={showDrawer}
      onClose={onCloseDrawer}
    >
      <AdminNavList />
    </Drawer>
  );
};

export default AdminDrawer;
