import styled from "@emotion/styled";
import { styled as MuiStyled } from "@mui/material/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
`;

export const Main = MuiStyled("main")(({ theme }) => ({
  flexGrow: 1
}));

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

export const LogoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
`;
