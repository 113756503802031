import { alpha, styled as MUIStyled, Select } from "@mui/material";
import styled from "@emotion/styled";

export interface ISelectStyleProps {
  error?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.span<ISelectStyleProps>`
  font-size: 16px;
  font-weight: 400;
  color: ${({ error, theme }) =>
    error ? theme.palette.error.dark : theme.palette.text.secondary};
  margin-bottom: 4px;
`;

export const ErrorWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.error.dark};
  margin-top: 4px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
`;

export const StyledSelect = MUIStyled(Select)(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  position: "relative",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.grey[700]} !important`,
  padding: "8px 16px",
  height: "40px",
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "&.Mui-error": {
    border: `1px solid ${theme.palette.error.dark} !important`,
  },
  "&:focus": {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
  ".MuiSelect-select": {
    fontSize: 16,
    width: "100%",
    color: theme.palette.text.secondary,
    padding: "0 !important",
  },
}));
