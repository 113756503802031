import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { JobPosition } from "../network";
import Queries from "../util/Queries";
import { IRequestFilter } from "../models";

export const useGetJobPositions = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_JOB_POSITIONS, filter],
      () => JobPosition.getPositions(filter),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useInvalidateJobPositions = () => {
  const query = useQueryClient();
  const invalidate = () => {
    query.invalidateQueries([Queries.GET_JOB_POSITIONS]);
  };
  return { invalidate };
};

export const useGetJobPosition = (id: number) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_JOB_POSITION, id],
      () => JobPosition.getPosition(id),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useCreate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    JobPosition.create
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useUpdate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    JobPosition.update
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useDelete = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    JobPosition.deleteJobPosition
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};
