import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";

import { PAGE_SIZE } from "../../../../util/constants";
import { UseApplicant, UseFolder, useDashboardRoutes } from "../../../../hooks";
import { IGetApplicantFilter } from "../../../../network";
import { IApplicant } from "../../../../models";

const useApplicants = () => {
  const { folderId } = useParams();
  const navigate = useNavigate();
  const { urls } = useDashboardRoutes();

  const [filterSearch, setFilterSearch] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [isSomeItemSelected, setIsSomeItemSelected] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [stateData, setStateData] = useState<IApplicant[]>([]);

  const getFilterText = (filterText: any): string | number => {
    const filterNumber = Number(filterText);
    if (filterNumber) {
      return filterNumber;
    } else {
      if (filterText === "starred") {
        return "starred=ilike=TRUE";
      }
    }

    return "";
  };

  const [filter, setFilter] = useState<IGetApplicantFilter>({
    page: 0,
    size: PAGE_SIZE,
    sort: "id,desc",
    filter: "",
    folder_id:
      typeof getFilterText(folderId) === "number"
        ? Number(folderId)
        : undefined,
  });

  const {
    data,
    isLoading: isFetching,
    refetch,
  } = UseApplicant.useGetApplicants(filter, folderId === "starred");
  const { data: folderData, isFetching: folderIsFetching } =
    UseFolder.useGetFolders({
      page: 0,
      size: 1000,
      filter: "",
      sort: "id,desc",
    });
  const {
    mutate: starMutate,
    data: starData,
    isSuccess: starSuccess,
  } = UseApplicant.useStarApplicants();
  const {
    mutate: moveMutate,
    data: moveData,
    isSuccess: moveSuccess,
  } = UseApplicant.useMoveApplicantsToFolder();

  const handleFilter = () => {
    const searchFilterArray = [];
    if (filterSearch) {
      searchFilterArray.push(`name=ilike='${filterSearch}'`);
    }
    if (filterPosition && typeof filterPosition === "string") {
      searchFilterArray.push(
        `jobApplication.jobPositions.title=ilike='${filterPosition}'`
      );
    }
    if (filterStatus && typeof filterStatus === "string") {
      searchFilterArray.push(
        `status=ilike='${filterStatus.replaceAll(" ", "_").toUpperCase()}'`
      );
    }
    if (searchFilterArray.length > 0) {
      setFilter({
        ...filter,
        page: 0,
        filter: searchFilterArray.join(";"),
      });
    } else {
      setFilter({
        ...filter,
        page: 0,
        sort: "id,desc",
        filter: "",
      });
    }
  };

  const handleClearFilter = () => {
    setFilterSearch("");
    setFilterPosition("");
    setFilterStatus("");
    setFilter({
      ...filter,
      page: 0,
      sort: "id,desc",
      filter: "",
    });
  };

  const handleSortDate = (val: string) => {
    setFilter({
      ...filter,
      sort: val === "Newest" ? "applyDate,desc" : "applyDate,asc",
    });
  };

  const handleSelectAll = (all: boolean) => {
    setAllSelected(all);
    const newData = stateData.map((item) => ({
      ...item,
      selected: all,
    }));
    setStateData(newData);
  };

  const handleStarAll = (all: boolean) => {
    const ids = stateData.map((item) => item.id);
    starMutate({
      ids: ids,
      starred: all,
    });
  };

  const handleSelect = (selected: boolean, id: number) => {
    const newData = stateData.map((item) => ({
      ...item,
      selected: item.id === id ? selected : item.selected,
    }));
    setStateData(newData);
    const selectedItemCount = newData.filter((item) => item.selected).length;
    if (selectedItemCount === 0) {
      setAllSelected(false);
      setIsSomeItemSelected(false);
    } else {
      if (selectedItemCount === newData.length) {
        setIsSomeItemSelected(false);
        setAllSelected(true);
      } else {
        setIsSomeItemSelected(true);
        setAllSelected(false);
      }
    }
  };

  const handleStar = (selected: boolean, id: number) => {
    starMutate({
      ids: [id],
      starred: selected,
    });
  };

  const handleMove = (folderId: number) => {
    const ids = stateData.filter((item) => item.selected === true);
    if (!ids || ids.length === 0) {
      return;
    }
    moveMutate({
      folderId,
      ids: ids.map((item) => item.id),
    });
  };

  const handleMoveItem = (folderId: number, id: number) => {
    moveMutate({
      folderId,
      ids: [id],
    });
  };

  const onClickItem = (id: number) => {
    navigate(
      urls.adminSubRoots.APPLICANT_DETAIL.address.replace(":id", String(id))
    );
  };

  useEffect(() => {
    setTotalCount(data?.data?.totalElements ?? 0);
    setStateData(data?.data?.content ?? []);
  }, [data]);

  useEffect(() => {
    const selectedItems = stateData.filter((item) => item.selected === true);
    setIsSomeItemSelected(selectedItems.length > 0);
  }, [stateData]);

  useEffect(() => {
    setFilter({
      ...filter,
      folder_id:
        typeof getFilterText(folderId) === "number"
          ? Number(folderId)
          : undefined,
    });
  }, [folderId]);

  useEffect(() => {
    if (starSuccess || moveSuccess) {
      refetch();
    }
  }, [starData, starSuccess, moveData, moveSuccess]);

  useEffect(() => {
    handleFilter();
  }, [filterSearch, filterPosition, filterStatus]);

  return {
    totalCount,
    stateData,
    isFetching,
    folderIsFetching,
    filter,
    setFilter,
    handleSelect,
    handleSelectAll,
    handleStarAll,
    handleStar,
    handleMove,
    handleMoveItem,
    allSelected,
    isSomeItemSelected,
    folderData,
    setFilterPosition,
    setFilterStatus,
    setFilterSearch,
    handleClearFilter,
    handleSortDate,
    onClickItem,
  };
};

export default useApplicants;
