import { useMutation, useQuery } from "@tanstack/react-query";

import { Applicant, IGetApplicantFilter } from "../network";
import Queries from "../util/Queries";

export const useGetApplicants = (
  filter: IGetApplicantFilter,
  starred = false
) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_APPLICANTS, filter, starred],
      () =>
        starred
          ? Applicant.getApplicantsStarred(filter)
          : Applicant.getApplicants(filter),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useGetApplicant = (id: number) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_APPLICANT, id],
      () => Applicant.getApplicantAdmin(id),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useGetApplicantResume = (id: number) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_APPLICANT_RESUME, id],
      () => Applicant.getApplicantResume(id),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useRemoveApplicantsFromFolder = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Applicant.removeApplicantsFromFolder
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useMoveApplicantsToFolder = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Applicant.moveApplicantsFromFolder
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useStarApplicants = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Applicant.setApplicantsStar
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useAddEditNote = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Applicant.addNote
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useReject = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Applicant.reject
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useConfirm = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Applicant.confirm
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useHire = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Applicant.hire
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};
