import axios, { AxiosResponse } from "axios";

import {
  IAuthInput,
  IAuthResponse,
  IResetPass,
  IResetPassValidate
} from "./interfaces";

export const login = (
  data: IAuthInput
): Promise<AxiosResponse<IAuthResponse, any>> => {
  return axios.post("api/authenticate", data, {
    withCredentials: false
  });
};

export const resetPassInit = (
  email: string
): Promise<AxiosResponse<any, any>> => {
  return axios.post(
    "api/v1/accounts/reset-password/init",
    { email },
    {
      withCredentials: false
    }
  );
};

export const resetPassValidate = (
  data: IResetPassValidate
): Promise<AxiosResponse<any, any>> => {
  return axios.post("api/v1/accounts/reset-password/validation", data, {
    withCredentials: false
  });
};

export const resetPassFinish = (
  data: IResetPass
): Promise<AxiosResponse<any, any>> => {
  return axios.put("api/v1/accounts/reset-password/finish", data, {
    withCredentials: false
  });
};
