import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import { UseUser } from "../../../../hooks";

const useUserUpdate = () => {
  const { username } = useParams();
  const navigate = useNavigate();

  const { data, isFetching, isError } = UseUser.useGetUser(String(username));

  useEffect(() => {
    if (isError) {
      navigate(-1);
    }
  }, [isError]);

  return { data, isFetching };
};

export default useUserUpdate;
