import { IJobPosition } from "../../../../../models";
import { ITableDefinition } from "../../../atoms";
import { OperationWrapper } from "./config.styles";

import { ReactComponent as IconCheckBox } from "../../../../../assets/icons/ic-check-box.svg";
import { ReactComponent as IconCheckBoxSemi } from "../../../../../assets/icons/ic-check-box-semi-checked.svg";
import { ReactComponent as IconCheckBoxSelected } from "../../../../../assets/icons/ic-check-box-checked.svg";
import { ReactComponent as IconEdit } from "../../../../../assets/icons/ic-edit.svg";
import { ReactComponent as IconTrash } from "../../../../../assets/icons/ic-trash.svg";

const useJobPositionsTableConfig = (
  isAllSelected: boolean,
  isSomeSelected: boolean,
  setSelectedAll: (selected: boolean) => void,
  setSelected: (selected: boolean, id: number) => void,
  onClickEdit: (id: number) => void,
  onClickDelete: (id: number) => void
) => {
  const getTableDefinition = (): ITableDefinition<IJobPosition>[] => {
    return [
      {
        columnKey: "selected",
        label: "check",
        headerStyle: () => ({
          textAlign: "center",
          maxWidth: "8px",
        }),
        columnStyle: () => ({
          textAlign: "center",
          maxWidth: "8px",
        }),
        headerRenderer: () => {
          return isAllSelected ? (
            <IconCheckBoxSelected onClick={() => setSelectedAll(false)} />
          ) : isSomeSelected ? (
            <IconCheckBoxSemi onClick={() => setSelectedAll(false)} />
          ) : (
            <IconCheckBox onClick={() => setSelectedAll(true)} />
          );
        },
        renderer: (data) => {
          return data.selected ? (
            <IconCheckBoxSelected onClick={() => setSelected(false, data.id)} />
          ) : (
            <IconCheckBox onClick={() => setSelected(true, data.id)} />
          );
        },
      },
      {
        columnKey: "title",
        label: "Name",
      },
      {
        columnKey: "operation",
        label: "",
        renderer: (data) => {
          return (
            <OperationWrapper>
              <IconEdit onClick={() => onClickEdit(data.id)} />
              <IconTrash onClick={() => onClickDelete(data.id)} />
            </OperationWrapper>
          );
        },
      },
    ];
  };

  return { getTableDefinition };
};

export default useJobPositionsTableConfig;
