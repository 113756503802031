import {
  DeleteConfirmModal,
  JobPositionModal,
  NormalPage,
  Pagination,
  Table,
} from "../../atoms";
import useJobPositions from "./useJobPositions";
import useJobPositionsTableConfig from "./config/config";
import JobPositionFilter from "./filter/JobPositionsFilter";

const JobPositions = () => {
  const {
    totalCount,
    stateData,
    isFetching,
    filter,
    setFilter,
    handleSelect,
    handleSelectAll,
    allSelected,
    isSomeItemSelected,
    setFilterSearch,
    onClickItem,
    selectedItem,
    showModal,
    setShowModal,
    showDeleteModal,
    setShowDeleteModal,
    deleteLoading,
    deleteJobs,
    onClickDeleteItem,
    onClickDeleteItems,
    onClickAdd,
  } = useJobPositions();
  const { getTableDefinition } = useJobPositionsTableConfig(
    allSelected,
    isSomeItemSelected,
    handleSelectAll,
    handleSelect,
    onClickItem,
    onClickDeleteItem
  );

  return (
    <>
      <NormalPage>
        <JobPositionFilter
          onClickAdd={onClickAdd}
          handleSearch={(e) => setFilterSearch(e.target.value)}
          onClickDelete={onClickDeleteItems}
          showDeleteAll={isSomeItemSelected}
        />
        <div className="list-content">
          <div className="form-style">
            <Table
              definitions={getTableDefinition()}
              items={stateData}
              isLoading={isFetching}
            />
            <Pagination
              onPageChanged={(page) =>
                setFilter({
                  ...filter,
                  page: page - 1,
                })
              }
              totalCount={totalCount}
              currentPage={filter.page + 1}
            />
          </div>
        </div>
      </NormalPage>
      <JobPositionModal
        mode={selectedItem ? "EDIT" : "CREATE"}
        onCancel={() => setShowModal(false)}
        showModal={showModal}
        id={selectedItem?.id}
        title={selectedItem?.title}
      />
      <DeleteConfirmModal
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={deleteJobs}
        title={
          selectedItem ? "Delete Job Position" : "Delete Selected Job Positions"
        }
        message={
          selectedItem
            ? "Are you sure you want to delete this position?"
            : "Are you sure you want to delete these positions?"
        }
        loading={deleteLoading}
      />
    </>
  );
};

export default JobPositions;
