import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { User } from "../network";
import Queries from "../util/Queries";
import { IRequestFilter } from "../models";

export const useGetUsers = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery([Queries.GET_USERS, filter], () => User.getUsers(filter), {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    });

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useInvalidateUsers = () => {
  const query = useQueryClient();
  const invalidate = () => {
    query.invalidateQueries([Queries.GET_USERS]);
  };
  return { invalidate };
};

export const useGetUser = (username: string) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery([Queries.GET_USER, username], () => User.getUser(username), {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    });

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useCreate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    User.create
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useUpdate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    User.update
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useDelete = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    User.deleteUser
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useActivate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    User.activateUser
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};
