import axios, { AxiosResponse } from "axios";

import { IUser, IRequestFilter, IResponse } from "../../models";
import { IUserActivate, IUserCreate } from "./interfaces";

export const getUsers = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IUser>, any>> => {
  return axios.get("/api/v1/admin/users", {
    params: filter,
  });
};

export const getUser = (
  username: string
): Promise<AxiosResponse<IUser, any>> => {
  return axios.get(`/api/v1/admin/users/${username}`);
};

export const create = (
  data: IUserCreate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.post("/api/v1/admin/users", data);
};

export const update = (
  data: IUserCreate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/admin/users/${data.id}`, data);
};

export const deleteUser = (
  username: string
): Promise<AxiosResponse<unknown, any>> => {
  return axios.delete(`/api/v1/admin/users/${username}`);
};

export const activateUser = (
  data: IUserActivate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/admin/users/activation`, {
    ids: [data.id],
    activated: data.activated,
  });
};
