import { forwardRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { ITinyEditorProps } from "./TinyEditor.interface";
import { ErrorWrapper, InputTitle, Wrapper } from "./TinyEditor.styles";

import { ReactComponent as IconError } from "../../../../assets/icons/ic-error.svg";

const TinyEditor = (
  { onChange, initVal, title, style, error, errorMessage }: ITinyEditorProps,
  ref: any
) => {
  return (
    <Wrapper style={style ? style : {}}>
      <InputTitle>{title}</InputTitle>
      <Editor
        apiKey="y7vtsu1nwu6yxn19xw3xjq8qkxbkcp7md4xvixi0rgk3hhm4"
        onInit={(evt, editor) => {
          if (ref) {
            ref.current = editor;
          }
        }}
        // initialValue={initVal ?? ""}
        value={initVal ?? ""}
        onEditorChange={(val) => onChange(val)}
        init={{
          height: 500,
          width: "100%",
          menubar: "file edit view insert format tools table help",
          plugins: [
            "preview",
            "importcss",
            "searchreplace",
            "autolink",
            "autosave",
            "save",
            "directionality",
            "code",
            "visualblocks",
            "visualchars",
            "fullscreen",
            "image",
            "link",
            "media",
            "template",
            "codesample",
            "table",
            "charmap",
            "pagebreak",
            "nonbreaking",
            "anchor",
            "insertdatetime",
            "advlist",
            "lists",
            "wordcount",
            "help",
            "charmap",
            "quickbars",
            "emoticons",
          ],
          toolbar_sticky: false,
          toolbar_sticky_offset: 108,
          autosave_ask_before_unload: true,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          importcss_append: true,
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          contextmenu: "link image table",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {error && (
        <ErrorWrapper>
          <IconError />
          <span>{errorMessage ?? `${title} required`}</span>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default forwardRef(TinyEditor);
