const StringUtil = {
  camelize: (val: string): string => {
    return val
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  },
  toPascalCase: (val: string): string => {
    return val.replace(/\w+/g, function (w) {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    });
  },
};

export default StringUtil;
