import { Divider } from "@mui/material";
import moment from "jalali-moment";

import {
  BigHeaderTitle,
  HeaderTitle,
  HeaderWrapper,
  MainFormWrapper,
  SubTitle,
  SubTitleBlue,
} from "./ApplicantForm.styles";
import { IInterviewSectionProps } from "./ApplicantForm.interface";

const InterviewSection = ({ data }: IInterviewSectionProps) => {
  if(!data?.interviews?.type){
    return <></>
  }
  
  return (
    <>
      <MainFormWrapper>
        <BigHeaderTitle>{data?.interviews?.type}</BigHeaderTitle>
        <HeaderWrapper>
          <HeaderTitle>
            {data?.interviews?.plan_date
              ? moment(data?.interviews?.plan_date).format("DD / MMM / YYYY")
              : ""}
          </HeaderTitle>
          <HeaderTitle>
            {data?.interviews?.plan_date
              ? moment.utc(data?.interviews?.plan_date).format("h:mm a")
              : ""}
          </HeaderTitle>
        </HeaderWrapper>
      </MainFormWrapper>
      <Divider orientation="horizontal" sx={{ width: "100%" }} />
      <MainFormWrapper>
        <HeaderTitle>
          {data?.interviews?.type === "ONLINE"
            ? "Interview Link"
            : "Interview Site"}
        </HeaderTitle>
        {data?.interviews?.type === "ONLINE" ? (
          <a href={data?.interviews?.place} target="_blank" rel="noreferrer">
            <SubTitleBlue>{data?.interviews?.place}</SubTitleBlue>
          </a>
        ) : (
          <SubTitle>{data?.interviews?.place}</SubTitle>
        )}
      </MainFormWrapper>
      <Divider orientation="horizontal" sx={{ width: "100%" }} />
    </>
  );
};

export default InterviewSection;
