import {  CustomCard } from "../../atoms";
import {  
  Container,
  Header,
  Wrapper
} from "./ResetPass.styles";
import useResetPass from "./useResetPass";
import ResetPassInit from "./ResetPassInit";
import ResetPassOtp from "./ResetPassOtp";
import ResetPassFinal from "./ResetPassFinal";

import { ReactComponent as IconLogo } from "../../../../assets/icons/ic-logo-large.svg";
import ResetPassSuccess from "./ResetPassSuccess";

const ResetPass = () => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    step,    
    initMutate,
    initLoading,
    onClickChange,
    finishLoading,
    code,
    setCode,
    onClickBack,
    validateLoading,
    validateMutate,
    onClickGoLogin,
    hasError,
    errorMessage
  } = useResetPass();

  const renderStep = () => {
    switch(step){
      case 1:
        return <ResetPassInit email={email} setEmail={setEmail} onClick={() => initMutate(email)} isLoading={initLoading} onClickBack={onClickBack}/>
        case 2:
        return <ResetPassOtp code={code} setCode={setCode} onClickNext={() =>validateMutate({
          code,
          email
        })} isLoading={validateLoading} onClickBack={onClickBack} onClickSendAgain={() => initMutate(email)}/>
        case 3:
        return <ResetPassFinal
        hasError={hasError}
        errorMessage={errorMessage}
        password={password}
        passwordConfirm={newPassword}

        setPassword={setPassword}
        setPasswordConfirm={setNewPassword}
        onClickSubmit={onClickChange}
        loading={finishLoading}/>
        case 4:
        return <ResetPassSuccess onClickSubmit={onClickGoLogin} />
        default:
          return <></>
    }
  };

  return (
    <Wrapper>
      <CustomCard>
        {step !== 4 && <Container>
          <Header>
            <IconLogo />
          </Header>
        </Container>}
        {renderStep()}
      </CustomCard>
    </Wrapper>
  );
};

export default ResetPass;
