import { Checkbox } from "@mui/material";

import { Button, CustomCard, Input } from "../../atoms";
import {
  ButtonWrapper,
  Container,
  ForgotPass,
  FormWrapper,
  Header,
  Remember,
  RememberWrapper,
  Wrapper
} from "./Login.styles";
import useLogin from "./useLogin";

import { ReactComponent as IconLogo } from "../../../../assets/icons/ic-logo-large.svg";
import { url } from "inspector";

const Login = () => {
  const {
    username,
    setUsername,
    loginMutate,
    password,
    remember,
    setPassword,
    setRemember,
    isLoading,
    isFetching,
    urls,
    hasError,
    setHasError,
    hasErrorPassword,
    setHasErrorPassword,
    isError
  } = useLogin();

  return (
    <Wrapper>
      <CustomCard>
        <Container>
          <Header>
            <IconLogo />
          </Header>
          <FormWrapper>
            <Input
              title="Username"
              error={hasError}
              errorMessage={() => {
                if (!username) {
                  return "Username Required";
                }

                if (isError) {
                  return "The user name or password is incorrect.";
                }

                setHasError(false);
              }}
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <Input
              title="Password"
              error={hasErrorPassword}
              errorMessage={() => {
                if (!password) {
                  return "Password Required";
                }

                if (password.length < 4) {
                  return "Your Password must be at least 4 characters";
                }

                setHasErrorPassword(false);
              }}
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <RememberWrapper>
              <Checkbox
                sx={{ padding: 0 }}
                checked={remember}
                onChange={e => setRemember(e.target.checked)}
              />
              <Remember>Remember Me</Remember>
            </RememberWrapper>
            <ButtonWrapper>
              <ForgotPass to={urls.public.RESET_PASSWORD.address}>
                Forgot Password?
              </ForgotPass>
              <Button
                title="Login"
                variant="contained"
                loading={isLoading || isFetching}
                onClick={() => {
                  let valid = true;
                  if (!username) {
                    setHasError(true);
                    valid = false;
                  }

                  if (!password || password.length < 4) {
                    setHasErrorPassword(true);
                    valid = false;
                  }

                  if (valid) {
                    setHasError(false);
                    setHasErrorPassword(false);
                    loginMutate({
                      password,
                      rememberMe: remember,
                      username
                    });
                  }
                }}
              />
            </ButtonWrapper>
          </FormWrapper>
        </Container>
      </CustomCard>
    </Wrapper>
  );
};

export default Login;
