import styled from "@emotion/styled";

export const Title = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

export const Message = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[800]};
`;
