import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);

  .main-content {
    padding: 24px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    height: calc(100vh - 128px);
    overflow: auto;

    .form-style {
      padding: 16px;
      border-radius: 16px;
      background: ${({ theme }) => theme.palette.background.default};
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.14);
    }
  }

  .list-content {
    padding: 24px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    height: calc(100vh - 128px);
    overflow: auto;

    .form-style {
      padding: 16px;
      border-radius: 16px;
      background: ${({ theme }) => theme.palette.background.default};
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.14);
      height: calc(100vh - 202px);
      overflow: auto;
    }
  }
`;
