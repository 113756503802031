import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { Auth } from "../network";
import { handleLogin, handleUser, handleUserJWT } from "../redux/auth";
import StorageUtil from "../util/StorageUtil";
import useDashboardRoutes from "./useDashboardRoutes";
import { UseAccount } from ".";
import { IUserJWT } from "../models";
import { IRootState } from "../redux/store";

export const useAuth = () => {
  const [getAccount, setGetAccount] = useState(false);
  const dispatch = useDispatch();
  const { userJWT } = useSelector((state: IRootState) => state.auth);
  const navigate = useNavigate();
  const { urls } = useDashboardRoutes();

  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Auth.login
  );
  const {
    isFetching,
    data: accountData,
    isSuccess: accountSuccess,
    isStale,
  } = UseAccount.useGetAccount(getAccount);

  useEffect(() => {
    if (isSuccess) {
      const token = data.data?.id_token;

      StorageUtil.storageSet(StorageUtil.storageKeys.token, token);
      dispatch(
        handleLogin({
          isLogin: true,
          token: token,
        })
      );
      const decoded = jwt_decode(token);
      dispatch(handleUserJWT(decoded as IUserJWT));
      setGetAccount(true);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (accountSuccess && !isStale) {
      dispatch(handleUser(accountData?.data));
      if (
        userJWT?.auth === "ROLE_ADMIN" ||
        userJWT?.auth === "ROLE_SUPER_ADMIN"
      ) {
        navigate(urls.admin.APPLICATIONS.address, { replace: true });
      } else {
        navigate(urls.interviewer.APPLICANTS.address, { replace: true });
      }
    }
  }, [accountSuccess, accountData]);

  return { mutate, isLoading, isError, isSuccess, error, data, isFetching };
};

export const useResetPassInit = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Auth.resetPassInit
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useResetPassValidate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Auth.resetPassValidate
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useResetPassFinish = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Auth.resetPassFinish
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};
