import HeaderContainer from "../../../atoms/headerContainer/HeaderContainer";
import { Wrapper, IconWrapper, Title } from "./ApplicantFormFilter.styles";

import { ReactComponent as IconBack } from "../../../../../assets/icons/ic-arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { IApplicantsFilterProps } from "../ApplicantForm.interface";

const ApplicantFormFilter = ({ folderName }: IApplicantsFilterProps) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <Wrapper>
        <IconWrapper
          onClick={() => {
            navigate(-1);
            setTimeout(() => {
              if (window.location.pathname.includes("applicants/detail")) {
                navigate(-1);
              }
            }, 200);
          }}
        >
          <IconBack />
          <Title>{`Back ${
            folderName ? "to " + folderName + " Folder" : ""
          }`}</Title>
        </IconWrapper>
      </Wrapper>
    </HeaderContainer>
  );
};

export default ApplicantFormFilter;
