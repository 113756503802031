import { IApplication } from "./IApplication";
import { IFolder } from "./IFolder";
import { IListItem } from "./IListItem";

export interface IApplicantInterviewResult {
  id: number;
  organization: string;
  note: string | null;
  role: string;
  sent: boolean;
}

export interface IApplicantInterview {
  id: number;
  organization: string;
  place: string;
  type: string;
  confidential_note: string | null;
  plan_date: string;
  interview_results: IApplicantInterviewResult[];
}

export interface IApplicant extends Record<string, unknown> {
  id: number;
  organization: string;
  name: string;
  phone: string;
  email: string;
  status: string;
  folder: null | IFolder;
  starred: boolean;
  viewed: boolean;
  interviews: IApplicantInterview | null;
  apply_date: string;
  job_application: IApplication;
  reject_reason: null;
  resume_file_path: string;
  confidential_note?: string;

  selected?: boolean;
}

export enum ApplicantStatus {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  CONFIRM_FOR_INTERVIEW = "CONFIRM_FOR_INTERVIEW",
  HIRED = "HIRED"
}

export enum InterviewType {
  ON_SITE = "ON_SITE",
  ONLINE = "ONLINE"
}

export const getApplicantsStatusAsSelectList = (): IListItem[] => {
  return [
    {
      key: ApplicantStatus.REJECTED,
      value: "Rejected"
    },
    {
      key: ApplicantStatus.CONFIRM_FOR_INTERVIEW,
      value: "Confirm For Interview"
    },
    {
      key: ApplicantStatus.HIRED,
      value: "Hired"
    }
  ];
};

export const getApplicantsStatusAfterInterviewAsSelectList =
  (): IListItem[] => {
    return [
      {
        key: ApplicantStatus.REJECTED,
        value: "Rejected"
      },
      {
        key: ApplicantStatus.HIRED,
        value: "Hired"
      }
    ];
  };

export const getApplicantsStatusBeforeInterviewAsSelectList =
  (): IListItem[] => {
    return [
      {
        key: ApplicantStatus.REJECTED,
        value: "Rejected"
      },
      {
        key: ApplicantStatus.CONFIRM_FOR_INTERVIEW,
        value: "Confirm For Interview"
      }
    ];
  };

export const getApplicantsInterviewTypeAsSelectList = (): IListItem[] => {
  return [
    {
      key: InterviewType.ONLINE,
      value: "ONLINE"
    },
    {
      key: InterviewType.ON_SITE,
      value: "ON_SITE"
    }
  ];
};
