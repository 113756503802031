import { NormalPage, Pagination, Table } from "../../atoms";
import ApplicationsFilter from "./filter/ApplicantsFilter";
import useApplicants from "./useApplicants";
import useApplicantsTableConfig from "./config/config";

const Applicants = () => {
  const {
    totalCount,
    stateData,
    isFetching,
    folderIsFetching,
    filter,
    setFilter,
    handleSelect,
    handleSelectAll,
    allSelected,
    isSomeItemSelected,
    handleStar,
    handleStarAll,
    handleMove,
    handleMoveItem,
    folderData,
    setFilterPosition,
    setFilterStatus,
    setFilterSearch,
    handleClearFilter,
    handleSortDate,
    onClickItem,
  } = useApplicants();
  const { getTableDefinition } = useApplicantsTableConfig(
    allSelected,
    isSomeItemSelected,
    handleSelectAll,
    stateData.length > 0 &&
      stateData.filter((item) => item.starred)?.length === stateData.length,
    handleStarAll,
    handleSelect,
    handleStar,
    handleMoveItem,
    folderData?.data?.content ?? [],
    onClickItem
  );

  return (
    <NormalPage>
      <ApplicationsFilter
        handleClearFilter={handleClearFilter}
        handleFilterPosition={setFilterPosition}
        handleFilterStatus={setFilterStatus}
        handleSearch={(e) =>
          setFilterSearch(
            (e as React.ChangeEvent<HTMLInputElement>).target.value
          )
        }
        handleSortDate={handleSortDate}
        folders={folderData?.data?.content ?? []}
        onChangeFolder={handleMove}
        showChangeFolder={isSomeItemSelected}
      />
      <div className="list-content">
        <div className="form-style">
          <Table
            definitions={getTableDefinition()}
            items={stateData}
            isLoading={isFetching || folderIsFetching}
          />
          <Pagination
            onPageChanged={(page) =>
              setFilter({
                ...filter,
                page: page - 1,
              })
            }
            totalCount={totalCount}
            currentPage={filter.page + 1}
          />
        </div>
      </div>
    </NormalPage>
  );
};

export default Applicants;
