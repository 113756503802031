import { Button, Input, NormalPage, Select, TinyEditor } from "../../atoms";
import ApplicantFormFilter from "./filter/ApplicationFormFilter";
import useApplicationForm from "./useApplicationForm";
import {
  Wrapper,
  ButtonWrapper,
  LineWrapper,
  Title,
} from "./ApplicationForm.styles";
import { IApplicationFormProps } from "./ApplicationForm.interface";
import {
  getApplicationStatusAsSelectList,
  getApplicationStatusAsSelectListForCreate,
} from "../../../../models";

const ApplicationForm = ({ existModel }: IApplicationFormProps) => {
  const {
    model,
    jobPositions,
    setModel,
    createLoading,
    onClickSubmit,
    updateLoading,
    onClickCancel,
    formSubmit,
  } = useApplicationForm(existModel);

  return (
    <>
      <NormalPage>
        <ApplicantFormFilter />
        <div className="main-content">
          <div className="form-style">
            <Wrapper>
              <Title>{existModel ? "Edit" : "New"} Job Application</Title>
              <LineWrapper className="tree-item">
                <Input
                  error={formSubmit && !model.title}
                  placeholder="Job Title"
                  title="Job Title"
                  value={model.title}
                  sx={{ flex: 1 }}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      title: e.target.value,
                    })
                  }
                />
                <Select
                  items={jobPositions}
                  selectProps={{
                    error: formSubmit && !model.job_position?.id,
                    title: "Job Position",
                    value: model.job_position ? model.job_position.id : "",
                    sx: { flex: 1 },
                    onChange: (e) => {
                      setModel({
                        ...model,
                        job_position: {
                          ...model.job_position!,
                          id: Number(e.target.value),
                        },
                      });
                    },
                  }}
                />
                <Select
                  items={
                    existModel
                      ? getApplicationStatusAsSelectList()
                      : getApplicationStatusAsSelectListForCreate()
                  }
                  selectProps={{
                    error: formSubmit && !model.status,
                    title: "Status",
                    sx: { flex: 1 },
                    defaultValue: model.status,
                    onChange: (e) => {
                      setModel({
                        ...model,
                        status: String(e.target.value),
                      });
                    },
                  }}
                />
              </LineWrapper>

              <LineWrapper>
                <Input
                  error={formSubmit && !model.summery}
                  placeholder="Summary"
                  title="Summary"
                  value={model.summery}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      summery: e.target.value,
                    })
                  }
                />
              </LineWrapper>

              {/* <LineWrapper>
                <Input
                  placeholder="Url"
                  title="Url"
                  value={model.url}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      url: e.target.value,
                    })
                  }
                />
              </LineWrapper> */}

              {/* <LineWrapper className="two-item">
                <DatePicker
                  title="Start Date"
                  datePickerProps={{
                    sx: { flex: 1 },
                    defaultValue: model.start_date
                      ? dayjs(model.start_date)
                      : undefined,
                    onChange: (val: any) => {
                      setModel({
                        ...model,
                        start_date: (val as Dayjs).format("YYYY-MM-DD"),
                      });
                    },
                  }}
                />

                <DatePicker
                  title="End Date"
                  datePickerProps={{
                    sx: { flex: 1 },
                    defaultValue: model.exp_date
                      ? dayjs(model.exp_date)
                      : undefined,
                    onChange: (val: any) => {
                      setModel({
                        ...model,
                        exp_date: (val as Dayjs).format("YYYY-MM-DD"),
                      });
                    },
                  }}
                />
              </LineWrapper> */}

              <LineWrapper>
                <TinyEditor
                  error={formSubmit && !model.description}
                  errorMessage="Description Required"
                  onChange={(val) =>
                    setModel({
                      ...model,
                      description: val,
                    })
                  }
                  initVal={model.description}
                />
              </LineWrapper>

              <ButtonWrapper>
                <Button
                  title="Cancel"
                  onClick={onClickCancel}
                  variant="outlined"
                  sx={{ maxWidth: "210px" }}
                />
                <Button
                  title="Submit"
                  onClick={onClickSubmit}
                  variant="contained"
                  sx={{ maxWidth: "210px" }}
                  loading={createLoading || updateLoading}
                />
              </ButtonWrapper>
            </Wrapper>
          </div>
        </div>
      </NormalPage>
    </>
  );
};

export default ApplicationForm;
