import { Routes as Router, Route } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

import useApp from "./useApp";
import {
  ApplicantDetail,
  ApplicantList,
  ApplicationCreate,
  ApplicationList,
  ApplicationUpdate,
  AuthLogin,
  JobPositionList,
  ResetPassword,
  RoleList,
  UserCreate,
  UserList,
  UserUpdate
} from "../pages";
import { AdminLayout } from "../atomic/layouts";

export const PageRouter = () => {
  const { urls, isFetching } = useApp();

  if (isFetching) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Router>
      <Route path={urls.public.LOGIN.address}>
        <Route element={<AuthLogin />} index />
        <Route
          element={<ResetPassword />}
          path={urls.public.RESET_PASSWORD.address}
        />
      </Route>

      <Route element={<AdminLayout />}>
        <Route path={urls.admin.APPLICATIONS.address}>
          <Route element={<ApplicationList />} index />
          <Route
            element={<ApplicationCreate />}
            path={urls.adminSubRoots.APPLICATION_CREATE.address}
          />
          <Route
            element={<ApplicationUpdate />}
            path={urls.adminSubRoots.APPLICATION_UPDATE.address}
          />
        </Route>

        <Route path={urls.admin.APPLICANTS.address}>
          <Route element={<ApplicantList />} index />
          <Route
            element={<ApplicantList />}
            path={urls.admin.FOLDERS.address}
          />
          <Route
            element={<ApplicantDetail />}
            path={urls.adminSubRoots.APPLICANT_DETAIL.address}
          />
        </Route>

        <Route path={urls.admin.MEMBERS.address}>
          <Route element={<UserList />} index />
          <Route
            element={<UserCreate />}
            path={urls.adminSubRoots.MEMBER_CREATE.address}
          />
          <Route
            element={<UserUpdate />}
            path={urls.adminSubRoots.MEMBER_UPDATE.address}
          />
        </Route>

        <Route path={urls.admin.JOBS.address}>
          <Route element={<JobPositionList />} index />
        </Route>

        <Route path={urls.admin.ROLES.address}>
          <Route element={<RoleList />} index />
        </Route>
      </Route>
    </Router>
  );
};

export default PageRouter;
