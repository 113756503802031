import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { AxiosError } from "axios";
import { BrowserRouter } from "react-router-dom";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@emotion/react";

import "react-toastify/dist/ReactToastify.css";
import "./style/index.scss";

import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { DefaultTheme } from "./style/baseTheme";
import { App } from "./components";
import { getServerMessage } from "./util/constants";
import { useAlert } from "./hooks";

const Index = () => {
  const { alert } = useAlert();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: error => {        
        if ((error as AxiosError<any>).response?.status !== 401) {
          const message = getServerMessage(error as AxiosError<any>);
          alert({
            message,
            variant: "error"
          });
        }
      }
    }),
    mutationCache: new MutationCache({
      onError: error => {
        if ((error as AxiosError<any>).response?.status !== 401) {
          const message = getServerMessage(error as AxiosError<any>);
          alert({
            message,
            variant: "error"
          });
        }
      }
    })
  });

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={DefaultTheme}>
          <ToastContainer />
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
