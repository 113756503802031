import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const InputTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 4px;
`;

export const ErrorWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.error.dark};
  margin-top: 4px;
  gap:4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
`;