import { Button, Input } from "../../atoms";
import { IResetPassInitProps } from "./ResetPass.interface";
import {
  ButtonWrapper,
  FormWrapper,
  Remember,
  RememberWrapper
} from "./ResetPass.styles";

import { ReactComponent as IconArrow } from "../../../../assets/icons/ic-arrow-left.svg";

const ResetPassInit = ({
  email,
  onClick,
  setEmail,
  isLoading,
  onClickBack
}: IResetPassInitProps) => {
  return (
    <FormWrapper>
      <Input
        title="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <ButtonWrapper>
        <RememberWrapper>
          <IconArrow />
          <Remember onClick={onClickBack}>Back To Login</Remember>
        </RememberWrapper>
        <Button
          title="Send Verification Code"
          variant="contained"
          loading={isLoading}
          onClick={onClick}
        />
      </ButtonWrapper>
    </FormWrapper>
  );
};

export default ResetPassInit;
