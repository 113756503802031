import { MenuItem } from "@mui/material";
import { Wrapper, StyledSelect, Title, ErrorWrapper } from "./Select.styles";
import { ISelectProps } from "./Select.interface";

import { ReactComponent as IconError } from "../../../../assets/icons/ic-error.svg";

const Select = ({ items, selectProps, errorMessage }: ISelectProps) => {
  return (
    <Wrapper
      style={selectProps.sx ? (selectProps.sx as React.CSSProperties) : {}}
    >
      <Title error={selectProps.error}>{selectProps.title}</Title>
      <StyledSelect {...selectProps} displayEmpty>
        {items.map((item, index) => (
          <MenuItem key={index} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </StyledSelect>
      {selectProps.error && (
        <ErrorWrapper>
          <IconError />
          <span>{errorMessage ?? `${selectProps.title} required`}</span>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default Select;
