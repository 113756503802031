import { useEffect, useState } from "react";
import { UseAuth, useDashboardRoutes } from "../../../../hooks";

const useLogin = () => {
  const { urls } = useDashboardRoutes();
  // const { alert } = useAlert();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasErrorPassword, setHasErrorPassword] = useState(false);

  const {
    mutate: loginMutate,
    isLoading,
    isFetching,
    isError
  } = UseAuth.useAuth();

  useEffect(() => {
    if (isError) {
      setHasError(true);
    }
  }, [isError]);

  return {
    loginMutate,
    username,
    setUsername,
    password,
    setPassword,
    remember,
    setRemember,
    isLoading,
    isFetching,
    urls,
    hasError,
    hasErrorPassword,
    setHasError,
    setHasErrorPassword,
    isError
  };
};

export default useLogin;
