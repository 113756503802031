import { IApplicationListItemProps } from "./ApplicationListItem.interface";
import {
  Container,
  Date,
  DateWrapper,
  EditText,
  IconItemWrapper,
  IconWrapper,
  InfoLine,
  InfoWrapper,
  JobPosition,
  JobTitle,
  PublishedDate,
} from "./ApplicationListItem.styles";

import { ReactComponent as IconTrash } from "../../../../assets/icons/ic-trash.svg";
import { ReactComponent as IconPreview } from "../../../../assets/icons/ic-eye.svg";
import { ReactComponent as IconEdit } from "../../../../assets/icons/ic-edit-blue.svg";

const HederContainer = ({
  jobPosition,
  jobTitle,
  onClickDelete,
  onClickEdit,
  showDate = false,
  showPreview,
  onClickPreview,
  publishedDate,
  closedDate,
  id,
  link
}: IApplicationListItemProps) => {
  return (
    <Container>
      <InfoWrapper>
        <InfoLine>
          <JobTitle>{jobTitle}</JobTitle>
          <JobPosition>{`/ ${jobPosition}`}</JobPosition>
        </InfoLine>
        {showDate && publishedDate && (
          <DateWrapper>
            <InfoLine>
              <PublishedDate>Publish date:</PublishedDate>
              <Date>{` ${publishedDate}`}</Date>
            </InfoLine>
            {closedDate && (
              <InfoLine>
                <PublishedDate>Closed date:</PublishedDate>
                <Date>{` ${closedDate}`}</Date>
              </InfoLine>
            )}
          </DateWrapper>
        )}
      </InfoWrapper>
      <IconWrapper>
        <IconItemWrapper onClick={() => onClickDelete(id)}>
          <IconTrash />
          <JobPosition>Delete</JobPosition>
        </IconItemWrapper>
        {showPreview && (
          <IconItemWrapper onClick={() => onClickPreview?.(link)}>
            <IconPreview />
            <JobPosition>Preview</JobPosition>
          </IconItemWrapper>
        )}
        <IconItemWrapper onClick={() => onClickEdit(id)}>
          <IconEdit />
          <EditText>Edit</EditText>
        </IconItemWrapper>
      </IconWrapper>
    </Container>
  );
};

export default HederContainer;
