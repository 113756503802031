import { AxiosError } from "axios";

export const PAGE_SIZE = 10;

export const getServerMessage = (error: AxiosError<any>) => {
  try {
    const message = (error as AxiosError<any>).response?.data?.message;
    if (Array.isArray(message)) {
      return message[0];
    } else {
      if (typeof message === "string") {
        return message;
      } else {
        const keys = Object.keys(message);
        const msg = message[keys[0]];
        if (Array.isArray(msg)) {
          return msg[0];
        } else {
          return msg;
        }
      }
    }
  } catch (e) {
    return "Something bad happened";
  }
};
