import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Container>
      <CircularProgress size={20} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Loading;
