import { Divider } from "@mui/material";

import {
  NormalPage,
  Loading,
  Input,
  Button,
  DeleteConfirmModal,
} from "../../atoms";
import ApplicantFormFilter from "./filter/ApplicantFormFilter";
import useApplicantInterviewerForm from "./useApplicantInterviewerForm";
import {
  FormWrapper,
  MainFormWrapper,
  RightWrapper,
  MainWrapper,
} from "./ApplicantInterviewerForm.styles";
import { ApplicantStatus } from "../../../../models";
import InfoSection from "./InfoSection";
import InterviewSection from "./InterviewSection";

const ApplicantForm = () => {
  const {
    data,
    isFetching,
    note,
    setNote,
    noteLoading,
    noteMutate,
    onClickDownload,
    sendMutate,
    sendLoading,
    resumeUrl,
    showDeleteModal,
    setShowDeleteModal,
    showConfirm,
    setShowConfirm,
  } = useApplicantInterviewerForm();

  if (isFetching) {
    return <Loading />;
  }

  return (
    <>
      <NormalPage>
        <ApplicantFormFilter />
        <div className="main-content">
          <MainWrapper>
            <InfoSection
              data={data?.data!}
              resumeUrl={resumeUrl}
              onClickDownload={onClickDownload}
            />
            {data?.data.status !== String(ApplicantStatus.REJECTED) && (
              <RightWrapper>
                <FormWrapper>
                  <MainFormWrapper>
                    <InterviewSection data={data?.data!} />
                    <Input
                      title="Interview Note"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      multiline={true}
                      rows={5}
                      maxRows={5}
                    />
                    <Button
                      title="Save"
                      variant="contained"
                      sx={{ maxWidth: "fit-content", alignSelf: "end" }}
                      loading={noteLoading}
                      onClick={() =>
                        noteMutate({
                          id: Number(data?.data.current_interviewer_result.id),
                          note,
                        })
                      }
                    />
                    <Divider
                      variant="fullWidth"
                      orientation="horizontal"
                      sx={{ width: "100%" }}
                    />
                    <Button
                      title="Send"
                      variant="contained"
                      sx={{ maxWidth: "fit-content", alignSelf: "end" }}
                      onClick={() => {
                        if (
                          note !== data?.data.current_interviewer_result.note
                        ) {
                          setShowConfirm(true);
                        } else {
                          setShowDeleteModal(true);
                        }
                      }}
                    />
                  </MainFormWrapper>
                </FormWrapper>
              </RightWrapper>
            )}
          </MainWrapper>
        </div>
      </NormalPage>
      <DeleteConfirmModal
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={() =>
          sendMutate(Number(data?.data.current_interviewer_result.id))
        }
        title="Attention!"
        message="Before sending, please be aware that once sent, it will disappear and cannot be edited or retrieved."
        loading={sendLoading}
        deleteButtonTitle="Send"
      />
      <DeleteConfirmModal
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => setShowConfirm(false)}
        title="Attention!"
        message="Please ensure you write and save your note in the provided section before submitting the application. Thank you!"
        deleteButtonTitle="OK"
      />
    </>
  );
};

export default ApplicantForm;
