import { useMutation, useQuery } from "@tanstack/react-query";

import { Interviewer } from "../network";
import Queries from "../util/Queries";
import { IRequestFilter } from "../models";

export const useGetInterviewer = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_INTERVIEWERS, filter],
      () => Interviewer.getInterviewers(filter),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useGetResumes = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_INTERVIEWER_RESUMES, filter],
      () => Interviewer.getResumes(filter),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useGetResume = (id: number) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_INTERVIEWER_RESUME, id],
      () => Interviewer.getResume(id),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useAddNote = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Interviewer.addNote
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useSend = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Interviewer.send
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};
