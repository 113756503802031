import axios, { AxiosResponse } from "axios";

import { IApplicant, IResponse } from "../../models";
import {
  IApplicantAddNote,
  IApplicantConfirm,
  IApplicantCreateByAdmin,
  IApplicantHire,
  IApplicantMoveToFolder,
  IApplicantRejection,
  IApplicantStarred,
  IGetApplicantFilter
} from "./interfaces";

export const getApplicants = (
  filter: IGetApplicantFilter
): Promise<AxiosResponse<IResponse<IApplicant>, any>> => {
  return axios.get("/api/v1/applicants", {
    params: filter
  });
};

export const getApplicantsStarred = (
  filter: IGetApplicantFilter
): Promise<AxiosResponse<IResponse<IApplicant>, any>> => {
  return axios.get("/api/v1/applicants/starred", {
    params: filter
  });
};

export const getApplicantAdmin = (
  id: number
): Promise<AxiosResponse<IApplicant, any>> => {
  return axios.get(`/api/v1/applicants/${id}/admin`);
};

export const getApplicantResume = (
  id: number
): Promise<AxiosResponse<Blob, any>> => {
  return axios.get(`/api/v1/applicants/${id}/attachment`, {
    responseType: "blob"
  });
};

export const updateByAdmin = (
  data: IApplicantCreateByAdmin
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/applicants/${data.id}/admin`, data);
};

export const removeApplicantsFromFolder = (
  ids: number[]
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put("/api/v1/applicants/remove-from-folder", {
    data: {
      ids
    }
  });
};

export const archiveApplicants = (
  ids: number[]
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put("/api/v1/applicants/archive", {
    data: {
      ids
    }
  });
};

export const moveApplicantsFromFolder = (
  data: IApplicantMoveToFolder
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/applicants/move-to-folder`, {
    ids: data.ids,
    job_folder_id: data.folderId
  });
};

export const setApplicantsStar = (
  data: IApplicantStarred
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/applicants/starred`, {
    ids: data.ids,
    star: data.starred
  });
};

export const addNote = (
  data: IApplicantAddNote
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/applicants/${data.id}/confidential-note`, data);
};

export const reject = (
  data: IApplicantRejection
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/applicants/${data.id}/reject`, {
    email_content: data.email_content,
    reject_reason: data.reject_reason
  });
};

export const confirm = (
  data: IApplicantConfirm
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/applicants/${data.id}/confirm-for-interview`, data);
};

export const hire = (
  data: IApplicantHire
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/applicants/${data.id}/hire`, data);
};
