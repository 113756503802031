import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  .MuiTextField-root {
    height: 40px;
    width: 100%;
    outline: none;

    .MuiInputBase-root {
      height: 40px;
      border-radius: 8px;
      background-color: ${({ theme }) => theme.palette.background.default};
      border: 1px solid ${({ theme }) => theme.palette.grey[700]};
      outline: none;

      input {
        outline: none;
      }
    }
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 4px;
`;
