import moment from "jalali-moment";
import { useTheme } from "@emotion/react";

import { IApplicant, IFolder, IListItem } from "../../../../../models";
import { ITableDefinition, Select } from "../../../atoms";

import { ReactComponent as IconCheckBox } from "../../../../../assets/icons/ic-check-box.svg";
import { ReactComponent as IconCheckBoxSemi } from "../../../../../assets/icons/ic-check-box-semi-checked.svg";
import { ReactComponent as IconCheckBoxSelected } from "../../../../../assets/icons/ic-check-box-checked.svg";
import { ReactComponent as IconStar } from "../../../../../assets/icons/ic-star.svg";
import { ReactComponent as IconStarGold } from "../../../../../assets/icons/ic-start-gold.svg";

const useApplicantsTableConfig = (
  isAllSelected: boolean,
  isSomeSelected: boolean,
  setSelectedAll: (selected: boolean) => void,
  isAllFavorite: boolean,
  setFavoriteAll: (selected: boolean) => void,
  setSelected: (selected: boolean, id: number) => void,
  setFavorite: (favorite: boolean, id: number) => void,
  onChangeFolder: (folderId: number, id: number) => void,
  folders: IFolder[],
  onClickItem: (id: number) => void
) => {
  const theme = useTheme();

  const getTableDefinition = (): ITableDefinition<IApplicant>[] => {
    return [
      {
        columnKey: "selected",
        label: "check",
        headerStyle: () => ({
          textAlign: "center",
        }),
        columnStyle: (data) => ({
          textAlign: "center",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        headerRenderer: () => {
          return isAllSelected ? (
            <IconCheckBoxSelected onClick={() => setSelectedAll(false)} />
          ) : isSomeSelected ? (
            <IconCheckBoxSemi onClick={() => setSelectedAll(false)} />
          ) : (
            <IconCheckBox onClick={() => setSelectedAll(true)} />
          );
        },
        renderer: (data) => {
          return data.selected ? (
            <IconCheckBoxSelected onClick={() => setSelected(false, data.id)} />
          ) : (
            <IconCheckBox onClick={() => setSelected(true, data.id)} />
          );
        },
      },
      {
        columnKey: "star",
        label: "favorite",
        headerStyle: () => ({
          textAlign: "center",
        }),
        columnStyle: (data) => ({
          textAlign: "center",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        headerRenderer: () => {
          return isAllFavorite ? (
            <IconStarGold onClick={() => setFavoriteAll(false)} />
          ) : (
            <IconStar onClick={() => setFavoriteAll(true)} />
          );
        },
        renderer: (data: IApplicant) => {
          return data.starred ? (
            <IconStarGold onClick={() => setFavorite(false, data.id)} />
          ) : (
            <IconStar onClick={() => setFavorite(true, data.id)} />
          );
        },
      },
      {
        columnKey: "name",
        columnStyle: (data) => ({
          cursor: "pointer",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        label: "Applicant’s name",
        onColumnClick: (data) => {
          onClickItem(data.id);
        },
      },
      {
        columnKey: "position",
        label: "Position",
        columnStyle: (data) => ({
          cursor: "pointer",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          return <>{data.job_application?.job_position?.title}</>;
        },
        onColumnClick: (data) => {
          onClickItem(data.id);
        },
      },
      {
        columnKey: "phone_number",
        label: "Date",
        columnStyle: (data) => ({
          cursor: "pointer",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          const daysAgo = moment(data.apply_date).diff(moment(), "days") < -30;
          return (
            <>
              {daysAgo
                ? moment(data.apply_date).format("DD / MMM / YYYY")
                : moment(data.apply_date).fromNow()}
            </>
          );
        },
        onColumnClick: (data) => {
          onClickItem(data.id);
        },
      },
      {
        columnKey: "folder",
        label: "Folder",
        columnStyle: (data) => ({
          width: "150px",
          minWidth: "150px",
          maxWidth: "150px",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          return (
            <Select
              items={folders.map(
                (folder): IListItem => ({
                  key: folder.id,
                  value: folder.name,
                })
              )}
              selectProps={{
                value: data.folder ? data.folder.id : "",
                placeholder:'Not selected',
                label:'Not selected',
                sx: { width: "100%" },
                onChange: (e) => {
                  onChangeFolder(Number(e.target.value), data.id);
                },
              }}
            />
          );
        },
      },
      {
        columnKey: "status",
        label: "Status",
        columnStyle: (data) => ({
          cursor: "pointer",
          backgroundColor: data.viewed
            ? theme.palette.info.light
            : theme.palette.background.default,
        }),
        onColumnClick: (data) => {
          onClickItem(data.id);
        },
      },
    ];
  };

  return { getTableDefinition };
};

export default useApplicantsTableConfig;
