import axios from "axios";

import StorageUtil from "../util/StorageUtil";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const authUrls = [
  "api/authenticate",
  "api/v1/accounts/reset-password/init",
  "api/v1/accounts/reset-password/validation",
  "api/v1/accounts/reset-password/finish",
];

axios.interceptors.request.use((config) => {
  if (config.headers) {
    const token: any = StorageUtil.storageGet(StorageUtil.storageKeys.token);

    if (token && !authUrls.includes(config.url!)) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
});
