import { Box, Modal, Paper } from "@mui/material";
import { IDeleteConfirmModalProps } from "./DeleteConfirmModal.interface";
import Button from "../button/Button";
import { Message, Title } from "./DeleteConfirmModal.styles";

const DeleteConfirmModal = ({
  open,
  onCancel,
  onConfirm,
  message,
  title,
  loading = false,
  deleteButtonTitle
}: IDeleteConfirmModalProps) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          padding: "16px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: "70%",
            padding: "16px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="16px"
          >
            <Title>{title ?? "Delete Item"}</Title>
            <Message>{message ?? "Are you sure to delete item?"}</Message>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyItems="center"
              gap={2}
              justifyContent="flex-end"
            >
              <Button variant="outlined"  onClick={onCancel} title={'Cancel'} />
              <Button
                variant="contained"
                color="error"                
                loading={loading}
                onClick={onConfirm}
                title={deleteButtonTitle ?? 'Delete'}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmModal;
