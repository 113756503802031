import { LoadingButton } from "@mui/lab";
import { styled as MUIStyled } from "@mui/material";

export const CustomizedButton = MUIStyled(LoadingButton)(({ theme }) => ({
  color: theme.palette.background.default,
  fontSize: "14px",
  fontWeight: 500,
  borderRadius: "20px",
  padding: "8px 16px",
  width: "100%",

  "&.MuiButton-outlined": {
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[800],
  },
}));
