import { useQuery } from "@tanstack/react-query";

import { Account } from "../network";
import Queries from "../util/Queries";

export const useGetAccount = (active: boolean = true) => {
  const {
    data,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    refetch,
    isStale
  } = useQuery([Queries.GET_ACCOUNT], () => Account.getAccount(), {
    keepPreviousData: true,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: active,
    staleTime: 2000
  });

  return {
    data,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    refetch,
    isStale
  };
};
