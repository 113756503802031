import { useEffect, useState } from "react";
import { hireTextEmail } from "./textConfig";

const useApplicantHireModal = (jobPosition: string) => {
  const [content, setContent] = useState(
    hireTextEmail.replace("{job_position}", jobPosition)
  );

  useEffect(() => {
    setContent(hireTextEmail.replace("{job_position}", jobPosition));
  }, [jobPosition]);

  return {
    content,
    setContent,
  };
};

export default useApplicantHireModal;
