export const interviewTextEmail = `<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR"><strong>ایمیل اطلاع رسانی به متقاضی استخدام جهت اعلام زمان مصاحبه</strong></span></span></span></span></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">موضوع </span></span></span></span>: <span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">اعلام زمان مصاحبه استخدامی</span></span></span></span></p>
<p dir="rtl" align="right">&nbsp;</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">با سلام و احترام </span></span></span></span></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;">از شما به خاطر ارسال رزومه صمیمانه سپاسگزاریم</span></span></span>. <span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">رزومه شما مورد بررسی قرار گرفت</span></span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;">از شما برای مصاحبه {type} دعوت به عمل می آوریم</span></span></span>. <span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;">هدف از این مصاحبه آشنایی بیشتر با شما و همین&zwnj;طور ارائه اطلاعات بیشتر در خصوص این موقعیت شغلی است</span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">زمان مصاحبه شما </span></span></span></span>:</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">تاریخ </span></span></span></span>:{date}</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">ساعت </span></span></span></span>:{time}</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">مکان </span></span></span></span>:{place}</p>
<p dir="rtl" align="right"><br><br></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">لطفا حداکثر </span></span></span></span>15 <span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">دقیقه قبل از زمان اعلام شده برای مصاحبه در محل حاضر شوید </span></span></span></span>. <span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">برای هر گونه اطلاعات بیشتر قبل از مصاحبه با ما تماس بگیرید </span></span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">منتظر دیدار شما هستیم و امیدواریم این مصاحبه با موفقیت منجر به استخدام شما در مجموعه ما گردد </span></span></span></span>.</p>
<p dir="rtl" align="right"><br><br></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">با تشکر از همکاری شما </span></span></span></span></p>
<p dir="rtl" align="right"><br><br></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">با احترام </span></span></span></span>:</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">هانیه آصفی</span></span></span></span></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">مدیر منابع انسانی</span></span></span></span></p>
<p dir="rtl" align="right"><img src="https://sjicompany.com/imgpsh_fullsize_anim.png" alt="sji" width="128" height="64" /></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">شکوه جاوید ایرانیان</span></span></span></span></p>
<p align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">تلفن </span></span></span></span>: 985191007575 +</p>
<p dir="rtl" align="justify"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">ایمیل </span></span></span></span>: <span style="color: #0563c1;"><u><a href="mailto:h.asefi@sjicompany.com">h.asefi@sjicompany.com</a></u></span></p>
<table style="border-collapse: collapse; width: 100%; border-width: 1px; background-color: rgb(206, 212, 217);" border="1"><colgroup><col style="width: 99.9193%;"></colgroup>
<tbody>
<tr>
<td>
<p>Subject: Interview Schedule Notification for Job Application</p>
<p>Dear {name},</p>
<p>&nbsp;</p>
<p>Greetings and respect,</p>
<p>We sincerely appreciate your submission of the resume. Your resume has been reviewed, and we are pleased to invite you for an interview to further discuss your qualifications.</p>
<p>The purpose of this interview is to get to know you better and provide additional information about the job opportunity. You are invited to attend the interview in one of the following formats: in-person at our office, via Skype, or over the phone.</p>
<p>Your interview details are as follows:</p>
<p>Date:{date}</p>
<p>Time:{time}</p>
<p>Location:{place}</p>
<p>Please ensure that you arrive at the specified location 15 minutes before the scheduled interview time. If you have any questions or need further information before the interview, feel free to contact us.</p>
<p>We look forward to meeting you, and we hope that this interview will lead to a successful collaboration and your employment with our company.</p>
<p>Thank you for your cooperation.</p>
<p>&nbsp;</p>
<p>Best regards,</p>
<p>Haniye Asefi</p>
<p>Human Resources Manager</p>
<p><img src="https://sjicompany.com/imgpsh_fullsize_anim.png" alt="sji" width="128" height="64" /></p>
<p>Shokooh Javid Iranian</p>
<p>Phone: +985191007575</p>
<p>Email: h.asefi@sjicompany.com</p>
</td>
</tr>
</tbody>
</table>
<p style="text-align: left;"><span style="font-family: Times New Roman, serif;"><span style="font-size: medium;">&nbsp;</span></span></p>
`;
