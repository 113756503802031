import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { IInterviewer, IListItem } from "../../../../models";
import { UseApplicant, UseInterviewer, useAlert } from "../../../../hooks";

const useApplicantForm = () => {
  const { alert } = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();

  const [note, setNote] = useState("");
  const [status, setStatus] = useState("");
  const [hasInterviewerComment, setHasInterviewerComment] = useState(false);
  const [interviewers, setInterviewers] = useState<IListItem[]>([]);
  const [selectedInterviewer, setSelectedInterviewers] = useState<
    IInterviewer[]
  >([]);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [showHiredModal, setShowHiredModal] = useState(false);
  const [resumeUrl, setResumeUrl] = useState<string | undefined>();

  const { data, isError, isFetching, refetch } = UseApplicant.useGetApplicant(
    Number(id)
  );
  const { data: resumeData, isSuccess: resumeSuccess } =
    UseApplicant.useGetApplicantResume(Number(id));
  const { data: interviewData } = UseInterviewer.useGetInterviewer({
    page: 0,
    size: 1000,
    filter: "",
    sort: "id,desc",
  });
  const {
    mutate: noteMutate,
    isSuccess: noteSuccess,
    isLoading: noteLoading,
  } = UseApplicant.useAddEditNote();
  const {
    mutate: rejectMutate,
    isSuccess: rejectSuccess,
    isLoading: rejectLoading,
  } = UseApplicant.useReject();
  const {
    mutate: confirmMutate,
    isSuccess: confirmSuccess,
    isLoading: confirmLoading,
  } = UseApplicant.useConfirm();
  const {
    mutate: hireMutate,
    isSuccess: hireSuccess,
    isLoading: hireLoading,
  } = UseApplicant.useHire();

  const onClickCancel = () => {
    navigate(-1);
    setTimeout(() => {
      if (window.location.pathname.includes("applicants/detail")) {
        navigate(-1);
      }
    }, 500);
  };

  const onClickDownload = () => {
    if (resumeUrl) {
      window.open(resumeUrl, "_blank");
    }
  };

  useEffect(() => {
    if (interviewData?.data.content) {
      setInterviewers(
        interviewData.data.content.map(
          (item): IListItem => ({
            key: item.id,
            value: item.name,
          })
        )
      );
    }
  }, [interviewData]);

  useEffect(() => {
    if (noteSuccess) {
      alert({
        message: "Note add/edit success",
        variant: "success",
      });
    }
  }, [noteSuccess]);

  useEffect(() => {
    if (rejectSuccess) {
      alert({
        message: "Applicant rejected success",
        variant: "success",
      });
      setShowRejectionModal(false);
      refetch();
    }
  }, [rejectSuccess]);

  useEffect(() => {
    if (confirmSuccess) {
      alert({
        message: "Applicant confirmed for interview success",
        variant: "success",
      });
      setShowInterviewModal(false);
      refetch();
    }
  }, [confirmSuccess]);

  useEffect(() => {
    if (hireSuccess) {
      alert({
        message: "Applicant hired success",
        variant: "success",
      });
      setShowHiredModal(false);
      refetch();
    }
  }, [hireSuccess]);

  useEffect(() => {
    if (data?.data) {
      setNote(data.data.confidential_note ?? "");
      const interviewResult = data?.data?.interviews?.interview_results;
      if (interviewResult) {
        for (let i = 0; i < interviewResult.length; i++) {
          if (interviewResult[i].note) {
            setHasInterviewerComment(true);
            break;
          }
        }
      }
      if (
        data?.data.interviews?.interview_results &&
        data?.data.interviews?.interview_results.length > 0
      ) {
        setSelectedInterviewers(
          data?.data.interviews?.interview_results.map(
            (item): IInterviewer => ({
              id: item.id,
              name: item.role,
              user: null,
            })
          ) ?? []
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      navigate(-1);
    }
  }, [isError]);

  useEffect(() => {
    if (resumeSuccess && resumeData?.data) {
      const blob = new Blob([resumeData.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setResumeUrl(url);
    }
  }, [resumeData, resumeSuccess]);

  return {
    alert,
    onClickCancel,
    data,
    isFetching,
    interviewers,
    noteMutate,
    noteLoading,
    note,
    setNote,
    id,
    status,
    setStatus,
    rejectMutate,
    rejectLoading,
    confirmMutate,
    confirmLoading,
    hireMutate,
    hireLoading,
    showRejectionModal,
    showInterviewModal,
    setShowInterviewModal,
    showHiredModal,
    setShowHiredModal,
    setShowRejectionModal,
    selectedInterviewer,
    setSelectedInterviewers,
    interviewData,
    resumeUrl,
    onClickDownload,
    hasInterviewerComment,
    setHasInterviewerComment,
  };
};

export default useApplicantForm;
