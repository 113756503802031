import styled from "@emotion/styled";

export const Title = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
