import {
  BootstrapInput,
  ErrorWrapper,
  InputTitle,
  Wrapper,
} from "./Input.styles";
import { IInputProps } from "./Input.interface";

import { ReactComponent as IconError } from "../../../../assets/icons/ic-error.svg";

const Input = (props: IInputProps) => {
  return (
    <Wrapper
      onClick={(e) => props?.onClick?.(e)}
      style={props.sx ? (props.sx as React.CSSProperties) : {}}
    >
      <InputTitle error={props.error}>{props.title}</InputTitle>
      <BootstrapInput {...props} />
      {props.error && (
        <ErrorWrapper>
          <IconError />
          <span>{typeof(props.errorMessage) === 'function' ? props.errorMessage() : props.errorMessage  ?? `${props.title} required`}</span>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default Input;
