import { useEffect, useState } from "react";

import { UseJobPosition } from "../../../../../hooks";

const useApplicantsFilter = () => {
  const [clearEnable, setClearEnable] = useState(false);
  const [sortDate, setSortDate] = useState<string | undefined>();
  const [filterPosition, setFilterPosition] = useState<string | undefined>();

  const { data: jobPositionData } = UseJobPosition.useGetJobPositions({
    page: 0,
    size: 1000,
    filter: "",
    sort: "id,desc"
  });

  useEffect(
    () => {
      if (sortDate || filterPosition) {
        setClearEnable(true);
      } else {
        setClearEnable(false);
      }
    },
    [sortDate, filterPosition]
  );

  return {
    sortDate,
    setSortDate,
    filterPosition,
    setFilterPosition,
    jobPositionData,
    clearEnable
  };
};

export default useApplicantsFilter;
