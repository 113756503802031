import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import { useDashboardRoutes } from "../../../../hooks";
import { INavList } from "./AdminNavList.interface";
import { ItemButton, ItemIcon, ItemText } from "./AdminNavList.styles";
import useAdminNavList from "./useAdminNavList";

import { ReactComponent as IconApplications } from "../../../../assets/icons/ic-application.svg";
import { ReactComponent as IconFolders } from "../../../../assets/icons/ic-folders.svg";
import { ReactComponent as IconMembers } from "../../../../assets/icons/ic-members.svg";
import { ReactComponent as IconJobs } from "../../../../assets/icons/ic-job.svg";
import { ReactComponent as IconRoles } from "../../../../assets/icons/ic-role.svg";
import { ReactComponent as IconLogout } from "../../../../assets/icons/ic-logout.svg";
import { ReactComponent as IconChevronTop } from "../../../../assets/icons/ic-chevron-top.svg";
import { ReactComponent as IconChevronBottom } from "../../../../assets/icons/ic-chevron-bottom.svg";

const AdminNavList = () => {
  const { folders, onClickLogout, invalidate, invalidateRecent } =
    useAdminNavList();
  const { urls } = useDashboardRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const { folderId } = useParams();
  const [navListConfig, setNavListConfig] = useState<INavList>({
    folders: false,
  });
  const [listOpen, setListOpen] = useState(false);

  const handleNavList = (key: string) => {
    const keys = Object.keys(navListConfig);
    keys.map((item) => {
      if (item === key) {
        navListConfig[item] = !navListConfig[item];
      }
    });
    setNavListConfig({
      ...navListConfig,
    });
  };

  const handleSelection = (key: string): boolean => {
    return location.pathname.includes(key);
  };

  return (
    <List sx={{ width: "100%" }} component="nav">
      <ItemButton
        onClick={() => navigate(urls.admin.APPLICATIONS.address)}
        selected={handleSelection(urls.admin.APPLICATIONS.address)}
      >
        <ItemIcon>
          <IconApplications />
        </ItemIcon>
        <ItemText primary={urls.admin.APPLICATIONS.title} />
      </ItemButton>

      <ItemButton
        onClick={() => {
          handleNavList("folders");
          setListOpen(!listOpen);
        }}
      >
        <ItemIcon>
          <IconFolders />
        </ItemIcon>
        <ItemText primary={urls.admin.FOLDERS.title} />
        {listOpen ? <IconChevronTop /> : <IconChevronBottom />}
      </ItemButton>
      <Collapse in={listOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {folders.map((folder, index) => (
            <ListItemButton
              key={index}
              sx={{ pl: 4 }}
              onClick={() => {
                let id: number | string = "";
                if (folder.id === 0) {
                  id = folder.name.toLowerCase();
                } else {
                  id = folder.id;
                }

                navigate(
                  urls.admin.FOLDERS.address.replace(":folderId", String(id))
                );
                invalidate();
                invalidateRecent();
              }}
              selected={
                folderId === String(folder.id) ||
                folderId?.toLowerCase() === folder.name.toLowerCase()
              }
            >
              <Badge badgeContent={folder.unReadCount ?? 0} color="error">
                <ListItemText primary={folder.name} />
              </Badge>
            </ListItemButton>
          ))}
        </List>
      </Collapse>

      <ItemButton
        onClick={() => navigate(urls.admin.MEMBERS.address)}
        selected={handleSelection(urls.admin.MEMBERS.address)}
      >
        <ItemIcon>
          <IconMembers />
        </ItemIcon>
        <ItemText primary={urls.admin.MEMBERS.title} />
      </ItemButton>

      <ItemButton
        onClick={() => navigate(urls.admin.JOBS.address)}
        selected={handleSelection(urls.admin.JOBS.address)}
      >
        <ItemIcon>
          <IconJobs />
        </ItemIcon>
        <ItemText primary={urls.admin.JOBS.title} />
      </ItemButton>

      <ItemButton
        onClick={() => navigate(urls.admin.ROLES.address)}
        selected={handleSelection(urls.admin.ROLES.address)}
      >
        <ItemIcon>
          <IconRoles />
        </ItemIcon>
        <ItemText primary={urls.admin.ROLES.title} />
      </ItemButton>

      <ItemButton onClick={onClickLogout}>
        <ItemIcon>
          <IconLogout />
        </ItemIcon>
        <ItemText primary={"Log Out"} />
      </ItemButton>
    </List>
  );
};

export default AdminNavList;
