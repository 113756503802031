import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import {
  UseApplicant,
  UseInterviewer,
  useAlert,
  useDashboardRoutes,
} from "../../../../hooks";

const useApplicantInterviewerForm = () => {
  const { alert } = useAlert();
  const { urls } = useDashboardRoutes();
  const navigate = useNavigate();
  const { id, applicationId } = useParams();

  const [note, setNote] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [resumeUrl, setResumeUrl] = useState<string | undefined>();

  const { data, isError, isFetching, refetch } = UseInterviewer.useGetResume(
    Number(id)
  );
  const { data: resumeData, isSuccess: resumeSuccess } =
    UseApplicant.useGetApplicantResume(Number(applicationId));

  const {
    mutate: noteMutate,
    isSuccess: noteSuccess,
    isLoading: noteLoading,
  } = UseInterviewer.useAddNote();
  const {
    mutate: sendMutate,
    data: sendData,
    isSuccess: sendSuccess,
    isLoading: sendLoading,
  } = UseInterviewer.useSend();

  const onClickCancel = () => {
    navigate(urls.interviewer.APPLICANTS.address);
  };

  const onClickDownload = () => {
    if (resumeUrl) {
      window.open(resumeUrl, "_blank");
    }
  };

  useEffect(() => {
    if (sendSuccess) {
      alert({
        message: "Applicant send success",
        variant: "success",
      });
      navigate(urls.interviewer.APPLICANTS.address);
      setShowDeleteModal(false);
    }
  }, [sendSuccess, sendData]);

  useEffect(() => {
    if (noteSuccess) {
      alert({
        message: "Note add success",
        variant: "success",
      });
      refetch();
    }
  }, [noteSuccess]);

  useEffect(() => {
    if (data?.data) {
      setNote(data.data.current_interviewer_result.note ?? "");
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      navigate(-1);
    }
  }, [isError]);

  useEffect(() => {
    if (resumeSuccess && resumeData?.data) {
      const blob = new Blob([resumeData.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setResumeUrl(url);
    }
  }, [resumeData, resumeSuccess]);

  return {
    alert,
    onClickCancel,
    data,
    isFetching,
    noteMutate,
    noteLoading,
    sendMutate,
    sendLoading,
    note,
    setNote,
    id,
    resumeUrl,
    onClickDownload,
    showDeleteModal,
    setShowDeleteModal,
    showConfirm,
    setShowConfirm,
  };
};

export default useApplicantInterviewerForm;
