import axios, { AxiosResponse } from "axios";

import {
  IApplication,
  IRecentUnread,
  IRequestFilter,
  IResponse,
} from "../../models";
import { IApplicationCreate, IApplicationUpdate } from "./interfaces";

export const getPublished = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IApplication>, any>> => {
  return axios.get("/api/v1/job-applications/published", {
    params: filter,
  });
};

export const getDraft = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IApplication>, any>> => {
  return axios.get("/api/v1/job-applications/draft", {
    params: filter,
  });
};

export const getClosed = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IApplication>, any>> => {
  return axios.get("/api/v1/job-applications/closed", {
    params: filter,
  });
};

export const getApplication = (
  id: number
): Promise<AxiosResponse<IApplication, any>> => {
  return axios.get(`/api/v1/job-applications/${id}`);
};

export const getUnreadCount = (): Promise<
  AxiosResponse<IRecentUnread, any>
> => {
  return axios.get(`/api/v1/applicants/star-recent-count`);
};

export const create = (
  data: IApplicationCreate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.post("/api/v1/job-applications", {
    job_position_id: data.job_position_id,
    title: data.title,
    summary: data.summary,
    description: data.description,
    status: data.status,
    // url: data.url,
  });
};

export const update = (
  data: IApplicationUpdate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/job-applications/${data.id}`, {
    job_position_id: data.job_position_id,
    title: data.title,
    summary: data.summary,
    description: data.description,
    status: data.status,
    // url: data.url,
  });
};

export const deleteApplications = (
  ids: number[]
): Promise<AxiosResponse<unknown, any>> => {
  return axios.delete("/api/v1/job-applications", {
    data: {
      ids,
    },
  });
};
