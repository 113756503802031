import { Divider } from "@mui/material";
import moment from "jalali-moment";

import {
  BigHeaderTitle,
  HeaderTitle,
  HeaderWrapper,
  MainFormWrapper,
  SubTitle,
  SubTitleBlue,
} from "./ApplicantInterviewerForm.styles";
import { IInterviewSectionProps } from "./ApplicantInterviewerForm.interface";

const InterviewSection = ({ data }: IInterviewSectionProps) => {
  return (
    <>
      <MainFormWrapper>
        <BigHeaderTitle>{data?.type}</BigHeaderTitle>
        <HeaderWrapper>
          <HeaderTitle>
            {data?.plan_date
              ? moment(data?.plan_date).format("DD / MMM / YYYY")
              : ""}
          </HeaderTitle>
          <HeaderTitle>
            {data?.plan_date ? moment.utc(data?.plan_date).format("h:mm a") : ""}
          </HeaderTitle>
        </HeaderWrapper>
      </MainFormWrapper>
      <Divider sx={{ width: "100%" }} />
      <MainFormWrapper>
        <HeaderTitle>
          {data?.type === "ONLINE" ? "Interview Link" : "Interview Site"}
        </HeaderTitle>
        {data?.type === "ONLINE" ? (
          <a href={data?.place} target="_blank" rel="noreferrer">
            <SubTitleBlue>{data?.place}</SubTitleBlue>
          </a>
        ) : (
          <SubTitle>{data?.place}</SubTitle>
        )}
      </MainFormWrapper>
    </>
  );
};

export default InterviewSection;
