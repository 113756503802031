import { Suspense } from "react";
import { useSelector } from "react-redux";

import PageRouter from "./pageRouter";
import { IRootState } from "../../redux/store";
import InterviewerRouter from "./interviewerRouter";

const App = () => {
  const { userJWT } = useSelector((state: IRootState) => state.auth);  
  
  return (
    <Suspense fallback={<div />}>
      {userJWT?.auth === "ROLE_ADMIN" ||
      userJWT?.auth === "ROLE_SUPER_ADMIN" ? (
        <PageRouter />
      ) : (
        <InterviewerRouter />
      )}
    </Suspense>
  );
};

export default App;
