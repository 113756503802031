import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;

  &.tree-item {
    flex: 3;
  }

  &.two-item {
    flex: 2;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`;
