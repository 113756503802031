import { IHeaderContainerProps } from "./HeaderContainer.interface";
import { Container } from "./HeaderContainer.styles";

const HeaderContainer = ({ children }: IHeaderContainerProps) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

export default HeaderContainer;
