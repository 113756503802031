import {
  DeleteConfirmModal,
  RoleModal,
  NormalPage,
  Pagination,
  Table,
} from "../../atoms";
import useRoles from "./useRoles";
import useRolesTableConfig from "./config/config";
import RolesFilter from "./filter/RolesFilter";

const Roles = () => {
  const {
    totalCount,
    stateData,
    isFetching,
    filter,
    setFilter,
    handleSelect,
    handleSelectAll,
    allSelected,
    isSomeItemSelected,
    setFilterSearch,
    onClickItem,
    selectedItem,
    showModal,
    setShowModal,
    showDeleteModal,
    setShowDeleteModal,
    deleteLoading,
    deleteRoles,
    onClickDeleteItem,
    onClickDeleteItems,
    onClickAdd,
  } = useRoles();
  const { getTableDefinition } = useRolesTableConfig(
    allSelected,
    isSomeItemSelected,
    handleSelectAll,
    handleSelect,
    onClickItem,
    onClickDeleteItem
  );

  return (
    <>
      <NormalPage>
        <RolesFilter
          onClickAdd={onClickAdd}
          handleSearch={(e) => setFilterSearch(e.target.value)}
          onClickDelete={onClickDeleteItems}
          showDeleteAll={isSomeItemSelected}
        />
        <div className="list-content">
          <div className="form-style">
            <Table
              definitions={getTableDefinition()}
              items={stateData}
              isLoading={isFetching}
            />
            <Pagination
              onPageChanged={(page) =>
                setFilter({
                  ...filter,
                  page: page - 1,
                })
              }
              totalCount={totalCount}
              currentPage={filter.page + 1}
            />
          </div>
        </div>
      </NormalPage>
      <RoleModal
        mode={selectedItem ? "EDIT" : "CREATE"}
        onCancel={() => setShowModal(false)}
        showModal={showModal}
        id={selectedItem?.id}
        title={selectedItem?.name}
      />
      <DeleteConfirmModal
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={deleteRoles}
        title={selectedItem ? "Delete Role" : "Delete Roles"}
        message={
          selectedItem
            ? "Are you sure you want to delete this role?"
            : "Are you sure you want to delete these Roles?"
        }
        loading={deleteLoading}
      />
    </>
  );
};

export default Roles;
