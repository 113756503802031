import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  UseFolder,
  UseJobPosition,
  useDashboardRoutes,
} from "../../../../../hooks";

const useApplicantsFilter = () => {
  const { folderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { urls } = useDashboardRoutes();

  const [clearEnable, setClearEnable] = useState(false);
  const [sortDate, setSortDate] = useState<string | undefined>();
  const [filterPosition, setFilterPosition] = useState<string | undefined>();
  const [filterStatus, setFilterStatus] = useState<string | undefined>();
  const [filterSearch, setFilterSearch] = useState<string>("");
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showFolderDeleteModal, setShowDeleteFolderModal] = useState(false);
  const [folderEditMode, setFolderEditMode] = useState(false);

  const { data: folderData } = UseFolder.useGetFolder(Number(folderId)!);
  const {
    mutate: deleteFolderMutate,
    isSuccess: deleteFolderSuccess,
    isError: deleteFolderError,
    isLoading: deleteFolderLoading,
  } = UseFolder.useDeleteSingle();
  const { data: jobPositionData } = UseJobPosition.useGetJobPositions({
    page: 0,
    size: 1000,
    filter: "",
    sort: "id,desc",
  });
  const { invalidate: invalidateFolders } = UseFolder.useInvalidateFolders();

  const removeFolder = () => {
    if (!folderId) {
      return;
    }

    deleteFolderMutate(Number(folderId));
  };

  useEffect(() => {
    if (deleteFolderSuccess) {
      setShowDeleteFolderModal(false);
      invalidateFolders();
      navigate(urls.admin.APPLICANTS.address, { replace: true });
    }
  }, [deleteFolderSuccess, deleteFolderError]);

  useEffect(() => {
    if (sortDate || filterPosition || filterStatus || filterSearch) {
      setClearEnable(true);
    } else {
      setClearEnable(false);
    }
  }, [sortDate, filterPosition, filterStatus, filterSearch]);

  return {
    jobPositionData,
    sortDate,
    setSortDate,
    filterPosition,
    setFilterPosition,
    filterStatus,
    setFilterStatus,
    filterSearch,
    setFilterSearch,
    folderData,
    showFolderModal,
    setShowFolderModal,
    folderEditMode,
    setFolderEditMode,
    deleteFolderLoading,
    setShowDeleteFolderModal,
    showFolderDeleteModal,
    removeFolder,
    folderId,
    clearEnable,
    location,
  };
};

export default useApplicantsFilter;
