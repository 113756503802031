import { useEffect, useState } from "react";
import { UseFolder } from "../../../../hooks";

const useFolderModal = (
  onSubmit: () => void,
  open: boolean,
  folderId?: number,
  folderName?: string
) => {
  const [stateFolderName, setStateFolderName] = useState("");
  const [hasError, setHasError] = useState(false);

  const {
    mutate: createMutate,
    isSuccess: createSuccess,
    isLoading: createLoading,
  } = UseFolder.useCreate();
  const {
    mutate: updateMutate,
    isSuccess: updateSuccess,
    isLoading: updateLoading,
  } = UseFolder.useUpdate();
  const { invalidate: invalidateFolder } = UseFolder.useInvalidateFolder();
  const { invalidate: invalidateFolders } = UseFolder.useInvalidateFolders();

  const onSave = () => {
    setHasError(false);
    if (stateFolderName) {
      if (folderId) {
        updateMutate({
          id: folderId,
          name: stateFolderName,
        });
      } else {
        createMutate({
          name: stateFolderName,
        });
      }
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      invalidateFolder();
      invalidateFolders();
      onSubmit();
    }
  }, [createSuccess, updateSuccess]);

  useEffect(() => {
    setHasError(false);
    folderName ? setStateFolderName(folderName) : setStateFolderName("");
  }, [folderName]);

  useEffect(() => {
    if (!stateFolderName) {
      setStateFolderName("");
    }
    folderName ? setStateFolderName(folderName) : setStateFolderName("");
    setHasError(false);
  }, [open]);

  return {
    stateFolderName,
    onSave,
    setStateFolderName,
    createLoading,
    updateLoading,
    hasError,
  };
};

export default useFolderModal;
