import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import StorageUtil from "../../../../util/StorageUtil";
import { handleLogout } from "../../../../redux/auth";

const useInterviewerLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickLogout = () => {
    StorageUtil.storageRemoveAll();
    dispatch(handleLogout());
    navigate("/sign-in", {
      replace: true,
    });
  };

  return { onClickLogout };
};

export default useInterviewerLayout;
