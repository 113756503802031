import styled from "@emotion/styled";
import { styled as MuiStyled } from "@mui/material/styles";

export const drawerWidth = 236;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
`;

export const Main = MuiStyled("main", {
  shouldForwardProp: (prop) => prop !== "open"
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,  
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));
