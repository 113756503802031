import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

import { UseAccount, useDashboardRoutes } from "../../hooks";
import StorageUtil from "../../util/StorageUtil";
import { handleUser, handleUserJWT } from "../../redux/auth";
import { IUserJWT } from "../../models";
import { IRootState } from "../../redux/store";

const useApp = () => {
  const { urls } = useDashboardRoutes();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userJWT } = useSelector((state: IRootState) => state.auth);
  const location = useLocation();

  const { data, isError, isSuccess, isFetching, isStale } =
    UseAccount.useGetAccount();

  useEffect(() => {
    if (isError) {
      if (location.pathname !== urls.public.LOGIN.address) {
        StorageUtil.storageRemoveAll();
        navigate(urls.public.LOGIN.address);
        return;
      }
    }

    if (isSuccess && !isStale) {
      dispatch(handleUser(data?.data));
      if (
        location.pathname === urls.public.LOGIN.address ||
        location.pathname === "/"
      ) {        
        if (
          userJWT?.auth === "ROLE_ADMIN" ||
          userJWT?.auth === "ROLE_SUPER_ADMIN"
        ) {
          navigate(urls.admin.APPLICATIONS.address, { replace: true });
        } else {
          navigate(urls.interviewer.APPLICANTS.address, { replace: true });
        }
      }
      return;
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    const token = StorageUtil.storageGet(StorageUtil.storageKeys.token);
    if (token) {
      const decoded = jwt_decode(token);
      dispatch(handleUserJWT(decoded as IUserJWT));
    }
  }, []);

  return { urls, isFetching };
};

export default useApp;
