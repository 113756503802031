import styled from "@emotion/styled";

export const OperationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;

  svg {
    cursor: pointer;
    user-select: none;
  }
`;
