import styled from "@emotion/styled";
import { Pagination } from "@mui/material";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 16px 0 0;
`;

export const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    background-color: ${({ theme }) => theme.palette.background.default};
    border: none;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.grey[800]};

    &.Mui-selected {
      border: 1px solid ${({ theme }) => theme.palette.primary.dark};
      color: ${({ theme }) => theme.palette.primary.dark};
    }

    &.Mui-disabled {
      background: ${({ theme }) => theme.palette.grey[400]};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
