import { Button, Input, NormalPage, Select } from "../../atoms";
import UserFormFilter from "./filter/UserFormFilter";
import useUserForm from "./useUserForm";
import { Wrapper, ButtonWrapper, LineWrapper, Title } from "./UserForm.styles";
import { IUserFormProps } from "./UserForm.interface";
import { emailValidation } from "../../../../util/Validation";

const UserForm = ({ existModel }: IUserFormProps) => {
  const {
    model,
    roles,
    setModel,
    createLoading,
    onClickSubmit,
    updateLoading,
    onClickCancel,
    formSubmit,
  } = useUserForm(existModel);

  return (
    <>
      <NormalPage>
        <UserFormFilter />
        <div className="main-content">
          <div className="form-style">
            <Wrapper>
              <Title>{existModel ? "Edit" : "New"} Member</Title>
              <LineWrapper className="two-item">
                <Input
                  error={formSubmit && !model.first_name}
                  placeholder="First Name"
                  title="First Name"
                  value={model!.first_name}
                  sx={{ flex: 1 }}
                  onChange={(e) =>
                    setModel({
                      ...model!,
                      first_name: e.target.value,
                    })
                  }
                />

                <Input
                  error={formSubmit && !model.last_name}
                  placeholder="Last Name"
                  title="Last Name"
                  value={model!.last_name}
                  sx={{ flex: 1 }}
                  onChange={(e) =>
                    setModel({
                      ...model!,
                      last_name: e.target.value,
                    })
                  }
                />
              </LineWrapper>

              <LineWrapper className="two-item">
                <Input
                  error={
                    formSubmit &&
                    (!model.email || !emailValidation(model?.email))
                  }
                  errorMessage={
                    model.email && !emailValidation(model?.email)
                      ? "Email is not valid format"
                      : "Email required"
                  }
                  placeholder="Email/Username"
                  title="Email/Username"
                  value={model!.email}
                  sx={{ flex: 1 }}
                  onChange={(e) =>
                    setModel({
                      ...model!,
                      email: e.target.value,
                      username: e.target.value,
                    })
                  }
                />
                <Select
                  items={roles}
                  selectProps={{
                    title: "Role",
                    error: formSubmit && !model.role_id,
                    value: model?.role_id,
                    sx: { flex: 1 },
                    onChange: (e) => {
                      setModel({
                        ...model!,
                        role_id: Number(e.target.value),
                      });
                    },
                  }}
                />
              </LineWrapper>

              {!existModel && (
                <LineWrapper>
                  <Input
                    error={formSubmit && !model.password}
                    placeholder="Password"
                    title="Password"
                    type="password"
                    value={model!.password}
                    sx={{ flex: 1 }}
                    onChange={(e) =>
                      setModel({
                        ...model!,
                        password: e.target.value,
                      })
                    }
                  />
                </LineWrapper>
              )}

              <ButtonWrapper>
                <Button
                  title="Cancel"
                  onClick={onClickCancel}
                  variant="outlined"
                  sx={{ maxWidth: "210px" }}
                />
                <Button
                  title="Save"
                  onClick={onClickSubmit}
                  variant="contained"
                  sx={{ maxWidth: "210px" }}
                  loading={createLoading || updateLoading}
                />
              </ButtonWrapper>
            </Wrapper>
          </div>
        </div>
      </NormalPage>
    </>
  );
};

export default UserForm;
