import axios, { AxiosResponse } from "axios";

import { IAuthority, IRequestFilter, IResponse } from "../../models";
import { IAuthorityCreate, IAuthorityUpdate } from "./interfaces";

export const getAuthorities = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IAuthority>, any>> => {
  return axios.get("/api/v1/authorities", {
    params: filter
  });
};

export const getAuthority = (
  id: number
): Promise<AxiosResponse<IAuthority, any>> => {
  return axios.get(`/api/v1/authorities/${id}`);
};

export const create = (
  data: IAuthorityCreate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.post("/api/v1/authorities", data);
};

export const update = (
  data: IAuthorityUpdate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/authorities/${data.id}`, data);
};

export const deleteJobPosition = (
  ids: number[]
): Promise<AxiosResponse<unknown, any>> => {
  return axios.delete("/api/v1/authorities", {
    data: {
      ids
    }
  });
};
