import { debounce } from "lodash";

import { Input, HeaderContainer, Button } from "../../../atoms";
import {
  FilterWrapper,
  MainWrapper,
  Title,
  Wrapper
} from "./RolesFilter.styles";
import { IRolesFilterProps } from "./RolesFilter.interface";

import { ReactComponent as IconAdd } from "../../../../../assets/icons/ic-add-white.svg";
import { ReactComponent as IconTrash } from "../../../../../assets/icons/ic-trash.svg";
import { ReactComponent as IconSearch } from "../../../../../assets/icons/ic-search.svg";

const RolesFilter = ({
  handleSearch,
  onClickDelete,
  showDeleteAll,
  onClickAdd
}: IRolesFilterProps) => {
  return (
    <HeaderContainer>
      <MainWrapper>
        <Wrapper>
          <FilterWrapper>
            <Input
              endAdornment={<IconSearch />}
              placeholder="Search"
              onChange={debounce(handleSearch, 300)}
            />
          </FilterWrapper>
          <Button
            title="Add Role"
            variant="contained"
            startIcon={<IconAdd />}
            sx={{ maxWidth: "fit-content" }}
            onClick={onClickAdd}
          />
        </Wrapper>
        {showDeleteAll &&
          <FilterWrapper>
            <Title>Delete all selected</Title>
            <IconTrash onClick={() => onClickDelete()} />
          </FilterWrapper>}
      </MainWrapper>
    </HeaderContainer>
  );
};

export default RolesFilter;
