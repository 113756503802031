import { Loading } from "../../atoms";
import ApplicationForm from "../../organs/applicationForm/ApplicationForm";
import useApplicationUpdate from "./useApplicationUpdate";

const ApplicationUpdate = () => {
  const { data, isFetching } = useApplicationUpdate();

  if (isFetching) {
    return <Loading />;
  }

  return <ApplicationForm existModel={data?.data} />;
};

export default ApplicationUpdate;
