import { Box, Modal, Paper } from "@mui/material";

import { IFolderModalProps } from "./FolderModal.interface";
import { Title } from "./FolderModal.styles";
import Button from "../button/Button";
import Input from "../input/Input";
import useFolderModal from "./useFolderModal";

const FolderModal = ({
  onCreateUpdate,
  showModal,
  onCancel,
  mode,
  folderId,
  folderName,
}: IFolderModalProps) => {
  const {
    onSave,
    stateFolderName,
    setStateFolderName,
    createLoading,
    updateLoading,
    hasError,
  } = useFolderModal(onCreateUpdate, showModal, folderId, folderName);

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          padding: "16px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: "70%",
            padding: "16px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="16px"
          >
            <Title>{mode === "EDIT" ? "Edit Folder Name" : "New Folder"}</Title>
            <Input
              title="Name"
              error={hasError}
              value={stateFolderName}
              onChange={(e) => setStateFolderName(e.target.value)}
            />
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyItems="center"
              gap={2}
              justifyContent="flex-end"
            >
              <Button variant="outlined" onClick={onCancel} title={"Cancel"} />
              <Button
                variant="contained"
                loading={createLoading || updateLoading}
                onClick={onSave}
                title="Save"
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default FolderModal;
