import { IJobPosition } from "./IJobPosition";
import { IListItem } from "./IListItem";

export interface IApplication {
  id: number;
  organization: string | number;
  title: string;
  summery: string;
  description: string;
  url: string;
  start_date: string;
  exp_date: string;
  status: string;
  default_folder: string;
  job_position?: IJobPosition;
}

export interface IRecentUnread {
  unread_stars_count: number;
  unread_recent_counts: number;
}

export enum ApplicationStatus {
  DRAFTS = "DRAFT",
  PUBLISHED = "PUBLISHED",
  CLOSED = "CLOSED",
}

export const getApplicationStatusAsSelectList = (): IListItem[] => {
  return [
    {
      key: ApplicationStatus.DRAFTS,
      value: "Drafts",
    },
    {
      key: ApplicationStatus.PUBLISHED,
      value: "Published",
    },
    {
      key: ApplicationStatus.CLOSED,
      value: "Closed",
    },
  ];
};

export const getApplicationStatusAsSelectListForCreate = (): IListItem[] => {
  return [
    {
      key: ApplicationStatus.DRAFTS,
      value: "Drafts",
    },
    {
      key: ApplicationStatus.PUBLISHED,
      value: "Published",
    },
  ];
};
