import { Routes as Router, Route } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

import { InterviewerLayout } from "../atomic/layouts";
import useApp from "./useApp";
import {
  ApplicantDetailInterviewer,
  ApplicantListInterviewer,
  AuthLogin,
  ResetPassword
} from "../pages";

export const InterviewerRouter = () => {
  const { urls, isFetching } = useApp();

  if (isFetching) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Router>
      <Route path={urls.public.LOGIN.address}>
        <Route element={<AuthLogin />} index />
        <Route
          element={<ResetPassword />}
          path={urls.public.RESET_PASSWORD.address}
        />
      </Route>

      <Route element={<InterviewerLayout />}>
        <Route path={urls.interviewer.APPLICANTS.address}>
          <Route element={<ApplicantListInterviewer />} index />
          <Route
            element={<ApplicantDetailInterviewer />}
            path={urls.interviewerSubRoots.APPLICANT_DETAIL.address}
          />
        </Route>
      </Route>
    </Router>
  );
};

export default InterviewerRouter;
