import axios, { AxiosResponse } from "axios";

import {
  IResponse,
  IRequestFilter,
  IInterviewer,
  IInterviewerResume
} from "../../models";
import { IInterviewerAddNote } from "./interfaces";

export const getInterviewers = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IInterviewer>, any>> => {
  return axios.get("/api/v1/authorities/interviewers", {
    params: filter
  });
};

export const getResumes = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IInterviewerResume>, any>> => {
  return axios.get("/api/v1/interviews", {
    params: filter
  });
};

export const getResume = (
  id: number
): Promise<AxiosResponse<IInterviewerResume, any>> => {
  return axios.get(`/api/v1/interviews/${id}`);
};

export const addNote = (
  data: IInterviewerAddNote
): Promise<AxiosResponse<any, any>> => {
  return axios.put(`/api/v1/interview-results/${data.id}/note`, {
    note: data.note
  });
};

export const send = (id: number): Promise<AxiosResponse<any, any>> => {
  return axios.put(`/api/v1/interview-results/${id}/send`);
};
