import { Dayjs } from "dayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

import { Wrapper, Title, ErrorWrapper } from "./DateTimePicker.styles";
import { IDateTimePickerProps } from "./DatePicker.interface";

import { ReactComponent as IconError } from "../../../../assets/icons/ic-error.svg";

const DateTimePicker = ({
  title,
  datePickerProps,
  error,
  errorMessage,
}: IDateTimePickerProps<Dayjs>) => {
  return (
    <Wrapper
      style={
        datePickerProps.sx ? (datePickerProps.sx as React.CSSProperties) : {}
      }
    >
      <Title error={error}>{title}</Title>
      <MobileDateTimePicker
        {...datePickerProps}
        className={`${error ? "has-error" : ""}`}
      />
      {error && (
        <ErrorWrapper>
          <IconError />
          <span>{errorMessage ?? `${title} required`}</span>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default DateTimePicker;
