import {
  styled as MUIStyled,
  ListItemIcon,
  ListItemButton,
  ListItemText
} from "@mui/material";

export const ItemIcon = MUIStyled(ListItemIcon)(({ theme }) => ({
  minWidth: "24px"
}));

export const ItemButton = MUIStyled(ListItemButton)(({ theme }) => ({
  gap: "12px"
}));

export const ItemText = MUIStyled(ListItemText)(({ theme }) => ({
  span: {
    fontSize: "16px",
    fontWeight: "500"
  }
}));
