import { Box, Modal, Paper } from "@mui/material";

import { IJobPositionModalProps } from "./JobPositionModal.interface";
import { Title } from "./JobPositionModal.styles";
import Button from "../button/Button";
import Input from "../input/Input";
import useJobPositionModal from "./useJobPositionModal";

const JobPositionModal = ({
  showModal,
  onCancel,
  mode,
  id,
  title,
}: IJobPositionModalProps) => {
  const {
    onSave,
    stateTitle,
    setStateTitle,
    createLoading,
    updateLoading,
    hasError,
  } = useJobPositionModal(onCancel, showModal, id, title);

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          padding: "16px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: "70%",
            padding: "16px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="16px"
          >
            <Title>
              {mode === "EDIT" ? "Edit Job Position" : "Add job Position"}
            </Title>
            <Input
              title="Name"
              error={hasError}
              value={stateTitle}
              onChange={(e) => setStateTitle(e.target.value)}
            />
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyItems="center"
              gap={2}
              justifyContent="flex-end"
            >
              <Button variant="outlined" onClick={onCancel} title={"Cancel"} />
              <Button
                variant="contained"
                loading={createLoading || updateLoading}
                onClick={onSave}
                title="Save"
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default JobPositionModal;
