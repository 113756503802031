import styled from "@emotion/styled";

export const OperationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;

  svg {
    cursor: pointer;
    user-select: none;
  }
`;

export const ActivateWrapper = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
`;
