import { debounce } from "lodash";

import { Input, HeaderContainer, Button } from "../../../atoms";
import {
  FilterWrapper,
  MainWrapper,
  Title,
  Wrapper
} from "./UsersFilter.styles";
import { IUsersFilterProps } from "./UsersFilter.interface";

import { ReactComponent as IconAdd } from "../../../../../assets/icons/ic-add-member-white.svg";
import { ReactComponent as IconTrash } from "../../../../../assets/icons/ic-trash.svg";
import { ReactComponent as IconSearch } from "../../../../../assets/icons/ic-search.svg";

const UsersFilter = ({
  handleSearch,
  onClickDelete,
  showDeleteAll,
  onClickAdd
}: IUsersFilterProps) => {
  return (
    <HeaderContainer>
      <MainWrapper>
        <Wrapper>
          <FilterWrapper>
            <Input
              endAdornment={<IconSearch />}
              placeholder="Search"
              onChange={debounce(handleSearch, 300)}
            />
          </FilterWrapper>
          <Button
            title="Add Member"
            variant="contained"
            startIcon={<IconAdd />}
            sx={{ maxWidth: "fit-content" }}
            onClick={onClickAdd}
          />
        </Wrapper>
        {showDeleteAll &&
          <FilterWrapper>
            <Title>Delete all selected</Title>
            <IconTrash onClick={() => onClickDelete()} />
          </FilterWrapper>}
      </MainWrapper>
    </HeaderContainer>
  );
};

export default UsersFilter;
