import { useNavigate } from "react-router-dom";

import HeaderContainer from "../../../atoms/headerContainer/HeaderContainer";
import { Wrapper, IconWrapper, Title } from "./ApplicantFormFilter.styles";
import { useDashboardRoutes } from "../../../../../hooks";

import { ReactComponent as IconBack } from "../../../../../assets/icons/ic-arrow-left.svg";

const ApplicantFormFilter = () => {
  const navigate = useNavigate();
  const { urls } = useDashboardRoutes();

  return (
    <HeaderContainer>
      <Wrapper>
        <IconWrapper
          onClick={() => {
            navigate(urls.interviewer.APPLICANTS.address);
          }}
        >
          <IconBack />
          <Title>Back</Title>
        </IconWrapper>
      </Wrapper>
    </HeaderContainer>
  );
};

export default ApplicantFormFilter;
