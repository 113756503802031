import { CircularProgress } from '@mui/material';

import {
  Container,
  LoadingWrapper,
  StyledTBody,
  StyledTHead,
  Table as StyledTable,
} from './Table.styles';
import { ITableDefinition, TableProps } from './Table.interface';
import NoContent from '../noContent/NoContent';

const Table = <
  D extends Record<string, unknown>,
  T extends readonly ITableDefinition<D>[],
>({
  definitions,
  items = [],
  isLoading,
}: TableProps<D, T>) => {
  const columnsKeys = definitions.map(item => item.columnKey);

  const renderDesktopMode = () => {
    if(items.length === 0){
      return <NoContent />
    }

    return (
      <StyledTable stickyHeader>
        <StyledTHead>
          <tr>
            {definitions.map((item, index) => (
              <th key={index} style={typeof item.headerStyle === 'function' ? item.headerStyle() : typeof item.headerStyle === 'object' ? item.headerStyle : {}}>
                {item.headerRenderer?.() ?? item.label}
              </th>
            ))}
          </tr>
        </StyledTHead>

        <StyledTBody>
          {items.map((row, index) => (
            <tr key={index}>
              {columnsKeys.map((column, columnIndex) => {
                const definition = definitions.find(
                  item => item.columnKey === column,
                );
                return (
                  <td key={columnIndex} 
                  onClick={() => definition?.onColumnClick?.(row)}
                  style={typeof definition?.columnStyle === 'function' ? definition?.columnStyle(row) : typeof definition?.columnStyle === 'object' ? definition?.columnStyle : {}}>
                    {definition?.renderer
                      ? definition.renderer(row)
                      : String(row[column])}
                  </td>
                );
              })}
            </tr>
          ))}
        </StyledTBody>
      </StyledTable>
    );
  };

  return isLoading ? (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  ) : (
    <Container>{renderDesktopMode()}</Container>
  );
};

export default Table;
