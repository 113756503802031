import {
  HeaderContainer,  
} from "../../../atoms";
import {
  FilterWrapper, 
  IconWrapper,
  MainWrapper,
  Title,
  Wrapper, 
} from "./ApplicantsFilter.styles";
import { SortMenu } from "../../../molecules";
import { IApplicantsFilterProps } from "./ApplicantsFilter.interface";
import useApplicantsFilter from "./useApplicantsFilter";

import { ReactComponent as IconClose } from "../../../../../assets/icons/ic-close.svg";

const ApplicationsFilter = ({
  handleSortDate,
handleFilterPosition,
handleClearFilter,
}: IApplicantsFilterProps) => {
  const {    
    sortDate,
    setSortDate,
    filterPosition,
    setFilterPosition,
    jobPositionData,
    clearEnable
  } = useApplicantsFilter();

  return (
    <HeaderContainer>
    <MainWrapper>    
      <Wrapper>
        <FilterWrapper>             
          <SortMenu
            onChange={(val) => {
              if(typeof val === 'string'){
                handleSortDate(val);
                setSortDate(val);
              }              
            }}
            sortList={["Newest", "Oldest"]}
            placeHolder="Date"
            value={sortDate}
          />
          <SortMenu
            onChange={(val) => {
              if(typeof val === 'string'){
              handleFilterPosition(val);
              setFilterPosition(val);
              }
            }}
            sortList={
              jobPositionData?.data?.content?.map((item) => item.title) ??
              []
            }
            placeHolder="Position"
            value={filterPosition}
          />             
        </FilterWrapper>
        {clearEnable && <IconWrapper
          onClick={() => {
            setFilterPosition(undefined);              
            setSortDate(undefined);
            handleClearFilter();
          }}
        >
          <IconClose />
          <Title>Clear All Filters</Title>
        </IconWrapper>}
      </Wrapper>
      
    </MainWrapper>
  </HeaderContainer>
  );
};

export default ApplicationsFilter;
