import { useEffect, useState } from "react";
import { UseAuth, useDashboardRoutes } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

const useResetPass = () => {
  const navigate = useNavigate();
  const { urls } = useDashboardRoutes();
  // const { alert } = useAlert();

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    mutate: initMutate,
    isLoading: initLoading,
    isSuccess: initSuccess,
    data: initData
  } = UseAuth.useResetPassInit();
  const {
    mutate: validateMutate,
    isLoading: validateLoading,
    isSuccess: validateSuccess,
    data: validateData
  } = UseAuth.useResetPassValidate();
  const {
    mutate: finishMutate,
    isLoading: finishLoading,
    isSuccess: finishSuccess,
    data: finishData
  } = UseAuth.useResetPassFinish();

  const onClickBack = () => {
    if (step === 1) {
      navigate(-1);
    } else {
      setStep(step - 1);
    }
  };

  const onClickGoLogin = () => {
    navigate(urls.public.LOGIN.address, {
      replace: true
    });
  };

  const onClickChange = () => {
    setHasError(false);
    if (password !== newPassword) {
      setHasError(true);
      setErrorMessage("Repeat password is not same as Password");
      return;
    }

    if (password.length < 4) {
      setHasError(true);
      setErrorMessage("Your Password must be at least 4 characters.");
      return;
    }

    finishMutate({
      email,
      new_password: newPassword
    });
  };

  useEffect(() => {
    if (initSuccess) {
      setStep(2);
    }

    if (validateSuccess) {
      setStep(3);
    }

    if (finishSuccess) {
      setStep(4);
    }
  }, [
    finishData,
    initData,
    validateData,
    initSuccess,
    finishSuccess,
    validateSuccess
  ]);

  return {
    email,
    setEmail,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    step,
    setStep,
    code,
    setCode,
    initMutate,
    initLoading,
    finishMutate,
    onClickBack,
    finishLoading,
    validateLoading,
    validateMutate,
    onClickChange,
    onClickGoLogin,
    hasError,
    errorMessage
  };
};

export default useResetPass;
