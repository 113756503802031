import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
`;

export const JobTitle = styled.span`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
`;

export const JobPosition = styled.span`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const PublishedDate = styled.span`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  opacity: calc() 0.5;
`;

export const Date = styled.span`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const IconItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

export const EditText = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;
