import { Box, Modal, Paper } from "@mui/material";

import { IApplicantRejectionModalProps } from "./ApplicantRejectionModal.interface";
import { Title } from "./ApplicantRejectionModal.styles";
import useApplicantRejectionModal from "./useApplicantRejectionModal";
import Select from "../select/Select";
import TinyEditor from "../tinyEditor/TinyEditor";
import Button from "../button/Button";

const ApplicantRejectionModal = ({
  showModal,
  onCancel,
  onSubmit,
  loading,
}: IApplicantRejectionModalProps) => {
  const {
    content,
    reasons,
    selectedReason,
    setContent,
    setSelectedReason,
    validation,
    formSubmit,
  } = useApplicantRejectionModal();

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          padding: "16px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: "70%",
            padding: "16px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="16px"
          >
            <Title>Rejection application</Title>
            <Select
              items={reasons}
              selectProps={{
                error: formSubmit && !selectedReason,
                title: "Rejection Reason",
                onChange: (e) => {
                  setSelectedReason(String(e.target.value));
                },
              }}
            />
            <TinyEditor onChange={(val) => setContent(val)} initVal={content} />
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyItems="flex-end"
              gap={2}
              justifyContent="flex-end"
              alignSelf="flex-end"
            >
              <Button
                variant="outlined"
                onClick={onCancel}
                title={"Cancel"}
                sx={{ minWidth: "fit-content" }}
              />
              <Button
                variant="contained"
                loading={loading}
                sx={{ minWidth: "fit-content" }}
                onClick={() => {
                  if (validation() && content) {
                    onSubmit(selectedReason, content);
                  }
                }}
                title="Send E-mail"
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ApplicantRejectionModal;
