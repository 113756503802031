import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Authority } from "../network";
import Queries from "../util/Queries";
import { IRequestFilter } from "../models";

export const useGetAuthorities = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_AUTHORITIES, filter],
      () => Authority.getAuthorities(filter),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useInvalidateAuthorities = () => {
  const query = useQueryClient();
  const invalidate = () => {
    query.invalidateQueries([Queries.GET_AUTHORITIES]);
  };
  return { invalidate };
};

export const useGetAuthority = (id: number) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery([Queries.GET_AUTHORITY, id], () => Authority.getAuthority(id), {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false
    });

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useCreate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Authority.create
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useUpdate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Authority.update
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useDelete = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Authority.deleteJobPosition
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};
