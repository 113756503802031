import { useEffect, useState } from "react";

import { PAGE_SIZE } from "../../../../util/constants";
import { UseJobPosition } from "../../../../hooks";
import { IJobPosition, IRequestFilter } from "../../../../models";

const useJobPositions = () => {
  const [filterSearch, setFilterSearch] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSomeItemSelected, setIsSomeItemSelected] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [stateData, setStateData] = useState<IJobPosition[]>([]);
  const [selectedItem, setSelectedItem] = useState<IJobPosition | undefined>();

  const [filter, setFilter] = useState<IRequestFilter>({
    page: 0,
    size: PAGE_SIZE,
    sort: "id,desc",
    filter: "",
  });

  const {
    data,
    isLoading: isFetching,
    refetch,
  } = UseJobPosition.useGetJobPositions(filter);
  const {
    isSuccess: deleteSuccess,
    isLoading: deleteLoading,
    mutate: deleteMutate,
  } = UseJobPosition.useDelete();

  const handleFilter = () => {
    const searchFilterArray = [];
    if (filterSearch) {
      searchFilterArray.push(`title=ilike='${filterSearch}'`);
      searchFilterArray.push(`organization=ilike='${filterSearch}'`);
    }

    if (searchFilterArray.length > 0) {
      setFilter({
        ...filter,
        page: 0,
        filter: searchFilterArray.join(","),
      });
    } else {
      setFilter({
        ...filter,
        page: 0,
        sort: "id,desc",
        filter: "",
      });
    }
  };

  const handleSelectAll = (all: boolean) => {
    setAllSelected(all);
    const newData = stateData.map((item) => ({
      ...item,
      selected: all,
    }));
    setStateData(newData);
  };

  const handleSelect = (selected: boolean, id: number) => {
    const newData = stateData.map((item) => ({
      ...item,
      selected: item.id === id ? selected : item.selected,
    }));
    setStateData(newData);
    const selectedItemCount = newData.filter((item) => item.selected).length;
    if (selectedItemCount === 0) {
      setAllSelected(false);
      setIsSomeItemSelected(false);
    } else {
      if (selectedItemCount === newData.length) {
        setIsSomeItemSelected(false);
        setAllSelected(true);
      } else {
        setIsSomeItemSelected(true);
        setAllSelected(false);
      }
    }
  };

  const onClickItem = (id: number) => {
    const item = stateData.find((item) => item.id === id);
    if (item) {
      setSelectedItem(item);
      setShowModal(true);
    }
  };

  const onClickDeleteItem = (id: number) => {
    const item = stateData.find((item) => item.id === id);
    if (item) {
      setSelectedItem(item);
      setShowDeleteModal(true);
    }
  };

  const onClickDeleteItems = () => {
    setSelectedItem(undefined);
    setShowDeleteModal(true);
  };

  const onClickAdd = () => {
    setSelectedItem(undefined);
    setShowModal(true);
  };

  const deleteJobs = () => {
    if (selectedItem) {
      deleteMutate([selectedItem.id]);
    } else if (isSomeItemSelected) {
      const ids = stateData
        .filter((item) => item.selected)
        .map((item) => item.id);
      deleteMutate(ids);
    }
  };

  useEffect(() => {
    setTotalCount(data?.data?.totalElements ?? 0);
    setStateData(data?.data?.content ?? []);
  }, [data]);

  useEffect(() => {
    const selectedItems = stateData.filter((item) => item.selected === true);
    setIsSomeItemSelected(selectedItems.length > 0);
  }, [stateData]);

  useEffect(() => {
    if (deleteSuccess) {
      setShowDeleteModal(false);
      setSelectedItem(undefined);
      refetch();
    }
  }, [deleteSuccess]);

  useEffect(() => {
    handleFilter();
  }, [filterSearch]);

  return {
    totalCount,
    stateData,
    isFetching,
    filter,
    setFilter,
    handleSelect,
    handleSelectAll,
    allSelected,
    isSomeItemSelected,
    setFilterSearch,
    onClickItem,
    showModal,
    setShowModal,
    showDeleteModal,
    setShowDeleteModal,
    selectedItem,
    onClickDeleteItem,
    onClickDeleteItems,
    deleteJobs,
    deleteLoading,
    onClickAdd,
  };
};

export default useJobPositions;
