import { Divider } from "@mui/material";
import moment from "jalali-moment";

import {
  FormWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  LeftWrapper,
  PDFWrapper,
  ResumeWrapper,
  SubTitle,
  TinyTitle,
} from "./ApplicantInterviewerForm.styles";
import { Button } from "../../atoms";
import { IInfoSectionProps } from "./ApplicantInterviewerForm.interface";

import { ReactComponent as IconDownload } from "../../../../assets/icons/ic-download-white.svg";

const InfoSection = ({
  data,
  resumeUrl,
  onClickDownload,
}: IInfoSectionProps) => {
  return (
    <LeftWrapper>
      <FormWrapper>
        <PDFWrapper>
          <HeaderWrapper>
            <InfoWrapper>
              <HeaderTitle>{data.applicants.name}</HeaderTitle>
              <HeaderWrapper>
                <SubTitle>{`Phone: ${data.applicants.phone}`}</SubTitle>
                <SubTitle>{`E-mail: ${data.applicants.email}`}</SubTitle>
              </HeaderWrapper>
              <Divider orientation="horizontal" />
              <TinyTitle>
                {moment(data.applicants.apply_date).format("DD / MMM / YYYY")}
              </TinyTitle>
            </InfoWrapper>
            <Button
              title="Download"
              variant="contained"
              startIcon={<IconDownload />}
              sx={{ maxWidth: "fit-content" }}
              onClick={onClickDownload}
            />
          </HeaderWrapper>

          {resumeUrl && (
            <ResumeWrapper>
              <iframe src={`${resumeUrl}#toolbar=0`} title="resume" />
            </ResumeWrapper>
          )}
        </PDFWrapper>
      </FormWrapper>
    </LeftWrapper>
  );
};

export default InfoSection;
