import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { IApplication, IListItem } from "../../../../models";
import { UseApplication, UseJobPosition, useAlert } from "../../../../hooks";
import { IApplicationCreate, IApplicationUpdate } from "../../../../network";

const useApplicationForm = (existModel?: IApplication) => {
  const { alert } = useAlert();
  const navigate = useNavigate();

  const [formSubmit, setFormSubmit] = useState(false);
  const [jobPositions, setJobPositions] = useState<IListItem[]>([]);
  const [model, setModel] = useState<IApplication>(
    existModel ?? {
      id: 0,
      organization: "",
      job_position: {
        id: 0,
        organization: "",
        title: "",
      },
      title: "",
      summery: "",
      description: "",
      url: "",
      start_date: "",
      exp_date: "",
      status: "",
      default_folder: "",
    }
  );

  const { data: jobPositionData } = UseJobPosition.useGetJobPositions({
    page: 0,
    size: 1000,
    filter: "",
    sort: "id,desc",
  });

  const {
    isLoading: createLoading,
    isSuccess: createSuccess,
    isError: createError,
    mutate: createMutate,
  } = UseApplication.useCreate();
  const {
    isLoading: updateLoading,
    isSuccess: updateSuccess,
    isError: updateError,
    mutate: updateMutate,
  } = UseApplication.useUpdate();

  const validation = () => {
    let valid = true;

    if (!model.job_position?.id) {
      valid = false;
    }

    if (!model.title) {
      valid = false;
    }
    if (!model.summery) {
      valid = false;
    }

    if (!model.description) {
      valid = false;
    }

    // if (!model.url) {
    //   valid = false;
    // }

    // if (!model.start_date) {
    //   valid = false;
    // }

    // if (!model.exp_date) {
    //   valid = false;
    // }

    if (!model.status) {
      valid = false;
    }

    setFormSubmit(true);
    return valid;
  };

  const onClickCancel = () => {
    navigate(-1);
  };

  const onClickSubmit = () => {
    if (!validation()) {
      return;
    }

    const createModel: IApplicationCreate = {
      job_position_id: model.job_position?.id ?? 0,
      title: model.title,
      summary: model.summery,
      description: model.description,
      url: model.url,
      start_date: model.start_date,
      exp_date: model.exp_date,
      status: model.status,
    };

    if (existModel) {
      const updateModel: IApplicationUpdate = {
        ...createModel,
        id: existModel.id,
      };
      updateMutate(updateModel);
    } else {
      createMutate(createModel);
    }
  };

  useEffect(() => {
    if (jobPositionData?.data.content) {
      setJobPositions(
        jobPositionData.data.content.map(
          (item): IListItem => ({
            key: item.id,
            value: item.title,
          })
        )
      );
    }
  }, [jobPositionData]);

  useEffect(() => {
    if (updateError || createError) {
      alert({
        message: "Create / Update application failed",
        variant: "error",
      });
    } else if (updateSuccess || createSuccess) {
      alert({
        message: "Application Create/Update successfully",
        variant: "success",
      });
      onClickCancel();
    }
  }, [createError, updateError, createSuccess, updateSuccess]);

  return {
    model,
    setModel,
    jobPositions,
    updateLoading,
    createLoading,
    onClickSubmit,
    onClickCancel,
    formSubmit,
  };
};

export default useApplicationForm;
