import OTPInput from "react-otp-input";
// import { InputHTMLAttributes, ReactNode } from "react";

import { Button } from "../../atoms";
import {  IResetPassOtpProps } from "./ResetPass.interface";
import {
  ButtonWrapper,
  EnterCodeWrapper,
  ForgotPass,
  FormWrapper,
  Remember,
  RememberWrapper,
  SendAgainWrapper
} from "./ResetPass.styles";

import { ReactComponent as IconArrow } from "../../../../assets/icons/ic-arrow-left.svg";

const ResetPassOtp = ({
  code,onClickNext,onClickSendAgain,setCode,onClickBack,isLoading
}: IResetPassOtpProps) => {
  return (
    <FormWrapper>
      <EnterCodeWrapper>Enter Verify Code</EnterCodeWrapper>

      <OTPInput
        value={code}
        onChange={(otp) => setCode(otp)}
        numInputs={4}
        renderSeparator={<></>}
        shouldAutoFocus
        containerStyle="otp-wrapper"
        renderInput={(props) => <input 
          {...props}         
          className={`otp-input-item`}
        />}
      />

      <SendAgainWrapper onClick={onClickSendAgain}>
        <Remember>Didn’t receive the code?</Remember>
        <ForgotPass >Send Again</ForgotPass>
      </SendAgainWrapper>

      <ButtonWrapper>
        <RememberWrapper onClick={onClickBack}>
          <IconArrow />
          <Remember >Back</Remember>
        </RememberWrapper>
        <Button
          title="Submit"
          variant="contained"   
          loading={isLoading}       
          onClick={onClickNext}
        />
      </ButtonWrapper>
    </FormWrapper>
  );
};

export default ResetPassOtp;
