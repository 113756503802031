import styled from "@emotion/styled";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex: 10;
  height: 100%;
`;

export const LeftWrapper = styled.div`
  min-width: 732px;
  width: 100%;
  flex: 7;
`;

export const RightWrapper = styled.div`
  flex: 3;
  width: 100%;
`;

export const MainFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  overflow: auto;
`;

export const PDFWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
export const BigHeaderTitle = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

export const HeaderTitle = styled.span`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

export const SubTitle = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

export const SubTitleBlue = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary.dark};
  cursor: pointer;
`;

export const TinyTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

export const FormWrapper = styled.div`
  padding: 24px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.14);
  height: 100%;
  overflow: auto;
`;

export const InterviewItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ResumeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
  height: 100%;
  overflow: auto;

  iframe {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
