import { Button } from "../../atoms";
import { IResetPassFinishProps } from "./ResetPass.interface";
import { ButtonWrapper, SuccessWrapper, SuccessText } from "./ResetPass.styles";

import { ReactComponent as IconCheck } from "../../../../assets/icons/ic-check-green-white.svg";

const ResetPassSuccess = ({ onClickSubmit }: IResetPassFinishProps) => {
  return (
    <SuccessWrapper style={{ marginTop: "0 !important" }}>
      <IconCheck />
      <SuccessText>Your password changed successfully!</SuccessText>
      <ButtonWrapper>
        <Button title="Login" variant="contained" onClick={onClickSubmit} />
      </ButtonWrapper>
    </SuccessWrapper>
  );
};

export default ResetPassSuccess;
