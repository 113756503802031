import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { PAGE_SIZE } from "../../../../util/constants";
import { UseInterviewer, useDashboardRoutes } from "../../../../hooks";
import { IGetApplicantFilter } from "../../../../network";
import { IInterviewerResume } from "../../../../models";

const useApplicantsForInterviewer = () => {
  const navigate = useNavigate();
  const { urls } = useDashboardRoutes();

  const [filterPosition, setFilterPosition] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [stateData, setStateData] = useState<IInterviewerResume[]>([]);

  const [filter, setFilter] = useState<IGetApplicantFilter>({
    page: 0,
    size: PAGE_SIZE,
    sort: "id,desc",
    filter: ""
  });

  const { data, isLoading: isFetching } = UseInterviewer.useGetResumes(filter);

  const handleFilter = () => {
    const searchFilterArray = [];
    if (filterPosition && typeof filterPosition === "string") {
      searchFilterArray.push(
        `applicants.jobApplication.jobPositions.title=ilike='${filterPosition}'`
      );
    }

    if (searchFilterArray.length > 0) {
      setFilter({
        ...filter,
        page: 0,
        filter: searchFilterArray.join(";")
      });
    } else {
      setFilter({
        ...filter,
        page: 0,
        sort: "id,desc",
        filter: ""
      });
    }
  };

  const handleClearFilter = () => {
    setFilterPosition("");
    setFilter({
      ...filter,
      page: 0,
      sort: "id,desc",
      filter: ""
    });
  };

  const handleSortDate = (val: string) => {
    setFilter({
      ...filter,
      sort: val === "Newest" ? "planDate,desc" : "planDate,asc"
    });
  };

  const onClickItem = (id: number, applicationId: number) => {
    navigate(
      urls.interviewerSubRoots.APPLICANT_DETAIL.address
        .replace(":id", String(id))
        .replace(":applicationId", String(applicationId))
    );
  };

  useEffect(() => {
    setTotalCount(data?.data?.totalElements ?? 0);
    setStateData(data?.data?.content ?? []);
  }, [data]);

  useEffect(() => {
    handleFilter();
  }, [filterPosition]);

  return {
    totalCount,
    stateData,
    isFetching,
    filter,
    setFilter,
    setFilterPosition,
    handleClearFilter,
    handleSortDate,
    onClickItem
  };
};

export default useApplicantsForInterviewer;
