import axios, { AxiosResponse } from "axios";

import { IJobPosition, IRequestFilter, IResponse } from "../../models";
import { IJobPositionCreate, IJobPositionUpdate } from "./interfaces";

export const getPositions = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IJobPosition>, any>> => {
  return axios.get("/api/v1/job-positions", {
    params: filter
  });
};

export const getPosition = (
  id: number
): Promise<AxiosResponse<IJobPosition, any>> => {
  return axios.get(`/api/v1/job-positions/${id}`);
};

export const create = (
  data: IJobPositionCreate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.post("/api/v1/job-positions", data);
};

export const update = (
  data: IJobPositionUpdate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/job-positions/${data.id}`, data);
};

export const deleteJobPosition = (
  ids: number[]
): Promise<AxiosResponse<unknown, any>> => {
  return axios.delete("/api/v1/job-positions", {
    data: {
      ids
    }
  });
};
