import axios, { AxiosResponse } from "axios";

import { IFolder, IRequestFilter, IResponse } from "../../models";
import { IFolderCreate, IFolderUpdate } from "./interfaces";

export const getFolders = (
  filter: IRequestFilter
): Promise<AxiosResponse<IResponse<IFolder>, any>> => {
  return axios.get("/api/v1/job-folders", {
    params: filter,
  });
};

export const getFolder = (id: number): Promise<AxiosResponse<IFolder, any>> => {
  return axios.get(`/api/v1/job-folders/${id}`);
};

export const create = (
  data: IFolderCreate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.post("/api/v1/job-folders", data);
};

export const update = (
  data: IFolderUpdate
): Promise<AxiosResponse<unknown, any>> => {
  return axios.put(`/api/v1/job-folders/${data.id}`, data);
};

export const deleteFolders = (
  ids: number[]
): Promise<AxiosResponse<unknown, any>> => {
  return axios.delete("/api/v1/job-folders", {
    params: {
      ids: ids.join(","),
    },
  });
};

export const deleteFolder = (
  id: number
): Promise<AxiosResponse<unknown, any>> => {
  return axios.delete(`/api/v1/job-folders/${id}`, {});
};
