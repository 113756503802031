// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_ACCOUNT: "get_account",
  GET_APPLICATIONS_PUBLISHED: "get_applications_published",
  GET_APPLICATIONS_CLOSED: "get_applications_closed",
  GET_APPLICATIONS_DRAFT: "get_applications_draft",
  GET_APPLICATION: "get_application",
  GET_APPLICATION_UNREAD_COUNT: "get_application_unread_count",
  GET_JOB_POSITIONS: "get_job_positions",
  GET_JOB_POSITION: "get_job_position",
  GET_FOLDERS: "get_folders",
  GET_FOLDER: "get_folder",
  GET_APPLICANTS: "get_applicants",
  GET_APPLICANT: "get_applicant",
  GET_APPLICANT_RESUME: "get_applicant_resume",
  GET_INTERVIEWERS: "get_interviewers",
  GET_AUTHORITIES: "get_authorities",
  GET_AUTHORITY: "get_authority",
  GET_USERS: "get_users",
  GET_USER: "get_user",
  GET_INTERVIEWER_RESUMES: "get_interviewer_resumes",
  GET_INTERVIEWER_RESUME: "get_interviewer_resume",
};
