import { IURLS } from "../models";

const useDashboardRoutes = () => {
  const urls: IURLS = {
    public: {
      LOGIN: {
        address: "/sign-in",
        title: "sign-in"
      },
      RESET_PASSWORD: {
        address: "/sign-in/reset-password",
        title: "sign-in"
      }
    },
    admin: {
      APPLICATIONS: {
        address: "/applications",
        title: "Applications"
      },
      APPLICANTS: {
        address: "/applicants/",
        title: "Applicants"
      },
      FOLDERS: {
        address: "/applicants/folder/:folderId",
        title: "Resume Folders"
      },
      MEMBERS: {
        address: "/members",
        title: "Members"
      },
      JOBS: {
        address: "/job-positions",
        title: "Job Positions"
      },
      ROLES: {
        address: "/roles",
        title: "Roles"
      }
    },
    adminSubRoots: {
      APPLICATION_CREATE: {
        address: "/applications/create",
        title: "Application Create"
      },
      APPLICANTS_RECENT: {
        address: "/applicants/folder/recent",
        title: "Applicants Recent"
      },
      APPLICANTS_STARRED: {
        address: "/applicants/folder/starred",
        title: "Applicants Starred"
      },
      APPLICATION_UPDATE: {
        address: "/applications/update/:id",
        title: "Application Update"
      },
      APPLICANT_DETAIL: {
        address: "/applicants/detail/:id",
        title: "Applicant Detail"
      },
      MEMBER_CREATE: {
        address: "/members/create",
        title: "Member Create"
      },
      MEMBER_UPDATE: {
        address: "/members/update/:username",
        title: "Member Update"
      }
    },
    interviewer: {
      APPLICANTS: {
        address: "/applicants/",
        title: "Applicants"
      }
    },
    interviewerSubRoots: {
      APPLICANT_DETAIL: {
        address: "/applicants/detail/:id/:applicationId",
        title: "Applicant Detail"
      }
    }
  };

  return { urls };
};

export default useDashboardRoutes;
