import styled from "@emotion/styled";

export interface IDateTimePickerStyleProps {
  error?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  .MuiTextField-root {
    height: 40px;
    width: 100%;
    outline: none;

    &.has-error {
      border: 1px solid ${({ theme }) => theme.palette.error.dark} !important;
      border-radius: 8px;

      .MuiInputBase-root {
        border: none;
      }
    }

    .MuiInputBase-root {
      height: 40px;
      border-radius: 8px;
      background-color: ${({ theme }) => theme.palette.background.default};
      border: 1px solid ${({ theme }) => theme.palette.grey[700]};
      outline: none;

      input {
        outline: none;
      }
    }
  }
`;

export const ErrorWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.error.dark};
  margin-top: 4px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
`;

export const Title = styled.span<IDateTimePickerStyleProps>`
  font-size: 16px;
  font-weight: 400;
  color: ${({ error, theme }) =>
    error ? theme.palette.error.dark : theme.palette.text.secondary};
  margin-bottom: 4px;
`;
