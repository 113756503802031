import { useEffect, useState } from "react";

import { UseJobPosition } from "../../../../hooks";

const useJobPositionModal = (
  closing: () => void,
  open: boolean,
  id?: number,
  title?: string
) => {
  const [stateTitle, setStateTitle] = useState("");
  const [hasError, setHasError] = useState(false);

  const {
    mutate: createMutate,
    isSuccess: createSuccess,
    isLoading: createLoading,
  } = UseJobPosition.useCreate();
  const {
    mutate: updateMutate,
    isSuccess: updateSuccess,
    isLoading: updateLoading,
  } = UseJobPosition.useUpdate();
  const { invalidate: invalidateFolders } =
    UseJobPosition.useInvalidateJobPositions();

  const onSave = () => {
    setHasError(false);
    if (stateTitle) {
      if (id) {
        updateMutate({
          id: id,
          title: stateTitle,
        });
      } else {
        createMutate({
          title: stateTitle,
        });
      }
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      invalidateFolders();
      closing();
    }
  }, [createSuccess, updateSuccess]);

  useEffect(() => {
    title ? setStateTitle(title) : setStateTitle("");
    setHasError(false);
  }, [title]);

  useEffect(() => {
    if (!title) {
      setStateTitle("");
    }
    setHasError(false);
  }, [open]);

  return {
    stateTitle,
    setStateTitle,
    createLoading,
    updateLoading,
    onSave,
    hasError,
  };
};

export default useJobPositionModal;
