import { useState } from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Outlet } from "react-router-dom";

import AdminDrawer from "../../molecules/adminDrawer/AdminDrawer";
import {
  Container,
  drawerWidth,
  Main,
  MainWrapper,
} from "./AdminLayout.styles";

import { ReactComponent as IconTypoLogo } from "../../../../assets/icons/ic-typo-logo.svg";
import { useSelector } from "react-redux";
import { IRootState } from "../../../../redux/store";

const AdminLayout = () => {
  const [showDrawer, setShowDrawer] = useState(true);
  const toggleDrawer = () => setShowDrawer(!showDrawer);
  const { user } = useSelector((state: IRootState) => state.auth);  

  return (
    <Container>
      <AppBar
        position="static"
        color="inherit"
        sx={{
          height: "56px",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Toolbar
          sx={{
            height: "56px !important",
            minHeight: "56px !important",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <div>
              <IconTypoLogo />
            </div>
            <div>{`${user?.firstName} ${user?.lastName}`}</div>
          </Box>
        </Toolbar>
      </AppBar>
      <MainWrapper>
        <AdminDrawer
          drawerWidth={drawerWidth}
          onCloseDrawer={toggleDrawer}
          showDrawer={showDrawer}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Main open={showDrawer}>
            <Outlet />
          </Main>
        </LocalizationProvider>
      </MainWrapper>
    </Container>
  );
};

export default AdminLayout;
