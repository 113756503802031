import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { IListItem, IUser } from "../../../../models";
import { UseAuthority, UseUser, useAlert } from "../../../../hooks";
import { IUserCreate } from "../../../../network";
import { emailValidation } from "../../../../util/Validation";

const useUserForm = (existModel?: IUser) => {
  const { alert } = useAlert();
  const navigate = useNavigate();

  const [formSubmit, setFormSubmit] = useState(false);
  const initModel = (): IUserCreate => {
    if (existModel) {
      return {
        email: existModel.email,
        first_name: existModel.first_name,
        last_name: existModel.last_name,
        role_id: existModel.role?.id ?? 0,
        username: existModel.username,
        password: "",
        id: existModel.id,
      };
    } else {
      return {
        email: "",
        first_name: "",
        last_name: "",
        role_id: 0,
        id: 0,
        username: "",
        password: "",
      };
    }
  };

  const [roles, setRoles] = useState<IListItem[]>([]);
  const [model, setModel] = useState<IUserCreate>(initModel);

  const { data: rolesData } = UseAuthority.useGetAuthorities({
    page: 0,
    size: 1000,
    filter: "",
    sort: "id,desc",
  });

  const {
    isLoading: createLoading,
    isSuccess: createSuccess,
    isError: createError,
    mutate: createMutate,
  } = UseUser.useCreate();
  const {
    isLoading: updateLoading,
    isSuccess: updateSuccess,
    isError: updateError,
    mutate: updateMutate,
  } = UseUser.useUpdate();

  const validation = () => {
    let valid = true;

    if (!model?.first_name) {
      valid = false;
      // alert({
      //   message: "Please first name",
      //   variant: "warn",
      // });
    }

    if (!model?.last_name) {
      valid = false;
      // alert({
      //   message: "Please fill last name",
      //   variant: "warn",
      // });
    }

    if (!model?.email) {
      valid = false;
      // alert({
      //   message: "Please fill email",
      //   variant: "warn",
      // });
    } else if (!emailValidation(model?.email)) {
      valid = false;
      // alert({
      //   message: "Please fill email with correct email format",
      //   variant: "warn",
      // });
    }

    if (!model?.role_id) {
      valid = false;
      // alert({
      //   message: "Please fill role",
      //   variant: "warn",
      // });
    }

    if (!existModel) {
      if (!model?.password) {
        valid = false;
        // alert({
        //   message: "Please fill password",
        //   variant: "warn",
        // });
      }
    }

    setFormSubmit(true);
    return valid;
  };

  const onClickCancel = () => {
    navigate(-1);
  };

  const onClickSubmit = () => {
    if (!validation()) {
      return;
    }

    if (existModel) {
      updateMutate(model!);
    } else {
      createMutate(model!);
    }
  };

  useEffect(() => {
    if (rolesData?.data.content) {
      setRoles(
        rolesData.data.content.map(
          (item): IListItem => ({
            key: item.id,
            value: item.name,
          })
        )
      );
    }
  }, [rolesData]);

  useEffect(() => {
    if (updateSuccess || createSuccess) {
      alert({
        message: "User Create/Update successfully",
        variant: "success",
      });
      onClickCancel();
    }
  }, [createError, updateError, createSuccess, updateSuccess]);

  return {
    model,
    setModel,
    roles,
    updateLoading,
    createLoading,
    onClickSubmit,
    onClickCancel,
    formSubmit,
  };
};

export default useUserForm;
