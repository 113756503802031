import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { AppBar, Box, Toolbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  Container,  
  LogoutWrapper,  
  Main,
  MainWrapper,
  NameWrapper,
} from "./InterviewerLayout.styles";
import { IRootState } from "../../../../redux/store";

import { ReactComponent as IconTypoLogo } from "../../../../assets/icons/ic-typo-logo.svg";
import { ReactComponent as IconLogout } from "../../../../assets/icons/ic-logout.svg";
import useInterviewerLayout from "./useInterviewerLayout";

const InterviewerLayout = () => {  
  const { user } = useSelector((state: IRootState) => state.auth);
const {onClickLogout} = useInterviewerLayout();
  return (
    <Container>
      <AppBar
        position="static"
        color="inherit"
        sx={{
          height: "56px",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Toolbar
          sx={{
            height: "56px !important",
            minHeight: "56px !important",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <div>
              <IconTypoLogo />
            </div>
            <NameWrapper>
              <span>{`${user?.firstName} ${user?.lastName}`}</span>
              <LogoutWrapper onClick={onClickLogout}>
                <IconLogout />
                Log out
              </LogoutWrapper>
            </NameWrapper>
          </Box>
        </Toolbar>
      </AppBar>
      <MainWrapper>        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Main >
            <Outlet />
          </Main>
        </LocalizationProvider>
      </MainWrapper>
    </Container>
  );
};

export default InterviewerLayout;
