import { Loading } from "../../atoms";
import { UserForm } from "../../organs";
import useUserUpdate from "./useUserUpdate";

const UserUpdate = () => {
  const { data, isFetching } = useUserUpdate();

  if (isFetching) {
    return <Loading />;
  }

  return <UserForm existModel={data?.data} />;
};

export default UserUpdate;
