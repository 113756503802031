import { Box, Modal, Paper } from "@mui/material";

import { IApplicantHireModalProps } from "./ApplicantHireModal.interface";
import { Title } from "./ApplicantHireModal.styles";
import useApplicantHireModal from "./useApplicantHireModal";
import TinyEditor from "../tinyEditor/TinyEditor";
import Button from "../button/Button";

const ApplicantHireModal = ({
  showModal,
  onCancel,
  onSubmit,
  loading,
  jobPosition,
}: IApplicantHireModalProps) => {
  const { content, setContent } = useApplicantHireModal(jobPosition);

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          padding: "16px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: "70%",
            padding: "16px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="16px"
          >
            <Title>Hired E-mail</Title>
            <TinyEditor onChange={(val) => setContent(val)} initVal={content} />
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyItems="flex-end"
              gap={2}
              justifyContent="flex-end"
              alignSelf="flex-end"
            >
              <Button
                variant="outlined"
                onClick={onCancel}
                title={"Cancel"}
                sx={{ minWidth: "fit-content" }}
              />
              <Button
                variant="contained"
                loading={loading}
                sx={{ minWidth: "fit-content" }}
                onClick={() => {
                  if (content) {
                    onSubmit(content);
                  }
                }}
                title="Submit"
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ApplicantHireModal;
