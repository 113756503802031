import { useNavigate } from "react-router-dom";

import HeaderContainer from "../../../atoms/headerContainer/HeaderContainer";
import { Wrapper, IconWrapper, Title } from "./ApplicationFormFilter.styles";

import { ReactComponent as IconBack } from "../../../../../assets/icons/ic-arrow-left.svg";

const ApplicantFormFilter = () => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <Wrapper>
        <IconWrapper onClick={() => navigate(-1)}>
          <IconBack />
          <Title>Back</Title>
        </IconWrapper>
      </Wrapper>
    </HeaderContainer>
  );
};

export default ApplicantFormFilter;
