import { Divider } from "@mui/material";
import moment from "jalali-moment";

import {
  FormWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  LeftWrapper,
  PDFWrapper,
  ResumeWrapper,
  SubTitle,
  TinyTitle,
} from "./ApplicantForm.styles";
import { Button } from "../../atoms";
import { IInfoSectionProps } from "./ApplicantForm.interface";

import { ReactComponent as IconDownload } from "../../../../assets/icons/ic-download-white.svg";

const InfoSection = ({
  data,
  resumeUrl,
  onClickDownload,
}: IInfoSectionProps) => {
  const daysAgo = moment(data.apply_date).diff(moment(), "days") < -30;

  return (
    <LeftWrapper>
      <FormWrapper>
        <PDFWrapper>
          <HeaderWrapper>
            <InfoWrapper>
              <HeaderTitle>{data.name}</HeaderTitle>
              <HeaderWrapper>
                <SubTitle>{`Phone: ${data.phone}`}</SubTitle>
                <SubTitle>{`E-mail: ${data.email}`}</SubTitle>
              </HeaderWrapper>
              <Divider orientation="horizontal" sx={{ width: "100%" }} />
              <HeaderWrapper>
                {!daysAgo && (
                  <TinyTitle>{moment(data.apply_date).fromNow()}</TinyTitle>
                )}
                <TinyTitle>
                  {moment(data.apply_date).format("DD / MMM / YYYY")}
                </TinyTitle>
              </HeaderWrapper>
            </InfoWrapper>
            <Button
              title="Download"
              variant="contained"
              startIcon={<IconDownload />}
              sx={{ maxWidth: "fit-content" }}
              onClick={onClickDownload}
            />
          </HeaderWrapper>

          {resumeUrl && (
            <ResumeWrapper>
              <iframe src={`${resumeUrl}#toolbar=0`} title="resume" />
            </ResumeWrapper>
          )}
        </PDFWrapper>
      </FormWrapper>
    </LeftWrapper>
  );
};

export default InfoSection;
