import { Divider } from "@mui/material";

import { IInterviewerItemProps } from "./InterviewerItem.interface";
import { MainWrapper, Title, Wrapper } from "./InterviewerItem.styles";

import { ReactComponent as IconCross } from "../../../../assets/icons/ic-close.svg";

const InterviewerItem = ({
  id,
  onClickRemove,
  title,
}: IInterviewerItemProps) => {
  return (
    <Wrapper>
      <MainWrapper>
        <Title>{title}</Title>
        <IconCross onClick={() => onClickRemove(id)} />
      </MainWrapper>
      <Divider />
    </Wrapper>
  );
};

export default InterviewerItem;
