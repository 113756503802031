import styled from "@emotion/styled";
import { default as TableMUI } from "@mui/material/Table";

export const Table = styled(TableMUI)`
  border: none;
`;

export const Container = styled.div`
  width: 100%;
  max-height: 90%;
  height: 90%;
  overflow: auto;
`;

export const StyledTHead = styled.thead`
  height: 56px;
  tr {
    th {
      position: sticky;
      top: 0;
      z-index: 2;
      color: ${({ theme }) => theme.palette.grey[800]};
      text-align: left;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      padding: 0 8px;
      background-color: ${({ theme }) => theme.palette.background.paper};

      border-bottom: 2px solid ${({ theme }) => theme.palette.grey[300]};
      /* &:first-child {
        border: none;
      } */
    }
  }
`;

export const StyledTBody = styled.tbody`
  tr {
    height: 72px;
    background: ${({ theme }) => theme.palette.background.paper};

    td {
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: ${({ theme }) => theme.palette.grey[800]};
      padding: 0 8px;
      user-select: none;
      border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey[400]};
    }

    /* &:last-child {
      box-shadow: none;
    } */
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  min-height: 64px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
