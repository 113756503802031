import Loading from "../../atoms/loading/Loading";
import { Container } from "./ApplicationsList.styles";
import { IApplicationsListProps } from "./ApplicationsList.interface";
import { ApplicationListItem } from "../../atoms";

const ApplicationsList = ({
  items,
  loading = false,
  showDate,
  showPreview,
}: IApplicationsListProps) => {
  return (
    <Container>
      {loading && <Loading />}
      {items?.map((item, index) => (
        <ApplicationListItem
          key={index}
          {...item}
          showPreview={showPreview}
          showDate={showDate}
        />
      ))}
    </Container>
  );
};

export default ApplicationsList;
