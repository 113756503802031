import { useState } from "react";

import { IListItem } from "../../../../models";
import { rejectTextEmail } from "./textConfig";

const useApplicantRejectionModal = () => {
  const reasons: IListItem[] = [
    {
      key: "LACK_OF_RESUME_INFORMATION",
      value: "Lack of resume information",
    },
    {
      key: "INSUFFICIENT_TECHNICAL_KNOWLEDGE",
      value: "Insufficient technical knowledge",
    },
    {
      key: "OVER_QUALIFIED",
      value: "Over qualified",
    },
    {
      key: "JOB_POSITION_BEING_FILLED",
      value: "job position bing filled",
    },
    {
      key: "IRRELEVANCE_OF_REQUESTED_SALARY_TO_THE_JOB_POSITION",
      value: "Irrelevance of requested salary to the job position",
    },
    {
      key: "NOT_HAVING_ENOUGH_RELEVANT_WORK_EXPERIENCE",
      value: "Not having enough relevant work experience",
    },
    {
      key: "OTHER_REASONS",
      value: "Other reasons",
    },
  ];

  const [content, setContent] = useState(rejectTextEmail);
  const [selectedReason, setSelectedReason] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);

  const validation = () => {
    let valid = true;

    if (!selectedReason) {
      valid = false;
    }

    setFormSubmit(true);
    return valid;
  };

  return {
    reasons,
    content,
    setContent,
    selectedReason,
    setSelectedReason,
    validation,
    formSubmit,
  };
};

export default useApplicantRejectionModal;
