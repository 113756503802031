import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import { UseApplication } from "../../../../hooks";

const useApplicationUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isFetching, isError } = UseApplication.useGetApplication(
    Number(id!)
  );

  useEffect(() => {
    if (isError) {
      navigate(-1);
    }
  }, [isError]);

  return { data, isFetching };
};

export default useApplicationUpdate;
