import { Fragment } from "react";
import { Divider } from "@mui/material";

import {
  Button,
  Input,
  NormalPage,
  Loading,
  Select,
  ApplicantRejectionModal,
  InterviewerItem,
  ApplicantInterviewModal,
  ApplicantHireModal,
} from "../../atoms";
import ApplicantFormFilter from "./filter/ApplicantFormFilter";
import useApplicantForm from "./useApplicantForm";
import {
  FormWrapper,
  MainFormWrapper,
  RightWrapper,
  MainWrapper,
  InterviewItemsWrapper,
  ButtonWrapper,
  SubTitle,
} from "./ApplicantForm.styles";
import {
  ApplicantStatus,
  getApplicantsStatusAfterInterviewAsSelectList,
  getApplicantsStatusBeforeInterviewAsSelectList,
} from "../../../../models";
import InfoSection from "./InfoSection";
import InterviewSection from "./InterviewSection";
import RejectReason from "./RejectReason";

const ApplicantForm = () => {
  const {
    id,
    data,
    interviewers,
    isFetching,
    note,
    setNote,
    noteLoading,
    noteMutate,
    status,
    setStatus,
    rejectLoading,
    rejectMutate,
    confirmLoading,
    confirmMutate,
    hireLoading,
    hireMutate,
    setShowRejectionModal,
    showRejectionModal,
    showHiredModal,
    showInterviewModal,
    setShowHiredModal,
    setShowInterviewModal,
    selectedInterviewer,
    setSelectedInterviewers,
    interviewData,
    alert,
    resumeUrl,
    onClickDownload,
    hasInterviewerComment,
  } = useApplicantForm();

  if (isFetching) {
    return <Loading />;
  }

  return (
    <>
      <NormalPage>
        <ApplicantFormFilter folderName={data?.data.folder?.name} />
        <div className="main-content">
          <MainWrapper>
            <InfoSection
              data={data?.data!}
              resumeUrl={resumeUrl}
              onClickDownload={onClickDownload}
            />
            <RightWrapper>
              <FormWrapper>
                <MainFormWrapper>
                  <Input
                    title="Confidential Note:"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    multiline={true}
                    rows={5}
                    maxRows={5}
                  />
                  <ButtonWrapper>
                    <Button
                      title="Discard"
                      variant="outlined"
                      disabled={!note}
                      sx={{ maxWidth: "fit-content", alignSelf: "end" }}
                      onClick={() => setNote("")}
                    />
                    <Button
                      title="Save"
                      variant="contained"
                      disabled={!note}
                      sx={{ maxWidth: "fit-content", alignSelf: "end" }}
                      loading={noteLoading}
                      onClick={() =>
                        noteMutate({
                          id: Number(id),
                          note,
                        })
                      }
                    />
                  </ButtonWrapper>

                  <Divider orientation="horizontal" sx={{ width: "100%" }} />

                  {/* interview place and date info */}
                  {data?.data.status === String(ApplicantStatus.REJECTED) && (
                    <>
                      <SubTitle>Rejection E-mail has been sent.</SubTitle>
                      <Divider
                        orientation="horizontal"
                        sx={{ width: "100%" }}
                      />
                    </>
                  )}

                  {(data?.data.status === String(ApplicantStatus.REJECTED) ||
                    data?.data.status === String(ApplicantStatus.HIRED)) && (
                    <RejectReason
                      status={data?.data.status as ApplicantStatus}
                      reason={
                        data?.data.reject_reason
                          ? data?.data.reject_reason
                          : undefined
                      }
                    />
                  )}

                  {/* interview place and date info */}
                  <InterviewSection data={data?.data!} />

                  {/* interviewers comments */}
                  {data?.data.interviews?.interview_results?.map(
                    (item, index) => {
                      if (item.note) {
                        return (
                          <Fragment key={index}>
                            <Input
                              title={item.role}
                              value={item.note}
                              multiline={true}
                              rows={5}
                              maxRows={5}
                              disabled={true}
                            />
                            <Divider
                              orientation="horizontal"
                              sx={{ width: "100%" }}
                            />
                          </Fragment>
                        );
                      } else {
                        return <Fragment key={index}></Fragment>;
                      }
                    }
                  )}

                  {/* select box for pending status */}
                  {data?.data.status === String(ApplicantStatus.PENDING) && (
                    <Select
                      items={getApplicantsStatusBeforeInterviewAsSelectList()}
                      selectProps={{
                        title: "Application Status",
                        onChange: (e) => {
                          setStatus(String(e.target.value));
                          setSelectedInterviewers([]);
                        },
                      }}
                    />
                  )}

                  {/* interviewer select box */}
                  {data?.data.status === String(ApplicantStatus.PENDING) &&
                    status ===
                      String(ApplicantStatus.CONFIRM_FOR_INTERVIEW) && (
                      <Select
                        items={interviewers}
                        selectProps={{
                          title: "Add Interviewer",
                          onChange: (e) => {
                            const existItem = selectedInterviewer.find(
                              (item) => item.id === Number(e.target.value)
                            );
                            if (!existItem) {
                              const item = interviewData?.data.content.find(
                                (item) => item.id === Number(e.target.value)
                              );
                              setSelectedInterviewers([
                                ...selectedInterviewer,
                                ...[item!],
                              ]);
                            }
                          },
                        }}
                      />
                    )}

                  {/* interviewer list */}
                  <InterviewItemsWrapper>
                    {selectedInterviewer.map((item) => (
                      <InterviewerItem
                        key={item.id}
                        id={item.id}
                        onClickRemove={(id) => {
                          if (
                            data?.data.status ===
                            String(ApplicantStatus.PENDING)
                          ) {
                            setSelectedInterviewers(
                              selectedInterviewer.filter(
                                (item) => item.id !== id
                              )
                            );
                          }
                        }}
                        title={item.name}
                      />
                    ))}
                  </InterviewItemsWrapper>

                  {/* continue button for pending status */}
                  {data?.data.status === String(ApplicantStatus.PENDING) && (
                    <Button
                      title="Continue"
                      disabled={!status}
                      variant="contained"
                      sx={{ maxWidth: "fit-content", alignSelf: "end" }}
                      onClick={() => {
                        if (status === ApplicantStatus.REJECTED) {
                          setShowRejectionModal(true);
                        }
                        if (status === ApplicantStatus.CONFIRM_FOR_INTERVIEW) {
                          if (selectedInterviewer.length > 0) {
                            setShowInterviewModal(true);
                          } else {
                            alert({
                              message: "Please select Interviewers first",
                              variant: "warn",
                            });
                          }
                        }
                        if (status === ApplicantStatus.HIRED) {
                          setShowHiredModal(true);
                        }
                      }}
                    />
                  )}

                  {/* select box for change status to hire or reject */}
                  {hasInterviewerComment &&
                    data?.data.status ===
                      String(ApplicantStatus.CONFIRM_FOR_INTERVIEW) && (
                      <>
                        <Select
                          items={getApplicantsStatusAfterInterviewAsSelectList()}
                          selectProps={{
                            title: "Application Status",
                            onChange: (e) => {
                              setStatus(String(e.target.value));
                            },
                          }}
                        />
                        <Button
                          title="Continue"
                          disabled={!status}
                          variant="contained"
                          sx={{ maxWidth: "fit-content", alignSelf: "end" }}
                          loading={noteLoading}
                          onClick={() => {
                            if (status === ApplicantStatus.REJECTED) {
                              setShowRejectionModal(true);
                            }
                            if (status === ApplicantStatus.HIRED) {
                              setShowHiredModal(true);
                            }
                          }}
                        />
                      </>
                    )}
                </MainFormWrapper>
              </FormWrapper>
            </RightWrapper>
          </MainWrapper>
        </div>
      </NormalPage>
      <ApplicantRejectionModal
        loading={rejectLoading}
        onCancel={() => setShowRejectionModal(false)}
        onSubmit={(reason, content) =>
          rejectMutate({
            id: Number(id),
            email_content: content,
            reject_reason: reason,
          })
        }
        showModal={showRejectionModal}
      />
      <ApplicantInterviewModal
        applicantName={data?.data.name ?? ""}
        jobPosition={data?.data.job_application.job_position?.title ?? ""}
        loading={confirmLoading}
        onCancel={() => setShowInterviewModal(false)}
        onSubmit={(type, place, date, content) => {
          if (selectedInterviewer.length > 0) {
            confirmMutate({
              id: Number(id),
              interviewer_role_ids: selectedInterviewer.map((item) => item.id),
              place,
              plan_date: date,
              type: type,
              email_content: content,
            });
          }
        }}
        showModal={showInterviewModal}
      />
      <ApplicantHireModal
        jobPosition={data?.data.job_application.job_position?.title ?? ""}
        loading={hireLoading}
        onCancel={() => setShowHiredModal(false)}
        onSubmit={(content) => {
          hireMutate({
            id: Number(id),
            email_content: content,
          });
        }}
        showModal={showHiredModal}
      />
    </>
  );
};

export default ApplicantForm;
