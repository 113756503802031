import { Divider } from "@mui/material";

import {
  BigHeaderTitle,
  MainFormWrapper,
  SubTitle,
} from "./ApplicantForm.styles";
import { IRejectReasonProps } from "./ApplicantForm.interface";
import { ApplicantStatus } from "../../../../models";

const RejectReason = ({ reason, status }: IRejectReasonProps) => {
  return (
    <>
      <MainFormWrapper>
        {status === ApplicantStatus.REJECTED && (
          <BigHeaderTitle>Rejected Reason</BigHeaderTitle>
        )}
        <SubTitle>
          {reason
            ? reason.replaceAll("_", " ").toLowerCase()
            : "Hired E-mail has been sent."}
        </SubTitle>
      </MainFormWrapper>
      <Divider orientation="horizontal" sx={{ width: "100%" }} />
    </>
  );
};

export default RejectReason;
