import { useState } from "react";
import { Checkbox } from "@mui/material";

import { Button, Input } from "../../atoms";
import { IResetPassFinalProps } from "./ResetPass.interface";
import {
  ButtonWrapper,
  FormWrapper,
  Remember,
  RememberWrapper
} from "./ResetPass.styles";

const ResetPassFinal = ({
  onClickSubmit,
  password,
  passwordConfirm,
  setPassword,
  setPasswordConfirm,
  loading,
  hasError,
  errorMessage
}: IResetPassFinalProps) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <FormWrapper>
      <Input
        title="New Password"
        type={showPass ? "text" : "password"}
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Input
        error={hasError}
        errorMessage={errorMessage}
        title="Repeat Password"
        type={showPass ? "text" : "password"}
        value={passwordConfirm}
        onChange={e => setPasswordConfirm(e.target.value)}
      />
      <RememberWrapper>
        <Checkbox
          sx={{ padding: 0 }}
          checked={showPass}
          onChange={e => setShowPass(e.target.checked)}
        />
        <Remember>Show Password</Remember>
      </RememberWrapper>
      <ButtonWrapper>
        <Button
          title="Submit"
          variant="contained"
          loading={loading}
          onClick={onClickSubmit}
        />
      </ButtonWrapper>
    </FormWrapper>
  );
};

export default ResetPassFinal;
