import { NormalPage, Pagination, Table } from "../../atoms";
import ApplicationsFilter from "./filter/ApplicantsFilter";
import useApplicantsForInterviewer from "./useApplicantsForInterviewer";
import useApplicantsTableConfig from "./config/config";

const ApplicantsForInterviewer = () => {
  const {
    totalCount,
    stateData,
    isFetching,
    filter,
    setFilter,
    setFilterPosition,
    handleClearFilter,
    handleSortDate,
    onClickItem
  } = useApplicantsForInterviewer();
  const { getTableDefinition } = useApplicantsTableConfig(onClickItem);

  return (
    <NormalPage>
      <ApplicationsFilter
        handleClearFilter={handleClearFilter}
        handleFilterPosition={setFilterPosition}
        handleSortDate={handleSortDate}
      />
      <div className="list-content">
        <div className="form-style">
          <Table
            definitions={getTableDefinition()}
            items={stateData}
            isLoading={isFetching}
          />
          <Pagination
            onPageChanged={page =>
              setFilter({
                ...filter,
                page: page - 1
              })}
            totalCount={totalCount}
            currentPage={filter.page + 1}
          />
        </div>
      </div>
    </NormalPage>
  );
};

export default ApplicantsForInterviewer;
