import { Box, Modal, Paper } from "@mui/material";
import { Dayjs } from "dayjs";

import { IApplicantInterviewModalProps } from "./ApplicantInterviewModal.interface";
import { LineWrapper, Title } from "./ApplicantInterviewModal.styles";
import useApplicantInterviewModal from "./useApplicantInterviewModal";
import Select from "../select/Select";
import TinyEditor from "../tinyEditor/TinyEditor";
import Button from "../button/Button";
import { getApplicantsInterviewTypeAsSelectList } from "../../../../models";
import Input from "../input/Input";
import DateTimePicker from "../datePickerTime/DateTimePicker";

const ApplicantInterviewModal = ({
  showModal,
  onCancel,
  onSubmit,
  loading,
  jobPosition,
  applicantName,
}: IApplicantInterviewModalProps) => {
  const {
    content,
    setContent,
    setPlace,
    selectedTime,
    selectedType,
    place,
    setSelectedTime,
    setSelectedType,
    formSubmit,
    validation,
  } = useApplicantInterviewModal(jobPosition, applicantName);

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          padding: "16px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: "70%",
            padding: "16px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="16px"
          >
            <Title>Set time for Interview</Title>
            <LineWrapper>
              <Select
                items={getApplicantsInterviewTypeAsSelectList()}
                selectProps={{
                  error: formSubmit && !selectedType,
                  title: "Interview Type",
                  sx: { width: "100%" },
                  onChange: (e) => {
                    setSelectedType(String(e.target.value));
                    setPlace("");
                  },
                }}
              />
              {selectedType === "ONLINE" ? (
                <Input
                  title="Place"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                  error={formSubmit && !place}
                />
              ) : (
                <Select
                  items={[
                    {
                      key: "دفتر مرکزی، مشهد، بین جانباز 3 و 5، ساختمان شیشه ای ست سنتر، ورودی اداری، طبقه 4",
                      value: "دفتر مرکزی",
                    },
                  ]}
                  selectProps={{
                    error: formSubmit && !place,
                    title: "Place",
                    sx: { width: "100%" },
                    onChange: (e) => {
                      setPlace(String(e.target.value));
                    },
                  }}
                />
              )}
            </LineWrapper>
            <DateTimePicker
              title="Date"
              error={formSubmit && !selectedTime}
              datePickerProps={{
                sx: { flex: 1 },
                disablePast: true,
                onChange: (val: any) => {
                  val !== null &&
                    setSelectedTime((val as Dayjs).format("YYYY-MM-DD HH:mm"));
                },
              }}
            />
            <TinyEditor onChange={(val) => setContent(val)} initVal={content} />
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyItems="flex-end"
              gap={2}
              justifyContent="flex-end"
              alignSelf="flex-end"
            >
              <Button
                variant="outlined"
                onClick={onCancel}
                title={"Cancel"}
                sx={{ minWidth: "fit-content" }}
              />
              <Button
                variant="contained"
                loading={loading}
                sx={{ minWidth: "fit-content" }}
                onClick={() => {
                  if (validation()) {
                    onSubmit(selectedType, place, selectedTime, content);
                  }
                }}
                title="Submit"
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ApplicantInterviewModal;
