import { Tabs } from "@mui/material";

import {
  DeleteConfirmModal,
  NormalPage,
  Pagination,
  StyledTab,
} from "../../atoms";
import ApplicationsFilter from "./filter/ApplicationsFilter";
import useApplications from "./useApplications";
import ApplicationsList from "../../molecules/applicationsList/ApplicationsList";

const Applications = () => {
  const {
    setTab,
    tab,
    convertData,
    publishedData,
    publishedFetching,
    draftData,
    draftFetching,
    closedData,
    closedFetching,
    filterPublished,
    filterClosed,
    filterDraft,
    closeTotalCount,
    draftTotalCount,
    setFilterClosed,
    setFilterDraft,
    setFilterPublished,
    publishedTotalCount,
    handleSearch,
    handleSort,
    showDeleteModal,
    setShowDeleteModal,
    isLoading,
    handleDelete,
  } = useApplications();

  return (
    <>
      <NormalPage>
        <ApplicationsFilter
          handleSort={handleSort}
          handleSearch={(e) => {
            handleSearch(e.target.value);
          }}
        />
        <div className="main-content">
          <Tabs value={tab} onChange={(_, index) => setTab(index)}>
            <StyledTab label="Published" />
            <StyledTab label="Drafts" />
            <StyledTab label="Closed" />
          </Tabs>
          {tab === 0 && (
            <>
              <ApplicationsList
                showDate={true}
                showPreview={true}
                loading={publishedFetching}
                items={
                  publishedData?.data
                    ? convertData(publishedData?.data.content!)
                    : []
                }
              />
              <Pagination
                onPageChanged={(page) =>
                  setFilterPublished({
                    ...filterPublished,
                    page: page - 1,
                  })
                }
                totalCount={publishedTotalCount}
                currentPage={filterPublished.page + 1}
              />
            </>
          )}
          {tab === 1 && (
            <>
              <ApplicationsList
                showDate={false}
                showPreview={false}
                loading={draftFetching}
                items={
                  draftData?.data ? convertData(draftData?.data.content!) : []
                }
              />
              <Pagination
                onPageChanged={(page) =>
                  setFilterDraft({
                    ...filterDraft,
                    page: page - 1,
                  })
                }
                totalCount={draftTotalCount}
                currentPage={filterDraft.page + 1}
              />
            </>
          )}
          {tab === 2 && (
            <>
              <ApplicationsList
                showDate={true}
                showPreview={true}
                loading={closedFetching}
                items={
                  closedData?.data ? convertData(closedData?.data.content!) : []
                }
              />
              <Pagination
                onPageChanged={(page) =>
                  setFilterClosed({
                    ...filterClosed,
                    page: page - 1,
                  })
                }
                totalCount={closeTotalCount}
                currentPage={filterClosed.page + 1}
              />
            </>
          )}
        </div>
      </NormalPage>
      <DeleteConfirmModal
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        loading={isLoading}
      />
    </>
  );
};

export default Applications;
