import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Application } from "../network";
import Queries from "../util/Queries";
import { IRequestFilter } from "../models";

export const useGetPublished = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_APPLICATIONS_PUBLISHED, filter],
      () => Application.getPublished(filter),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useGetDraft = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_APPLICATIONS_DRAFT, filter],
      () => Application.getDraft(filter),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useGetApplicationUnreadCount = () => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_APPLICATION_UNREAD_COUNT],
      () => Application.getUnreadCount(),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useInvalidateUnreadCount = () => {
  const query = useQueryClient();
  const invalidate = () => {
    query.invalidateQueries([Queries.GET_APPLICATION_UNREAD_COUNT]);
  };
  return { invalidate };
};

export const useGetClosed = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_APPLICATIONS_CLOSED, filter],
      () => Application.getClosed(filter),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useGetApplication = (id: number) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery(
      [Queries.GET_APPLICATION, id],
      () => Application.getApplication(id),
      {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useCreate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Application.create
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useUpdate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Application.update
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useDelete = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Application.deleteApplications
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};
