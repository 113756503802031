import { createSlice } from "@reduxjs/toolkit";

import { IUser, IUserJWT } from "../models";

export interface IAuthState {
  token?: string;
  isLogin: boolean;
  user?: IUser;
  userJWT?: IUserJWT;
}

const initialState: IAuthState = {
  token: undefined,
  isLogin: false,
  user: undefined,
  userJWT: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleLogin: (
      state,
      action: {
        payload: IAuthState;
      }
    ) => {
      state.token = action.payload.token;
      state.isLogin = action.payload.isLogin;
    },
    handleLogout: (state) => {
      state.token = undefined;
      state.isLogin = false;
      state.user = undefined;
      state.userJWT = undefined;
    },
    handleUser: (
      state,
      action: {
        payload: IUser | undefined;
      }
    ) => {
      state.user = action.payload;
    },
    handleUserJWT: (
      state,
      action: {
        payload: IUserJWT | undefined;
      }
    ) => {
      state.userJWT = action.payload;
    },
  },
});

export const { handleLogin, handleUser, handleUserJWT, handleLogout } =
  authSlice.actions;
export default authSlice.reducer;
