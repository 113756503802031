import { useTheme } from "@emotion/react";

import { IUser } from "../../../../../models";
import { ITableDefinition } from "../../../atoms";
import { ActivateWrapper, OperationWrapper } from "./config.styles";

import { ReactComponent as IconCheckBox } from "../../../../../assets/icons/ic-check-box.svg";
import { ReactComponent as IconCheckBoxDisable } from "../../../../../assets/icons/ic-checkbox-disable.svg";
import { ReactComponent as IconCheckBoxSemi } from "../../../../../assets/icons/ic-check-box-semi-checked.svg";
import { ReactComponent as IconCheckBoxSelected } from "../../../../../assets/icons/ic-check-box-checked.svg";
import { ReactComponent as IconEdit } from "../../../../../assets/icons/ic-edit.svg";
import { ReactComponent as IconTrash } from "../../../../../assets/icons/ic-trash.svg";
import { ReactComponent as IconTrashDisable } from "../../../../../assets/icons/ic-trash-disable.svg";

const useUsersTableConfig = (
  isAllSelected: boolean,
  isSomeSelected: boolean,
  setSelectedAll: (selected: boolean) => void,
  setSelected: (selected: boolean, id: number) => void,
  onClickEdit: (id: number) => void,
  onClickDelete: (id: number) => void,
  onClickDisable: (id: number) => void
) => {
  const theme = useTheme();
  const getTableDefinition = (): ITableDefinition<IUser>[] => {
    return [
      {
        columnKey: "firstName",
        label: "Full Name",
        renderer: (data) => {
          return <>{`${data.first_name} ${data.last_name}`}</>;
        },
        columnStyle: (data) => ({
          backgroundColor: !data.activated
            ? theme.palette.grey[300]
            : theme.palette.background.default,
        }),
      },
      {
        columnKey: "email",
        label: "Email/Username",
        columnStyle: (data) => ({
          backgroundColor: !data.activated
            ? theme.palette.grey[300]
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          return <>{data.email}</>;
        },
      },
      {
        columnKey: "authorities",
        label: "Role",
        columnStyle: (data) => ({
          backgroundColor: !data.activated
            ? theme.palette.grey[300]
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          const roles = data.role?.name;
          return <>{roles}</>;
        },
      },
      {
        columnKey: "operation",
        label: "",
        columnStyle: (data) => ({
          backgroundColor: !data.activated
            ? theme.palette.grey[300]
            : theme.palette.background.default,
        }),
        renderer: (data) => {
          return (
            <OperationWrapper>
              {data.activated ? (
                <ActivateWrapper onClick={() => onClickDisable(data.id)}>
                  Disable User
                </ActivateWrapper>
              ) : (
                <ActivateWrapper onClick={() => onClickDisable(data.id)}>
                  Enable User
                </ActivateWrapper>
              )}
              <IconEdit onClick={() => onClickEdit(data.id)} />
            </OperationWrapper>
          );
        },
      },
    ];
  };

  return { getTableDefinition };
};

export default useUsersTableConfig;
