import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Folder } from "../network";
import Queries from "../util/Queries";
import { IRequestFilter } from "../models";

export const useGetFolders = (filter: IRequestFilter) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery([Queries.GET_FOLDERS, filter], () => Folder.getFolders(filter), {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    });

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useInvalidateFolders = () => {
  const query = useQueryClient();
  const invalidate = () => {
    query.invalidateQueries([Queries.GET_FOLDERS]);
  };
  return { invalidate };
};

export const useInvalidateFolder = () => {
  const query = useQueryClient();
  const invalidate = () => {
    query.invalidateQueries([Queries.GET_FOLDER]);
  };
  return { invalidate };
};

export const useGetFolder = (id: number) => {
  const { data, error, isFetching, isLoading, isError, isSuccess, refetch } =
    useQuery([Queries.GET_FOLDER, id], () => Folder.getFolder(id), {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    });

  return { data, error, isFetching, isLoading, isError, isSuccess, refetch };
};

export const useCreate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Folder.create
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useUpdate = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Folder.update
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useDelete = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Folder.deleteFolders
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};

export const useDeleteSingle = () => {
  const { data, mutate, isLoading, isError, isSuccess, error } = useMutation(
    Folder.deleteFolder
  );

  return { data, mutate, isLoading, isError, isSuccess, error };
};
