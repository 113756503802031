import styled from "@emotion/styled";
import { styled as MUIStyled, MenuItem, Menu, MenuProps } from "@mui/material";

export const Container = styled.div`
  width: 100%;
  min-width: 180px;
`;

export const StyleMenuItem = MUIStyled(MenuItem)(({ theme }) => ({
  width: "100%",
}));

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "100%",
    maxWidth: "265px",
  },
}));
