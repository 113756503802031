export const rejectTextEmail = `<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">با سلام </span></span></span></span><br><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">متاسفانه درخواست شما برای پذیرش در موقعیت شغلی اعلام شده پذیرفته نشده است </span></span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">ما از شما برای علاقه و زمانی که برای شرکت در مصاحبه و فرآیند پیش استخدامی گذاشته اید ، قدردانی می کنیم&nbsp;</span></span></span></span>. <span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">در ادامه ، اطلاعات تماس شما در سیستم ما ذخیره خواهد شد و در صورتی که در آینده فرصت های شغلی در مجموعه ما باشد ، حتما در اولویت همکاری قرار خواهید گرفت و از شما برای مصاحبه مجدد دعوت به عمل خواهد امد </span></span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">با تشکر از توجه و همراهی شما </span></span></span></span></p>
<p dir="rtl" align="right"><br><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">با احترام </span></span></span></span>:</p>
<p align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">هانیه آصفی </span></span></span></span></p>
<p align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">مدیر منابع انسانی </span></span></span></span></p>
<p dir="rtl" align="right"><img src="https://sjicompany.com/imgpsh_fullsize_anim.png" alt="sji" width="128" height="64" /></p>
<p align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">شرکت شکوه جاوید ایرانیان </span></span></span></span></p>
<p align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">تلفن </span></span></span></span>: 985191007575</p>
<p dir="rtl" align="justify"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">ایمیل </span></span></span></span>: h.asefi@sjicompany.com</p>
<table style="border-collapse: collapse; width: 100%; border-width: 1px; background-color: rgb(206, 212, 217);" border="1"><colgroup><col style="width: 99.9193%;"></colgroup>
<tbody>
<tr>
<td>
<p>Hello,</p>
<p>Unfortunately, your application for the job position has not been accepted. We appreciate your interest and the time you dedicated to participating in the interview and pre-employment process. Your contact information will be stored in our system, and if there are future job opportunities within our organization, you will be given priority and invited for re-interview.</p>
<p>Thank you for your attention and cooperation.</p>
<p>&nbsp;</p>
<p>Best regards,</p>
<p>Hanieh Asefi</p>
<p>Human Resources Manager</p>
<p><img src="https://sjicompany.com/imgpsh_fullsize_anim.png" alt="sji" width="128" height="64" /></p>
<p>Shokooh Javid Iranian Company</p>
<p>Email: h.asefi@sjicompany.com</p>
<p>Phone: +985191007575</p>
<p>&nbsp;</p>
</td>
</tr>
</tbody>
</table>
<p style="text-align: left;"><span style="font-family: Times New Roman, serif;"><span style="font-size: medium;">&nbsp;</span></span></p>`;
