import { debounce } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Input, HeaderContainer } from "../../../atoms";
import { FilterWrapper, Wrapper } from "./ApplicationsFilter.styles";
import { SortMenu } from "../../../molecules";
import { IApplicationsFilterProps } from "./ApplicationsFilter.interface";
import { useDashboardRoutes } from "../../../../../hooks";

import { ReactComponent as IconSearch } from "../../../../../assets/icons/ic-search.svg";
import { ReactComponent as IconAdd } from "../../../../../assets/icons/ic-add-white.svg";

const ApplicationsFilter = ({
  handleSearch,
  handleSort,
}: IApplicationsFilterProps) => {
  const navigation = useNavigate();
  const { urls } = useDashboardRoutes();

  const [sortValue, setSortValue] = useState<string | undefined>();

  return (
    <HeaderContainer>
      <Wrapper>
        <FilterWrapper>
          <Input
            endAdornment={<IconSearch />}
            placeholder="Search"
            sx={{ minWidth: "448px" }}
            onChange={debounce(handleSearch, 300)}
          />
          <SortMenu
            onChange={(val) => {
              setSortValue(val);
              handleSort(val);
            }}
            value={sortValue}
            sortList={["Newest", "Oldest"]}
            placeHolder="Sort By"
          />
        </FilterWrapper>
        <Button
          title="Add Job"
          variant="contained"
          startIcon={<IconAdd />}
          sx={{ maxWidth: "fit-content" }}
          onClick={() =>
            navigation(urls.adminSubRoots.APPLICATION_CREATE.address)
          }
        />
      </Wrapper>
    </HeaderContainer>
  );
};

export default ApplicationsFilter;
