export const hireTextEmail = `<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">با احترام ، همکار عزیز خوشحالیم از اینکه بعد از سپری کردن مراحل جذب ، تقاضای شما برای فعالیت در پست سازمانی {job_position}</span></span></span></span>&nbsp;<span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">مورد پذیرش قرار گرفته است </span></span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">جزییات تاریخ شروع ، حقوق و شرایط اشتغال به زودی به شما اطلاع داده خواهد شد </span></span></span></span>. <span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">همچنین ، در خواست های مرتبط با مدارک و مراحل پیش استخدامی نیز به زودی برای شما ارسال می شود </span></span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">ما به این امید هستیم که بتوانیم با شما در این مسیر همراه شویم و ورود شمار را به مجموعه خود خوش آمد می گوییم </span></span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">در صورت هر گونه سوال یا ابهام با ما در ارتباط باشید </span></span></span></span>.</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">ما برای شما بهترین ها را آرزو می کنیم.</span></span></span></span></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">با احترام</span></span></span></span></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">هانیه آصفی&nbsp;</span></span></span></span></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">مدیر منابع انسانی </span></span></span></span></p>
<p dir="rtl" align="right"><img src="https://sjicompany.com/imgpsh_fullsize_anim.png" alt="sji" width="128" height="64" /></p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">شرکت شکوه جاوید ایرانیان </span></span></span></span><br><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">تلفن </span></span></span></span>: 985191007575 +</p>
<p dir="rtl" align="right"><span style="font-family: Arial;"><span lang="ar-SA"><span style="font-family: Arial;"><span lang="fa-IR">ایمیل </span></span></span></span>: h.asefi@sjicompany.com</p>
<table style="border-collapse: collapse; width: 100%; border-width: 1px; background-color: rgb(206, 212, 217);" border="1"><colgroup><col style="width: 99.9193%;"></colgroup>
<tbody>
<tr>
<td>
<p>&nbsp;</p>
<p>Dear valued colleague,</p>
<p>We are delighted to inform you that, following the completion of the recruitment process, your application for the position within our organization has been accepted. Details regarding the start date, salary, and employment terms will be provided to you shortly. Additionally, you will receive instructions related to document submission and pre-employment procedures in the near future.</p>
<p>We hope to embark on this journey with you and extend a warm welcome to you as a part of our team. If you have any questions or concerns, please do not hesitate to reach out to us.</p>
<p>We wish you all the best.</p>
<p>&nbsp;</p>
<p>Sincerely,</p>
<p>Hanieh Asefi</p>
<p>Human Resources Manager</p>
<p><img src="https://sjicompany.com/imgpsh_fullsize_anim.png" alt="sji" width="128" height="64" /></p>
<p>Shokooh Javid Iranian Company</p>
<p>Email: h.asefi@sjicompany.com</p>
<p>Phone: +985191007575</p>
<p>&nbsp;</p>
</td>
</tr>
</tbody>
</table>
<p style="text-align: left;"><span style="font-family: Times New Roman, serif;"><span style="font-size: medium;">&nbsp;</span></span></p>`;
