import { useEffect, useState } from "react";
import moment from "jalali-moment";
import { useNavigate } from "react-router";

import { IApplication, IRequestFilter } from "../../../../models";
import { PAGE_SIZE } from "../../../../util/constants";
import {
  UseApplication,
  useAlert,
  useDashboardRoutes
} from "../../../../hooks";
import { IApplicationListItemProps } from "../../atoms";

const useApplications = () => {
  const navigate = useNavigate();
  const { urls } = useDashboardRoutes();
  const { isSuccess, isError, mutate, isLoading } = UseApplication.useDelete();
  const { alert } = useAlert();

  const [tab, setTab] = useState(0);
  const [currentItemForDelete, setCurrentItemForDelete] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [publishedTotalCount, setPublishedTotalCount] = useState(0);
  const [draftTotalCount, setDraftTotalCount] = useState(0);
  const [closeTotalCount, setCloseTotalCount] = useState(0);
  const [filterPublished, setFilterPublished] = useState<IRequestFilter>({
    page: 0,
    size: PAGE_SIZE,
    sort: "startDate,desc",
    filter: ""
  });
  const [filterDraft, setFilterDraft] = useState<IRequestFilter>({
    page: 0,
    size: PAGE_SIZE,
    sort: "id,desc",
    filter: ""
  });
  const [filterClosed, setFilterClosed] = useState<IRequestFilter>({
    page: 0,
    size: PAGE_SIZE,
    sort: "expDate,desc",
    filter: ""
  });

  const {
    data: publishedData,
    isFetching: publishedFetching,
    refetch: publishedRefetch
  } = UseApplication.useGetPublished(filterPublished);
  const {
    data: draftData,
    isFetching: draftFetching,
    refetch: draftRefetch
  } = UseApplication.useGetDraft(filterDraft);
  const {
    data: closedData,
    isFetching: closedFetching,
    refetch: closedRefetch
  } = UseApplication.useGetClosed(filterClosed);

  const handleSearch = (search: string) => {
    if (search) {
      const searchFilter = `title=ilike='${search}',description=ilike='${search}'`;
      setFilterPublished({
        page: 0,
        size: PAGE_SIZE,
        sort: "startDate,desc",
        filter: searchFilter
      });
      setFilterDraft({
        page: 0,
        size: PAGE_SIZE,
        sort: "id,desc",
        filter: searchFilter
      });
      setFilterClosed({
        page: 0,
        size: PAGE_SIZE,
        sort: "expDate,desc",
        filter: searchFilter
      });
    } else {
      setFilterPublished({
        page: 0,
        size: PAGE_SIZE,
        sort: "startDate,desc",
        filter: ""
      });
      setFilterDraft({
        page: 0,
        size: PAGE_SIZE,
        sort: "id,desc",
        filter: ""
      });
      setFilterClosed({
        page: 0,
        size: PAGE_SIZE,
        sort: "expDate,desc",
        filter: ""
      });
    }
  };

  const handleSort = (val: string) => {
    setFilterPublished({
      ...filterPublished,
      sort: val === "Newest" ? "id,desc" : "id,asc"
    });
    setFilterDraft({
      ...filterDraft,
      sort: val === "Newest" ? "id,desc" : "id,asc"
    });
    setFilterClosed({
      ...filterClosed,
      sort: val === "Newest" ? "id,desc" : "id,asc"
    });
  };

  const handleDelete = () => {
    mutate([currentItemForDelete]);
  };

  const convertData = (list: IApplication[]): IApplicationListItemProps[] => {
    return list.map((item) => {
      return {
        id: item.id,
        jobTitle: item.title,
        jobPosition: String(item.job_position?.title),
        publishedDate: moment(item.start_date).format("MMMM / DD / YYYY"),
        link: item.url,
        closedDate:
          item.status === "CLOSED"
            ? moment(item.exp_date).format("MMMM / DD / YYYY")
            : undefined,
        showDate: true,
        showPreview: true,
        onClickDelete: (id) => {
          setCurrentItemForDelete(Number(id));
          setShowDeleteModal(true);
        },
        onClickEdit: (id) => {
          navigate(
            urls.adminSubRoots.APPLICATION_UPDATE.address.replace(
              ":id",
              String(id)
            )
          );
        },
        onClickPreview: (url) => {
          window.open(
            `https://sjicompany.com/job_position/${url}`,
            "_blank"
          );
        }
      };
    });
  };

  useEffect(() => {
    setPublishedTotalCount(publishedData?.data?.totalElements ?? 0);
  }, [publishedData]);

  useEffect(() => {
    setDraftTotalCount(draftData?.data?.totalElements ?? 0);
  }, [draftData]);

  useEffect(() => {
    setCloseTotalCount(closedData?.data?.totalElements ?? 0);
  }, [closedData]);

  useEffect(() => {
    if (isError) {
      alert({
        message: "Delete Application Failed",
        variant: "error"
      });
    }

    if (isSuccess) {
      alert({
        message: "Delete Application Success",
        variant: "success"
      });
      publishedRefetch();
      draftRefetch();
      closedRefetch();
    }

    setShowDeleteModal(false);
  }, [isError, isSuccess]);

  return {
    tab,
    setTab,
    publishedData,
    publishedFetching,
    convertData,
    closedFetching,
    closedData,
    draftData,
    draftFetching,
    filterDraft,
    filterPublished,
    filterClosed,
    publishedTotalCount,
    draftTotalCount,
    closeTotalCount,
    setFilterClosed,
    setFilterDraft,
    setFilterPublished,
    handleSearch,
    handleSort,
    isLoading,
    showDeleteModal,
    setShowDeleteModal,
    handleDelete
  };
};

export default useApplications;
