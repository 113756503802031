import { debounce } from "lodash";

import {
  Input,
  HeaderContainer,
  Button,
  Select,
  FolderModal,
  DeleteConfirmModal,
} from "../../../atoms";
import {
  FilterWrapper,
  FolderTitle,
  FolderWrapper,
  HeaderWrapper,
  IconWrapper,
  MainWrapper,
  Title,
  Wrapper,
  DeleteFolderMessageWrapper,
  DeleteFolderMessage,
} from "./ApplicantsFilter.styles";
import { SortMenu } from "../../../molecules";
import { IApplicantsFilterProps } from "./ApplicantsFilter.interface";

import { ReactComponent as IconSearch } from "../../../../../assets/icons/ic-search.svg";
import { ReactComponent as IconClose } from "../../../../../assets/icons/ic-close.svg";
import { ReactComponent as IconEdit } from "../../../../../assets/icons/ic-edit-blue.svg";
import { ReactComponent as IconTrash } from "../../../../../assets/icons/ic-trash-blue.svg";
import { ReactComponent as IconAdd } from "../../../../../assets/icons/ic-add-white.svg";
import { ReactComponent as IconWarning } from "../../../../../assets/icons/ic-warning.svg";

import useApplicantsFilter from "./useApplicantsFilter";
import { IListItem } from "../../../../../models";
import StringUtil from "../../../../../util/StringUtil";

const ApplicationsFilter = ({
  handleFilterPosition,
  handleSearch,
  handleSortDate,
  handleFilterStatus,
  handleClearFilter,
  folders,
  showChangeFolder,
  onChangeFolder,
}: IApplicantsFilterProps) => {
  const {
    jobPositionData,
    sortDate,
    setSortDate,
    filterPosition,
    setFilterPosition,
    filterStatus,
    setFilterStatus,
    filterSearch,
    setFilterSearch,
    folderData,
    showFolderModal,
    setShowFolderModal,
    folderEditMode,
    setFolderEditMode,
    setShowDeleteFolderModal,
    showFolderDeleteModal,
    removeFolder,
    deleteFolderLoading,
    folderId,
    clearEnable,
    location,
  } = useApplicantsFilter();

  const DeleteFolderMessageCmp = (
    <DeleteFolderMessageWrapper>
      <IconWarning />
      <DeleteFolderMessage>
        Resumes in this folder will be moved to Recent Folder.
      </DeleteFolderMessage>
    </DeleteFolderMessageWrapper>
  );

  const pathName =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  return (
    <>
      <HeaderContainer>
        <MainWrapper>
          <HeaderWrapper>
            {Number(folderId)! && folderData?.data ? (
              <FolderWrapper>
                <FolderTitle>{folderData.data.name}</FolderTitle>
                <IconEdit
                  onClick={() => {
                    setFolderEditMode(true);
                    setShowFolderModal(true);
                  }}
                />
                <IconTrash
                  onClick={() => {
                    setShowDeleteFolderModal(true);
                  }}
                />
              </FolderWrapper>
            ) : (
              <FolderWrapper>
                <FolderTitle>{StringUtil.toPascalCase(pathName)}</FolderTitle>
              </FolderWrapper>
            )}
            <Button
              title="Create Folder"
              variant="contained"
              startIcon={<IconAdd />}
              sx={{ maxWidth: "fit-content" }}
              onClick={() => {
                setFolderEditMode(false);
                setShowFolderModal(true);
              }}
            />
          </HeaderWrapper>
          <Wrapper>
            <FilterWrapper>
              <Input
                endAdornment={<IconSearch />}
                placeholder="Search"
                value={filterSearch}
                onChangeCapture={debounce(handleSearch, 300)}
                onChange={(e) =>
                  setFilterSearch(
                    (e as React.ChangeEvent<HTMLInputElement>).target.value
                  )
                }
              />
              <SortMenu
                onChange={(val) => {
                  if (typeof val === "string") {
                    handleSortDate(val);
                    setSortDate(val);
                  }
                }}
                sortList={["Newest", "Oldest"]}
                placeHolder="Date"
                value={sortDate}
              />
              <SortMenu
                onChange={(val) => {
                  if (typeof val === "string") {
                    handleFilterPosition(val);
                    setFilterPosition(val);
                  }
                }}
                sortList={
                  jobPositionData?.data?.content?.map((item) => item.title) ??
                  []
                }
                placeHolder="Position"
                value={filterPosition}
              />
              <SortMenu
                onChange={(val) => {
                  if (typeof val === "string") {
                    handleFilterStatus(val);
                    setFilterStatus(val);
                  }
                }}
                sortList={[
                  "Pending",
                  "Rejected",
                  "Confirm For Interview",
                  "Hired",
                ]}
                placeHolder="Status"
                value={filterStatus}
              />
            </FilterWrapper>
            {clearEnable && (
              <IconWrapper
                onClick={() => {
                  setFilterPosition(undefined);
                  setFilterStatus(undefined);
                  setFilterSearch("");
                  setSortDate(undefined);
                  handleClearFilter();
                }}
              >
                <IconClose />
                <Title>Clear All Filters</Title>
              </IconWrapper>
            )}
          </Wrapper>
          {showChangeFolder && (
            <FilterWrapper>
              <Title>Move To</Title>
              <Select
                items={folders.map(
                  (folder): IListItem => ({
                    key: folder.id,
                    value: folder.name,
                  })
                )}
                selectProps={{
                  sx: { maxWidth: "212px" },
                  onChange: (e) => {
                    onChangeFolder(Number(e.target.value));
                  },
                }}
              />
            </FilterWrapper>
          )}
        </MainWrapper>
      </HeaderContainer>
      <FolderModal
        mode={folderEditMode && folderData?.data ? "EDIT" : "CREATE"}
        onCancel={() => setShowFolderModal(false)}
        showModal={showFolderModal}
        onCreateUpdate={() => setShowFolderModal(false)}
        folderId={folderEditMode ? folderData?.data?.id : undefined}
        folderName={folderEditMode ? folderData?.data?.name : undefined}
      />
      <DeleteConfirmModal
        open={showFolderDeleteModal}
        onCancel={() => setShowDeleteFolderModal(false)}
        onConfirm={removeFolder}
        loading={deleteFolderLoading}
        title="Delete Folder"
        message={
          folderData?.data.editable
            ? "Are you sure you want to delete this folder?"
            : DeleteFolderMessageCmp
        }
      />
    </>
  );
};

export default ApplicationsFilter;
